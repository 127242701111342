import React, { useEffect, useState } from "react";
import "../../../styles/style/MainSidebarStyle.css";
import {
  MdAccountCircle,
  MdAdd,
  MdAssignmentLate,
  MdAttachMoney,
  MdBarChart,
  MdDirectionsCar,
  MdGraphicEq,
  MdNoCrash,
  MdOutlineSettings,
} from "react-icons/md";
import { BiWindows } from "react-icons/bi";
import { FaBriefcase, FaList, FaRegFileAlt } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { useCompany } from "../../../context/CompanyContext";
import { createCar } from "../services/servicesTableDeals";
import { useAuth } from "../../../context/AuthContext";
import { useDeals } from "../../../context/DealsContext";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../context/UserContext";
import MainMenuItemsComponent from "./MainMenuItemsComponent";
import { teams } from "../../../constants/teams";
import AccordionButton from "../../../components/AccordionButtonComponent";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { CreateCarModalComponent } from ".";
import CarCash from "../../../images/car_cash.svg";
import { useFilters } from "../../../context/FilterContext";

export default function MainSidebarComponent({ expanded, setExpanded }) {
  const { logout } = useAuth();
  const [modal, setModal] = useState(false);
  const [newDocId, setnewDocId] = useState("");
  const { user, setCompanyChange } = useUser();
  const { setViewName, setLoadingDeals, viewName } = useDeals();
  const { setPage } = useFilters();
  const { companyData } = useCompany();
  const navigate = useNavigate();
  let isPageWide = useMediaQuery("(min-width: 460px)");
  
  const toggleSidebar = (toggle) => {
    if (isPageWide) {
      setExpanded(toggle);
    }
  };

  const handleClick = (value) => {
    if (value !== viewName) {
      setLoadingDeals(true);
      setViewName(value);
    }
    setPage(1);
  };
  const logOut = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.log(error.message);
    }
  };

  const createDocDeal = () => {
    setModal(true);
    createCar(user, setnewDocId, user?.company);
  };
  const changeCompany = (id) => {
    setCompanyChange(id);
  };
  function isAuthorizedUser(email) {
    const authorizedEmails = [
      "santiago.bensadon@auta.com.ar",
      "emorales@auta.com.ar",
      "facu.higa.tablet2001@gmail.com",
    ];
    return authorizedEmails.includes(email);
  }
  return (
    <div
      className={`${isPageWide ? "sidebar" : "sidebar-mobile"} ${!expanded ? "" : "expanded"
        }`}
      onMouseEnter={() => toggleSidebar(true)}
      onMouseLeave={() => toggleSidebar(false)}
    >
      {expanded ? (
        <ul
          className={`list-unstyled list-main-content ${expanded ? "expanded" : ""
            }`}
        >
          <div style={{ width: "100%" }}>
            <span className="add-car-button" style={{ display: "inline-flex" }}>
              <MdAdd className="main-icon-list" />
              {companyData?.settings?.urls?.add_car_url ? (
                <div>
                  <a
                    href="https://www.cotizador.auta.com.ar/"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      textDecoration: "none",
                      color: "var(--secondary-ultra-light",
                      marginLeft: "6px",
                    }}
                  >
                    Agregar auto
                  </a>
                </div>
              ) : (
                <div onClick={createDocDeal} style={{ marginLeft: "6px" }}>
                  Agregar auto
                </div>
              )}
            </span>
            <div className="sidebar-divider"></div>
            <AccordionButton
              text="Vistas"
              icon={<BiWindows className="main-icon-list" />}
            >
              <MainMenuItemsComponent
                onClick={() => handleClick("tasks")}
                expanded={expanded}
                text={"Tareas"}
                icon={
                  <FaList
                    className={`icon-list ${expanded ? "expanded" : ""}`}
                  />
                }
                to="/tasks"
                isActive={window.location.pathname === "/tasks"}
              />
              {isAuthorizedUser(user.email) || user?.team === teams.PRICING ? (
                <MainMenuItemsComponent
                  onClick={() => handleClick("tasks")}
                  expanded={expanded}
                  text={"Pricing"}
                  icon={
                    <MdAttachMoney
                      className={`icon-list ${expanded ? "expanded" : ""}`}
                      style={{ fontSize: "22px" }}
                    />
                  }
                  to="/pricing"
                  isActive={window.location.pathname === "/pricing"}
                />
              ) : null}
              {/* {user?.team === teams.ADMIN || user?.team === teams.PRICING ? (
                <MainMenuItemsComponent
                  onClick={() => handleClick('incomingLeadsView')}
                  expanded={expanded}
                  text={"Nuevos"}
                  icon={<img className={`car-pricing`} src={CarCash} alt="car" />}
                  to="/incoming-leads"
                  isActive={window.location.pathname === '/incoming-leads'} />
              ) : null} */}
              {user?.team === teams.ADMIN || user?.team === teams.BACKOFFICE ? (
                <MainMenuItemsComponent
                  onClick={() => handleClick("pendingReportsView")}
                  expanded={expanded}
                  text={"Informes pendientes"}
                  icon={
                    <FaRegFileAlt
                      className={`icon-list ${expanded ? "expanded" : ""}`}
                    />
                  }
                  to="/pending-reports"
                  isActive={window.location.pathname === "/pending-reports"}
                />
              ) : null}
              {user?.team !== "BackOffice" ? (
                <MainMenuItemsComponent
                  onClick={() => handleClick("checkView")}
                  expanded={expanded}
                  text={"Inspecciones"}
                  icon={
                    <MdAssignmentLate
                      className={`icon-list ${expanded ? "expanded" : ""}`}
                    />
                  }
                  to="/inspections"
                  isActive={window.location.pathname === "/inspections"}
                />
              ) : null}
              <MainMenuItemsComponent
                onClick={() => handleClick("AcquisitionsView")}
                expanded={expanded}
                value="AcquisitionsView"
                text={"Acquisitions"}
                icon={
                  <MdNoCrash
                    className={`icon-list ${expanded ? "expanded" : ""}`}
                  />
                }
                to="/acquisitions"
                isActive={window.location.pathname === "/acquisitions"}
              />
            </AccordionButton>
            <div className="sidebar-divider"></div>
            {user?.team === teams.ADMIN ? (
              <AccordionButton
                text="Dashboards"
                useInCompany={true}
                icon={<MdBarChart className="main-icon-company" />}
              >
                <MainMenuItemsComponent
                  onClick={() => handleClick("graphics")}
                  expanded={expanded}
                  text={"Auta"}
                  to="/graphics"
                  isActive={window.location.pathname === "/graphics"}
                />
                <MainMenuItemsComponent
                  onClick={() => handleClick("graphics")}
                  expanded={expanded}
                  text={"Inspección"}
                  to="/graphics-insp"
                  isActive={window.location.pathname === "/graphics-insp"}
                />
                <MainMenuItemsComponent
                  onClick={() => handleClick("graphics")}
                  expanded={expanded}
                  text={"Pricing"}
                  to="/graphics-pricing"
                  isActive={window.location.pathname === "/graphics-pricing"}
                />
                <MainMenuItemsComponent
                  onClick={() => handleClick("graphics")}
                  expanded={expanded}
                  text={"Acquisitions"}
                  to="/graphics-acquisitions"
                  isActive={
                    window.location.pathname === "/graphics-acquisitions"
                  }
                />
                <MainMenuItemsComponent
                  onClick={() => handleClick("graphics")}
                  expanded={expanded}
                  text={"Llamadas Anura"}
                  to="/graphics-anura"
                  isActive={window.location.pathname === "/graphics-anura"}
                />
                <MainMenuItemsComponent
                  onClick={() => handleClick("graphics")}
                  expanded={expanded}
                  text={"Marketing"}
                  to="/graphics-marketing"
                  isActive={window.location.pathname === "/graphics-marketing"}
                />
              </AccordionButton>
            ) : user?.team === teams.AS ? (
              <AccordionButton
                text="Dashboards"
                useInCompany={true}
                icon={<MdBarChart className="main-icon-company" />}
              >
                <MainMenuItemsComponent
                  onClick={() => handleClick("graphics")}
                  expanded={expanded}
                  text={"Auta"}
                  to="/graphics"
                  isActive={window.location.pathname === "/graphics"}
                />
                <MainMenuItemsComponent
                  onClick={() => handleClick("graphics")}
                  expanded={expanded}
                  text={"Acquisitions"}
                  to="/graphics-acquisitions"
                  isActive={
                    window.location.pathname === "/graphics-acquisitions"
                  }
                />
              </AccordionButton>
            ) : user?.team === teams.PRICING ? (
              <AccordionButton
                text="Dashboards"
                useInCompany={true}
                icon={<MdBarChart className="main-icon-company" />}
              >
                <MainMenuItemsComponent
                  onClick={() => handleClick("graphics")}
                  expanded={expanded}
                  text={"Auta"}
                  to="/graphics"
                  isActive={window.location.pathname === "/graphics"}
                />
                <MainMenuItemsComponent
                  onClick={() => handleClick("graphics")}
                  expanded={expanded}
                  text={"Pricing"}
                  to="/graphics-pricing"
                  isActive={window.location.pathname === "/graphics-pricing"}
                />
              </AccordionButton>
            ) : user?.team === teams.INSPECCION ? (
              <AccordionButton
                text="Dashboards"
                useInCompany={true}
                icon={<MdBarChart className="main-icon-company" />}
              >
                <MainMenuItemsComponent
                  onClick={() => handleClick("graphics")}
                  expanded={expanded}
                  text={"Auta"}
                  to="/graphics"
                  isActive={window.location.pathname === "/graphics"}
                />
                <MainMenuItemsComponent
                  onClick={() => handleClick("graphics")}
                  expanded={expanded}
                  text={"Inspección"}
                  to="/graphics-insp"
                  isActive={window.location.pathname === "/graphics-insp"}
                />
              </AccordionButton>
            ) : (
              <MainMenuItemsComponent
                onClick={() => handleClick("graphics")}
                expanded={expanded}
                text={"Dashboards"}
                icon={
                  <MdBarChart
                    className={`icon-list ${expanded ? "expanded" : ""}`}
                  />
                }
                to="/graphics"
                isActive={window.location.pathname === "/graphics"}
              />
            )}
            {user?.team === teams.ADMIN || user?.team === teams.CS ? (
              <>
                <div className="sidebar-divider"></div>
                <MainMenuItemsComponent
                  onClick={() => handleClick("users")}
                  expanded={expanded}
                  text={"Configuraciones"}
                  icon={
                    <MdOutlineSettings
                      className={`icon-list ${expanded ? "expanded" : ""}`}
                    />
                  }
                  to="/users"
                  isActive={window.location.pathname === "/users"}
                />
              </>
            ) : null}
          </div>
          <div style={{ width: "100%" }}>
            <div className="user-container">
              <MdAccountCircle className="main-icon-list" />
              <p className="user-date-sidebar">{user?.name}</p>
            </div>
            <p className="user-date-sidebar" style={{ fontSize: "13px" }}>
              {user?.email}
            </p>
            <p className="user-date-sidebar" style={{ fontSize: "13px " }}>
              Team {user?.team}
            </p>
            <div className="sidebar-divider"></div>
            <div className="menu-items-logout" onClick={logOut}>
              <FiLogOut
                className="main-icon-list"
                style={{ marginRight: "8px" }}
              />
              Cerrar sesion
            </div>
          </div>
        </ul>
      ) : (
        <ul
          className={`list-unstyled list-main-content ${expanded ? "expanded" : ""
            }`}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <span className="icon-item">
              <MdAdd className="main-icon-list" />
            </span>
            <div className="sidebar-divider"></div>
            <span className="icon-item">
              <BiWindows className="main-icon-list" />
            </span>
            <span className="icon-item">
              <FaList className="icon-list" />
            </span>
            {user?.team === teams.ADMIN || user?.team === teams.PRICING ? (
              <span className="icon-item">
                <MdAttachMoney
                  className="icon-list"
                  style={{ fontSize: "20px" }}
                />
              </span>
            ) : null}
            {/* {user?.team === teams.ADMIN || user?.team === teams.PRICING ? (
              <span className="icon-item">
                <img
                  className="car-pricing-collapse icon-list"
                  src={CarCash}
                  alt="car"
                />
              </span>
            ) : null} */}
            {user?.team === teams.ADMIN || user?.team === teams.BACKOFFICE ? (
              <span className="icon-item">
                <FaRegFileAlt className="icon-list" />
              </span>
            ) : null}
            {user?.team !== "BackOffice" ? (
              <span className="icon-item">
                <MdAssignmentLate className="icon-list" />
              </span>
            ) : null}
            <span className="icon-item">
              <MdNoCrash className="icon-list" />
            </span>
            {/* <div className="sidebar-divider"></div>
            {user?.team === teams.ADMIN ? (
              <span className="icon-item">
                <FaBriefcase className="main-icon-list company" />
              </span>
            ) : null} */}
            <div className="sidebar-divider"></div>
            <span className="icon-item">
              <MdBarChart className="icon-list" />
            </span>
            <div className="sidebar-divider"></div>
            {user?.team === teams.ADMIN ? (
              <span className="icon-item">
                <MdOutlineSettings className="icon-list" />
              </span>
            ) : null}
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <span className="icon-item">
              <MdAccountCircle className="main-icon-list" />
            </span>
            <div className="sidebar-divider"></div>
            <span className="icon-item">
              <FiLogOut className="main-icon-list" />
            </span>
          </div>
        </ul>
      )}
      <CreateCarModalComponent
        modal={modal}
        setModal={setModal}
        newDocId={newDocId}
        setnewDocId={setnewDocId}
      />
    </div>
  );
}
