import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Table,
} from "react-bootstrap";
import { MdAdd, MdEdit } from "react-icons/md";
import ModalAddOrEditDiscountsComponent from "./ModalAddOrEditDiscountsComponent";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { useUser } from "../../../../context/UserContext";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  deleteDiscount,
  typeMoney,
} from "../../services/TakeServices/AmountToPayServices";
import { colors } from "../../../../styles/colors";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { updateFieldConsignament } from "../../services/TakeServices/ConsignmentServices";
import useFirebaseOnSnapshot from "../../../../hooks/useFirebaseOnSnapshot";
import IconInfo from "../../images/icon-info.svg";

export default function AmountToPayTabComponent({
  disabled,
  takeData,
  deal_id,
  toggleEditing,
  selectedType,
}) {
  const { user } = useUser();
  const { data } = useFirebaseOnSnapshot(user?.company, deal_id, "pricing");
  const [addOrEditDiscounts, setAddOrEditDiscounts] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [dataEdit, setDataEdit] = useState(null);
  const params = useParams();
  const [valueMoneda, setvalueMoneda] = useState("");
  const [valueInput, setvalueInput] = useState();
  const [isBenefits, setIsBenefits] = useState(false);
  const [dollarBlue, setDollarBlue] = useState(null);
  const [dollarMep, setDollarMep] = useState(null);
  const [highestDollar, setHighestDollar] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);

  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    const fetchDollarRates = async () => {
      try {
        const response = await fetch("https://dolarapi.com/v1/dolares");
        const data = await response.json();

        const blueRate = data.find((rate) => rate.casa === "blue");
        const mepRate = data.find((rate) => rate.casa === "bolsa");

        if (blueRate && mepRate) {
          setDollarBlue(blueRate);
          setDollarMep(mepRate);

          const highest =
            parseFloat(blueRate.venta) > parseFloat(mepRate.venta)
              ? blueRate
              : mepRate;
          setHighestDollar(highest);

          const lastUpdateTime = formatDate(blueRate.fechaActualizacion);
          setLastUpdated(lastUpdateTime);
        }
      } catch (error) {
        console.error("Error al obtener las cotizaciones del dólar:", error);
      }
    };

    fetchDollarRates();
  }, []);

  const formatDate = (isoDate) => {
    if (!isoDate) return "Fecha no disponible";
    const date = new Date(isoDate);
    return date.toLocaleString("es-ES", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  console.log("highestDollar", dollarMep);

  useEffect(() => {
    setValue("money", takeData?.amount_to_pay?.money);
    setvalueMoneda(takeData?.amount_to_pay?.money);
  }, [
    takeData?.amount_to_pay?.money,
    takeData?.amount_to_pay?.type_change_money,
    isEditing,
    setValue,
    data?.operation?.type,
  ]);

  const formatCurrency = (value) => {
    if (!value) return "";
    return `$${parseFloat(value).toLocaleString("es-AR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  useEffect(() => {
    if (takeData?.amount_to_pay?.type_change_money) {
      setValue("type_change_money", takeData?.amount_to_pay?.type_change_money);
      setvalueInput(takeData?.amount_to_pay?.type_change_money);
    } else {
      setValue("type_change_money", formatCurrency(highestDollar?.venta));
      setvalueInput(formatCurrency(highestDollar?.venta));
    }
  }, [highestDollar?.venta, dollarMep?.venta, setValue]);

  const styledisabled = {
    backgroundColor: colors.gris[100],
  };
  const cellStyle = {
    color: colors.neutral.lightTwo,
  };
  function deleteDiscountsModal(data, dataIsBenefits) {
    Swal.fire({
      title: "Borrar descuento",
      text: "¿Estas seguro de que quieres eliminar el descuento?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then((resultado) => {
      if (resultado.value) {
        deleteDiscount(params.deal_id, data, user, dataIsBenefits);
      } else {
        console.log("*NO se elimina el descuento*");
      }
    });
  }

  const editDiscounts = (data) => {
    setDataEdit(data);
    setAddOrEditDiscounts(true);
    setIsEditing(true);
  };

  const textStyle = {
    color: colors.primary.secondaryTwo,
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "0px",
    marginTop: "12px",
  };

  const handleInputChange = (e) => {
    setvalueMoneda(e.target.value);
  };

  const handleInputChangeTypeChange = (event) => {
    const inputValue = event.target.value;

    // Removemos cualquier carácter no válido (excepto números, ',' y '.')
    const cleanValue = inputValue.replace(/[^\d,]/g, "");

    // Validamos y formateamos el valor
    const [integerPart, decimalPart] = cleanValue.split(",");
    const formattedInteger = integerPart
      ? parseInt(integerPart, 10).toLocaleString("es-AR") // Agrega separadores de miles
      : "";

    let formattedValue = formattedInteger;
    if (decimalPart !== undefined) {
      // Limitamos el número de decimales a 2
      const limitedDecimals = decimalPart.slice(0, 2);
      formattedValue = `${formattedInteger},${limitedDecimals}`;
    }

    // Agregamos el signo $
    setvalueInput(`$${formattedValue}`);
  };

  const sendValueTypeMoney = (data) => {
    typeMoney(deal_id, data, user);
    updateFieldConsignament(
      selectedType === "toma" ? false : true,
      deal_id,
      user
    );
    toast.success("Informacion Guardada con exito.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    toggleEditing();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(sendValueTypeMoney)}>
        <Row>
          <Col lg={3}>
            <p style={textStyle} className="mb-2">
              Moneda
            </p>
            <Form.Select
              disabled={disabled}
              className="select-order"
              style={{ width: "100%" }}
              {...register("money")}
              onChange={(e) => handleInputChange(e)}
              aria-label="Moneda"
            >
              <option value="ARS">Pesos (ARS)</option>
              <option value="USD">Dólares (USD)</option>
            </Form.Select>
          </Col>
          {valueMoneda === "USD" ? (
            <Col lg={3}>
              <div className="d-flex align-items-center justify-content-start">
                <p style={textStyle} className="mb-2">
                  Tipo de cambio
                </p>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 200 }}
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Body>
                        <div>
                          {dollarBlue && (
                            <p>
                              <strong>Dólar Blue</strong>: Venta{" "}
                              {formatCurrency(dollarBlue.venta)}
                            </p>
                          )}
                          {dollarMep && (
                            <p>
                              <strong>Dólar MEP</strong>: Venta{" "}
                              {formatCurrency(dollarMep.venta)}
                            </p>
                          )}
                          <p>Última actualización: {lastUpdated}</p>
                        </div>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <img
                    src={IconInfo}
                    alt="icon-info"
                    className="ms-1"
                    style={{ width: "20px" }}
                  />
                </OverlayTrigger>
              </div>
              <input
                aria-label="Tipo de cambio"
                disabled={disabled}
                className="input-deal"
                style={{ width: "100%" }}
                {...register("type_change_money")}
                value={valueInput}
                onChange={handleInputChangeTypeChange}
              />
            </Col>
          ) : null}
          <Col
            lg={valueMoneda === "USD" ? 6 : 9}
            className="d-flex align-items-center justify-content-end"
          >
            {!disabled ? (
              <Button type="submit" disabled={disabled} className="mb-5">
                Guardar cambios
              </Button>
            ) : null}
          </Col>
        </Row>
      </form>
      <div className="divider mt-3 mb-3" />
      <p style={textStyle} className="mb-2">
        Revision de oferta
      </p>
      <div style={{ width: "200px" }}>
        <Table bordered style={styledisabled}>
          <thead>
            <tr className="table-header">
              <th className="table-title">Oferta final</th>
              <th style={cellStyle} className="table-body">
                ${takeData?.amount_to_pay?.offer}
              </th>
            </tr>
          </thead>
        </Table>
      </div>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <p style={textStyle}>Descuentos</p>

        {
          <Button
            size="sm"
            hidden={disabled}
            onClick={() => {
              setIsBenefits(false);
              setAddOrEditDiscounts(true);
              setIsEditing(false);
            }}
          >
            <MdAdd
              style={{
                fontSize: "18px",
              }}
            />
          </Button>
        }
      </div>
      <Table bordered style={styledisabled}>
        <thead>
          <tr
            className="table-header"
            style={{
              borderBottom: disabled
                ? "1px solid #1162A6"
                : "2px solid #864BFA",
            }}
          >
            <th className="table-title">Descuentos</th>
            <th className="table-title">Monto</th>
          </tr>
        </thead>
        <tbody>
          {takeData?.amount_to_pay?.discounts?.map((data, i) => (
            <tr key={i}>
              <td className="content-table">{data.name}</td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="content-table"
              >
                ${data.value}
                {!disabled ? (
                  <div>
                    <MdEdit
                      onClick={() => {
                        editDiscounts(data);
                      }}
                      style={{
                        fontSize: "24px",
                        color: colors.primary.primary,
                        cursor: "pointer",
                      }}
                      aria-label="edit"
                    />
                    <RiDeleteBin6Fill
                      style={{
                        fontSize: "24px",
                        color: colors.status.red,
                        cursor: "pointer",
                      }}
                      aria-label="delete"
                      onClick={() => deleteDiscountsModal(data, false)}
                    />
                  </div>
                ) : (
                  ""
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <p style={textStyle}>Adicionales</p>
        {
          <Button
            size="sm"
            hidden={disabled}
            onClick={() => {
              setIsBenefits(true);
              setAddOrEditDiscounts(true);
              setIsEditing(false);
            }}
          >
            <MdAdd
              style={{
                fontSize: "18px",
              }}
            />
          </Button>
        }
      </div>
      <Table bordered style={styledisabled}>
        <thead>
          <tr
            className="table-header"
            style={{
              borderBottom: disabled
                ? "1px solid #1162A6"
                : "2px solid #864BFA",
            }}
          >
            {/* <th className="table-title">Oferta final</th> */}
            <th className="table-title">Adicionales</th>
            <th className="table-title">Monto</th>
          </tr>
        </thead>
        <tbody>
          {takeData?.amount_to_pay?.benefits?.map((data, i) => (
            <tr key={i}>
              {/* <td className="content-table"></td> */}
              <td className="content-table">{data?.discounts_benefits}</td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="content-table"
              >
                ${data.benefits}
                {!disabled ? (
                  <div>
                    <MdEdit
                      aria-label="edit"
                      onClick={() => {
                        editDiscounts(data);
                      }}
                      style={{
                        fontSize: "24px",
                        color: colors.primary.primary,
                        cursor: "pointer",
                      }}
                    />
                    <RiDeleteBin6Fill
                      aria-label="delete"
                      style={{
                        fontSize: "24px",
                        color: colors.status.red,
                        cursor: "pointer",
                      }}
                      onClick={() => deleteDiscountsModal(data, true)}
                    />
                  </div>
                ) : (
                  ""
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div style={{ width: "200px" }}>
        <Table bordered style={styledisabled}>
          <thead>
            <tr className="table-header">
              <th className="table-title">Monto a pagar</th>
              <th style={cellStyle} className="table-body">
                ${takeData?.amount_to_pay?.total}
              </th>
            </tr>
          </thead>
        </Table>
        {takeData?.amount_to_pay?.total_usd !== "" ? (
          <Table bordered style={styledisabled}>
            <thead>
              <tr className="table-header">
                <th className="table-title">Monto a pagar en USD</th>
                <th style={cellStyle} className="table-body">
                  ${takeData?.amount_to_pay?.total_usd}
                </th>
              </tr>
            </thead>
          </Table>
        ) : null}
        {takeData?.amount_to_pay?.resto_ars !== "" ? (
          <Table bordered style={styledisabled}>
            <thead>
              <tr className="table-header">
                <th className="table-title">Redondeo Pesos</th>
                <th style={cellStyle} className="table-body">
                  ${takeData?.amount_to_pay?.resto_ars}
                </th>
              </tr>
            </thead>
          </Table>
        ) : null}
      </div>
      <ModalAddOrEditDiscountsComponent
        modal={addOrEditDiscounts}
        setModal={setAddOrEditDiscounts}
        isEditing={isEditing}
        dataEdit={dataEdit}
        discounts={
          !isBenefits
            ? takeData?.amount_to_pay?.discounts
            : takeData?.amount_to_pay?.benefits
        }
        isBenefits={isBenefits}
      />
    </div>
  );
}
