import React, { useEffect, useState } from "react";
import AppRouter from "./routes/AppRouter";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "./context/AuthContext";
import { DealsProvider } from "./context/DealsContext";
import { CompanyProvider } from "./context/CompanyContext";
import { UserProvider } from "./context/UserContext";
import OfflineScreen from "./OfflineScreen";
import TimeOutSessionModalComponent from "./components/TimeOutSessionModalComponent";
import MyErrorBoundary from "./components/MyErrorBoundary";
import { FilterProvider } from "./context/FilterContext";

function App() {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      {isOnline ? (
        <MyErrorBoundary>
          <AuthProvider>
            <UserProvider>
              <CompanyProvider>
                <DealsProvider>
                  <FilterProvider>
                    <AppRouter />
                    <Toaster position="bottom-center" />
                    <TimeOutSessionModalComponent />
                  </FilterProvider>
                </DealsProvider>
              </CompanyProvider>
            </UserProvider>
          </AuthProvider>
        </MyErrorBoundary>
      ) : (
        <OfflineScreen />
      )}
    </>
  );
}

export default App;
