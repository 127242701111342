import React, { useState, useEffect } from "react";
import { Modal, OverlayTrigger, Popover, Form } from "react-bootstrap";
import {
  aceptarPropuesta,
  rechazarPropuesta,
  solicitarRevision,
  updateOfferPostFinal,
  solicitarCotizacion,
  formatNumber,
  updateFieldOperation,
} from "../../../services/servicesPricing";
import toast from "react-hot-toast";
import { colors } from "../../../../../styles/colors";
import { MdHelpOutline } from "react-icons/md";
import { teams } from "../../../../../constants/teams";

const FinalOfferAdminModal = ({
  deal_id,
  setshowOfferFinal,
  showOfferFinal,
  user,
  dataHistory,
  actionId,
  sendActionId,
  valueCotizacion,
  revisionActionId,
  status,
  setActiveModalPricing,
  initialAptoConsignacion,
}) => {
  // Determinar si es la primera vez
  const isFirstTime =
    initialAptoConsignacion === undefined || initialAptoConsignacion === null;

  // Valor por defecto para aptoConsignacion
  const defaultAptoConsignacion = isFirstTime ? false : initialAptoConsignacion;

  // Estados del formulario
  const [valueOfferFinal, setValueOfferFinal] = useState(valueCotizacion || "");
  const [aptoConsignacion, setAptoConsignacion] = useState(
    defaultAptoConsignacion
  );

  const [showModalRevision, setShowModalRevision] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (showOfferFinal) {
      setValueOfferFinal(valueCotizacion || "");
      setAptoConsignacion(defaultAptoConsignacion);
    }
  }, [showOfferFinal, defaultAptoConsignacion, valueCotizacion]);

  const hasAptoConsignacionChanged =
    !isFirstTime && aptoConsignacion !== defaultAptoConsignacion;
  const disableOtherActions = hasAptoConsignacionChanged;

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        Como administrador puedes enviar y solicitar cotizaciones.
      </Popover.Body>
    </Popover>
  );

  const handleCloseOfferFinal = () => {
    setshowOfferFinal(false);
    setActiveModalPricing(false);
  };

  const activeModalRevision = () => {
    setshowOfferFinal(false);
    setShowModalRevision(true);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);
    setValueOfferFinal(formattedValue);
  };

  const handleSaveChanges = () => {
    const data = {
      "final_offer.consignment_user": user,
      "final_offer.consignment_ready": aptoConsignacion,
    };
    updateOfferPostFinal(user?.company, deal_id, data);
    toast.success("Cambios guardados.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setshowOfferFinal(false);
    setActiveModalPricing(false);
  };

  const sendPricingOfferFinal = () => {
    // Validación del campo valueOfferFinal
    if (!valueOfferFinal && !disableOtherActions) {
      setErrors({ value: "La oferta final es requerida" });
      return;
    }
    console.log(valueOfferFinal);
    const data = {
      "final_offer.user": user,
      "final_offer.consignment_user": user,
      "final_offer.consignment_ready": aptoConsignacion,
    };
    if (valueOfferFinal) {
      data["final_offer.value"] = valueOfferFinal;
    }
    updateOfferPostFinal(user?.company, deal_id, data);
    toast.success("Pricing enviado.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setshowOfferFinal(false);
    setActiveModalPricing(false);
  };

  const requestPrice = () => {
    const data = {
      "action_requested.action_id": actionId,
      "action_requested.comments": "",
      "action_requested.user": user,
      "action_requested.data_reminder": {},
    };
    solicitarCotizacion(user?.company, deal_id, data);
    toast.success("Solicitud enviada.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setshowOfferFinal(false);
    setActiveModalPricing(false);
  };

  const acceptedPrice = () => {
    const data = {
      "action_requested.action_id": sendActionId,
      "action_requested.comments": "",
      "action_requested.user": user,
    };
    aceptarPropuesta(user?.company, deal_id, data);
    updateFieldOperation("ARS", deal_id, user);
    toast.success("Oferta aceptada.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setshowOfferFinal(false);
    setActiveModalPricing(false);
  };

  const rejectedPrice = () => {
    const data = {
      "action_requested.action_id": "offer-f-rejected",
      "action_requested.comments": "",
      "action_requested.user": user,
    };
    rechazarPropuesta(user?.company, deal_id, data);
    toast.success("Oferta rechazada.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setshowOfferFinal(false);
    setActiveModalPricing(false);
  };

  const sendReviewRequest = (comments) => {
    const data = {
      "action_requested.action_id": revisionActionId,
      "action_requested.user": user,
      "action_requested.comments": comments,
      "action_requested.data_reminder": {},
    };
    solicitarRevision(user?.company, deal_id, data);
    toast.success("Solicitud de revisión enviada.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setShowModalRevision(false);
    setActiveModalPricing(false);
  };

  return (
    <div>
      <Modal
        show={showOfferFinal}
        onHide={handleCloseOfferFinal}
        centered
        dialogClassName="myModal"
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <span
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <p className="titleModal">Oferta final</p>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={popover}
              >
                <span>
                  <MdHelpOutline className="help-icon-modal" />
                </span>
              </OverlayTrigger>
            </span>
            <div style={{ width: "100%" }}>
              <div className="d-flex align-items-center mt-2">
                <input
                  className="inputModal"
                  style={{ width: "100%" }}
                  type="text"
                  placeholder={valueCotizacion || "$0.000.000"}
                  value={valueOfferFinal}
                  onChange={handleInputChange}
                  disabled={disableOtherActions}
                />
              </div>
              {errors.value && (
                <p
                  style={{
                    margin: "0px",
                    color: colors.status.red,
                    fontSize: "12px",
                    width: "100%",
                  }}
                  className="mt-2"
                >
                  {errors.value}
                </p>
              )}
              {/* Sección para los botones de radio */}
              <div className="mt-2 text-center">
                <Form.Group>
                  <Form.Label className="mb-22 textModalConsignment">
                    ¿Vehículo apto para consignación?
                  </Form.Label>
                  <div className="d-flex justify-content-center align-items-center">
                    <Form.Check
                      type="radio"
                      label="Sí"
                      value="si"
                      name="aptoConsignacion"
                      inline
                      checked={aptoConsignacion === true}
                      onChange={() => setAptoConsignacion(true)}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      value="no"
                      name="aptoConsignacion"
                      inline
                      checked={aptoConsignacion === false}
                      onChange={() => setAptoConsignacion(false)}
                    />
                  </div>
                </Form.Group>
              </div>
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center flex-column justify-content-between mt-2"
              >
                {/* Mostrar siempre el botón Enviar pricing */}
                {!disableOtherActions && (
                  <button
                    type="button"
                    className="btnSendPricing mb-2 mt-2"
                    onClick={sendPricingOfferFinal}
                  >
                    Enviar pricing
                  </button>
                )}
              </div>
            </div>
            {/* Mostrar siempre los demás botones */}
            {!disableOtherActions && (
              <>
                <button className="btnSendPricing mb-2" onClick={requestPrice}>
                  Solicitar
                </button>
                {status !== "expired" &&
                  (user.team === teams.CS ||
                    user.team === teams.AS ||
                    user.team === teams.ADMIN) && (
                    <>
                      <button
                        className="btnSendPricing mb-2"
                        onClick={acceptedPrice}
                      >
                        Aceptó la oferta
                      </button>
                      <button
                        className="btnSendPricing mb-2"
                        onClick={rejectedPrice}
                      >
                        Rechazó la oferta
                      </button>
                    </>
                  )}
                <button
                  className="btnPedirRevision mb-3"
                  onClick={activeModalRevision}
                >
                  Pedir revisión
                </button>
              </>
            )}
            {/* Botón Guardar cambios */}
            {!isFirstTime && hasAptoConsignacionChanged && (
              <button
                className="btnSendPricing mb-2"
                onClick={handleSaveChanges}
              >
                Guardar cambios
              </button>
            )}
            <div className="mt-2" style={{ width: "100%" }}>
              {dataHistory?.length > 0 &&
                dataHistory.map((info, i) => (
                  <div
                    className="d-flex align-items-center justify-content-between dividerHistory mb-3"
                    style={{ width: "100%" }}
                    key={i}
                  >
                    <p className="textHistory mb-2">${info.value}</p>
                    <p className="textHistory mb-2">
                      {new Date(info.date.seconds * 1000).toLocaleDateString()}{" "}
                      -{" "}
                      {new Date(info.date.seconds * 1000).toLocaleTimeString(
                        "en-US",
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    </p>
                  </div>
                ))}
            </div>
            <button
              className="btnCancelarModal"
              onClick={handleCloseOfferFinal}
            >
              Cancelar
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal para solicitar revisión */}
      <Modal
        show={showModalRevision}
        onHide={() => setShowModalRevision(false)}
        centered
        dialogClassName="myModal"
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="titleModal">Oferta final</p>
            <div style={{ width: "100%" }}>
              <textarea
                className="inputModal mt-3"
                style={{ width: "100%", height: "110px" }}
                placeholder="Dejar comentario opcional"
                onChange={() => {}}
              />
              <div
                style={{ width: "100%" }}
                className="d-flex flex-column align-items-center justify-content-between"
              >
                <button
                  className="btnSendPricing mb-2 mt-3"
                  onClick={() => sendReviewRequest("")}
                >
                  Solicitar
                </button>
                <button
                  className="btnCancelarModal"
                  onClick={() => setShowModalRevision(false)}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FinalOfferAdminModal;
