import React, { useRef, useState } from "react";
import {
  Table,
  Pagination,
  OverlayTrigger,
  Popover,
  Button,
  Spinner,
  Badge,
  Form,
} from "react-bootstrap";
import { colors } from "../../../styles/colors";
import { SpinnerFullScreenComponent } from "../../../components";
import { useCompany } from "../../../context/CompanyContext";

import { STATUS_TRANSLATIONS } from "../../../constants/statusApointment";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { getAcquisitions } from "../services/newTablesSevices";
import KeyImg from "../../Deal/images/key.svg";
import Consignment from "../../Deal/images/consignment-icon.svg";
import { useFilters } from "../../../context/FilterContext";
import { updateGoogleSheets } from "../services/acquisitionsTableServices";
import { landingMap } from "../../../constants/landingMap";
import IconBonus from "../images/icon-bonus.svg";

const AcquisitionsTableComponent = ({
  deals,
  loading,
  onStatusChange,
  totalPages,
}) => {
  const AUTA_URL = "https://admin.auta.com.ar";
  const { filters, page, setPage } = useFilters();
  const isPageWide = useMediaQuery("(min-width: 600px)");
  const MAX_ACTIONS_TO_DISPLAY = 4;
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const verDeal = (id) => {
    navigate(`/comments/${id}`);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const headTable = [
    "Turno",
    "Agendado por",
    "Agendado el",
    "Sucursal",
    "Confirmado",
    "Origen",
    "Estado",
    "Ultimo agente",
    "Cliente",
    "Patente",
    "Vehiculo",
    "Acciones",
    "Última Oferta",
    "Oferta esperada",
    "Estado BO",
    "Comentario",
    "Operacion",
  ];

  const { actions } = useCompany();

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const rowStyleDeal = {
    backgroundColor: colors.neutral.light,
  };

  const createSheetData = async () => {
    const newWindow = window.open("", "_blank");

    if (!newWindow) {
      alert(
        "Por favor, habilita las ventanas emergentes (pop-ups) en tu navegador para poder ver el Google Sheet."
      );
      return;
    }

    setIsSubmitting(true);
    try {
      const responseData = await getAcquisitions({
        init_date: filters.init_date,
        end_date: filters.end_date,
        status: filters.status,
        is_confirm:
          filters.is_confirm === "si"
            ? true
            : filters.is_confirm === "no"
            ? false
            : undefined,
        branch_id: filters.branch_id ? filters.branch_id : "",
        pagination: false,
      });

      if (!responseData.error) {
        const allData = responseData.items;

        if (!allData || allData.length === 0) {
          console.error("No se encontraron datos para exportar.");
          setIsSubmitting(false);
          newWindow.close();
          return;
        }

        const formattedData = allData.map((deal) => [
          new Date(deal.creation_date).toLocaleDateString("es-ES", {
            day: "2-digit",
            month: "2-digit",
          }),
          `${new Date(deal.appointment_date).toLocaleDateString("es-ES", {
            day: "2-digit",
            month: "2-digit",
          })} - ${new Date(deal.appointment_date).toLocaleTimeString("es-ES", {
            hour: "2-digit",
            minute: "2-digit",
          })}`,
          deal.agent || "-",
          deal.appointment_location || "-",
          deal.is_confirm === true
            ? "Sí"
            : deal.is_confirm === false
            ? "No"
            : "-",
          deal.status === "rescheduled" && deal.rescheduled_date
            ? `${STATUS_TRANSLATIONS[deal.status] || deal.status} (${new Date(
                deal.rescheduled_date
              ).toLocaleDateString()})`
            : STATUS_TRANSLATIONS[deal.status] || deal.status,
          deal.deal?.actions_open
            ?.map(
              (action) => actions[action.action_id]?.title || action.action_id
            )
            .join(", ") || "-",
          deal.deal?.last_agent?.name || "-",
          deal.deal?.client_name || "-",
          `${capitalize(deal?.basic?.brand)} - ${capitalize(
            deal?.basic?.model
          )} - ${formatNumberWithCommas(deal?.basic?.kms) || ""}kms`,
          deal.basic?.plate || "-",
          deal.back_office?.status || "-",
          deal.back_office?.comments || "-",
          deal.back_office?.last_offer?.value
            ? `$ ${deal.back_office?.last_offer?.value}`
            : "-",
          deal.deal?.id ? AUTA_URL + "/comments/" + deal.deal?.id : "-",
        ]);

        const response = await updateGoogleSheets({ data: formattedData });

        if (response && response.link) {
          newWindow.location.href = response.link;
        } else {
          console.error("No se encontró una URL en la respuesta:", response);
          newWindow.close();
        }
      } else {
        console.error(
          "Error al obtener los datos de Acquisitions:",
          responseData.message
        );
        newWindow.close();
      }
    } catch (error) {
      console.error("Error al crear el sheet:", error);
      newWindow.close();
    } finally {
      setIsSubmitting(false);
    }
  };

  const capitalize = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  function formatNumberWithCommas(numberOrString) {
    let number = parseFloat(numberOrString);

    if (isNaN(number)) {
      return "Invalid input";
    }
    const isFloat = number % 1 !== 0;
    const formattedNumber = isFloat
      ? number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      : number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return formattedNumber;
  }

  const handleCheckboxChange = (status) => {
    onStatusChange(status);
  };

  console.log("deals", deals);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-2">
        <div className="d-flex mt-3 pb-2">
          <Form.Check
            type="checkbox"
            id="checkbox-pending"
            label="Pendiente"
            onChange={() => handleCheckboxChange("pending")}
            style={{ fontSize: "14px" }}
            className="me-3"
            checked={filters.status.includes("pending")}
          />
          <Form.Check
            type="checkbox"
            id="checkbox-done"
            label="Realizado"
            onChange={() => handleCheckboxChange("done")}
            style={{ fontSize: "14px" }}
            className="me-3"
            checked={filters.status.includes("done")}
          />
          <Form.Check
            type="checkbox"
            id="checkbox-done"
            label="Vencido"
            onChange={() => handleCheckboxChange("expired")}
            style={{ fontSize: "14px" }}
            className="me-3"
            checked={filters.status.includes("expired")}
          />
          <Form.Check
            type="checkbox"
            id="checkbox-done"
            label="Cancelado"
            onChange={() => handleCheckboxChange("canceled")}
            style={{ fontSize: "14px" }}
            className="me-3"
            checked={filters.status.includes("canceled")}
          />
          <Form.Check
            type="checkbox"
            id="checkbox-done"
            label="Rechazado"
            onChange={() => handleCheckboxChange("rejected")}
            style={{ fontSize: "14px" }}
            checked={filters.status.includes("rejected")}
          />
        </div>
        <Button
          className="ms-2"
          onClick={() => createSheetData(deals)}
          disabled={isSubmitting || !deals?.length}
        >
          {isSubmitting ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : isPageWide ? (
            "Exportar a Google Sheets"
          ) : (
            "Sheets"
          )}
        </Button>
      </div>
      {loading ? (
        <div className="text-center">
          <SpinnerFullScreenComponent />
        </div>
      ) : deals?.length === 0 ? (
        <p className="text-center mt-4">
          No se encontraron resultados para mostrar.
        </p>
      ) : (
        <div
          className="table-container-grab scrollChat"
          ref={containerRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          style={{ overflow: "scroll", overflowY: "hidden" }}
        >
          <Table
            hover
            className="mt-1 deals-table-style mb-0"
            style={{ width: "100vw" }}
          >
            <thead>
              <tr>
                <th
                  colSpan={7}
                  style={{
                    backgroundColor: colors.primary.secondaryTwo,
                    color: "white",
                    fontWeight: "400",
                    borderTopLeftRadius: "8px",
                  }}
                >
                  Agendamiento
                </th>
                <th
                  colSpan={7}
                  style={{
                    backgroundColor: colors.primary.secondary,
                    color: "white",
                    fontWeight: "400",
                  }}
                >
                  Deal
                </th>
                <th
                  colSpan={3}
                  style={{
                    backgroundColor: colors.neutral.dark,
                    color: "white",
                    fontWeight: "400",
                    borderTopRightRadius: "8px",
                  }}
                >
                  Back Office
                </th>
              </tr>
              <tr>
                {headTable.map((data, i) => (
                  <th
                    key={i}
                    className="textHead pt-3 pb-3"
                    style={{
                      whiteSpace: "nowrap",
                      backgroundColor: colors.neutral.default,
                      borderRight:
                        data === "Estado" || data === "Oferta esperada"
                          ? "2px solid #E8E1EF"
                          : "none",
                    }}
                  >
                    {data}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {deals?.map((deal, index) => (
                <tr
                  key={index}
                  style={rowStyleDeal}
                  onClick={() => verDeal(deal?.deal?.id)}
                >
                  <td>
                    <p
                      style={{ fontSize: "12px", whiteSpace: "nowrap" }}
                      className="text-content-table-deals"
                    >
                      {`${new Date(deal.appointment_date).toLocaleDateString(
                        "es-ES",
                        { day: "2-digit", month: "2-digit" }
                      )}- ${new Date(deal.appointment_date).toLocaleTimeString(
                        [],
                        { hour: "2-digit", minute: "2-digit" }
                      )}`}
                    </p>
                  </td>
                  <td>
                    <p
                      style={{ fontSize: "12px" }}
                      className="text-content-table-deals"
                    >
                      {deal?.agent}
                    </p>
                  </td>
                  <td>
                    <p
                      style={{ fontSize: "12px", whiteSpace: "nowrap" }}
                      className="text-content-table-deals"
                    >
                      {" "}
                      {new Date(deal.creation_date).toLocaleDateString(
                        "es-ES",
                        { day: "2-digit", month: "2-digit" }
                      )}
                    </p>
                  </td>
                  <td>
                    <p
                      style={{ fontSize: "12px" }}
                      className="text-content-table-deals"
                    >
                      {deal?.appointment_location}
                    </p>
                  </td>
                  <td>
                    <p
                      style={{ fontSize: "12px" }}
                      className="text-content-table-deals"
                    >
                      {deal?.is_confirm === true
                        ? "Sí"
                        : deal?.is_confirm === false
                        ? "No"
                        : "-"}
                    </p>
                  </td>
                  <td>
                    <p
                      style={{ fontSize: "12px" }}
                      className="text-content-table-deals"
                    >
                      {" "}
                      {landingMap[deal?.source] ||
                        deal?.source?.replace(/_/g, "") ||
                        ""}
                    </p>
                  </td>
                  <td style={{ borderRight: "2px solid #E8E1EF" }}>
                    {deal.status === "rejected" && deal?.deal?.close_comment ? (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 200 }}
                        overlay={
                          <Popover id="popover-basic">
                            <Popover.Body>
                              {deal?.deal?.close_comment}
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <p
                          style={{ fontSize: "12px" }}
                          className="text-content-table-deals"
                        >
                          {STATUS_TRANSLATIONS[deal?.status] || deal?.status}*
                        </p>
                      </OverlayTrigger>
                    ) : (
                      <p
                        style={{ fontSize: "12px" }}
                        className="text-content-table-deals"
                      >
                        {STATUS_TRANSLATIONS[deal?.status] || deal?.status}
                        {deal?.status === "rescheduled" && deal.rescheduled_date
                          ? ` (${new Date(
                              deal?.rescheduled_date
                            ).toLocaleDateString()})`
                          : ""}
                      </p>
                    )}
                  </td>
                  <td>
                    <p
                      style={{ fontSize: "12px" }}
                      className="text-content-table-deals"
                    >
                      {deal?.deal?.last_agent?.name
                        ? deal?.deal?.last_agent?.name
                        : "-"}
                    </p>
                  </td>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <p
                        style={{
                          fontSize: "12px",
                          width: "auto",
                          whiteSpace: "nowrap", // Evita el ajuste de línea
                          overflow: "hidden", // Opcional, para manejar texto que excede el ancho
                          textOverflow: "ellipsis", // Opcional, para añadir puntos suspensivos
                        }}
                        className="text-content-table-deals"
                      >
                        {deal?.deal?.client_name}
                      </p>
                      {deal?.deal?.bonus ? (
                        <img
                          src={IconBonus}
                          alt="icon-bonus"
                          className="icon-bonus ms-1"
                          style={{ marginTop: "10px" }}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <p
                      style={{ fontSize: "12px" }}
                      className="text-content-table-deals"
                    >
                      {deal?.basic?.plate}
                    </p>
                  </td>
                  <td>
                    <p
                      style={{ fontSize: "12px" }}
                      className="text-content-table-deals"
                    >
                      {`${capitalize(deal?.basic?.brand)} - ${capitalize(
                        deal?.basic?.model
                      )} - ${formatNumberWithCommas(deal?.basic?.kms)}kms`}
                    </p>
                  </td>
                  <td>
                    <div className="d-flex align-items-center justify-content-start">
                      {deal?.deal?.actions_open
                        ?.slice(0, MAX_ACTIONS_TO_DISPLAY)
                        .map((action, i) => (
                          <OverlayTrigger
                            key={i}
                            placement="right"
                            delay={{ show: 250, hide: 200 }}
                            overlay={
                              <Popover id="popover-basic">
                                <Popover.Body>
                                  {actions[action.action_id]?.title ||
                                    action.action_id}
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <span className="position-relative me-1 ms-1">
                              <img
                                src={action.svg}
                                alt=""
                                style={{
                                  width: "22px",
                                  height: "22px",
                                  marginTop: "5px",
                                }}
                              />
                              {action.open_count > 1 && (
                                <Badge
                                  pill
                                  className="position-absolute translate-middle"
                                  style={{
                                    zIndex: 3,
                                    left: "20px",
                                    top: "3px",
                                  }}
                                >
                                  {action.open_count}
                                </Badge>
                              )}
                            </span>
                          </OverlayTrigger>
                        ))}
                      {deal?.deal?.actions_open?.length >
                        MAX_ACTIONS_TO_DISPLAY && (
                        <p className="text-length-action">
                          +
                          {deal.deal.actions_open.length -
                            MAX_ACTIONS_TO_DISPLAY}
                        </p>
                      )}
                    </div>
                  </td>
                  <td>
                    <p
                      style={{ fontSize: "12px" }}
                      className="text-content-table-deals"
                    >
                      {deal?.back_office?.last_offer?.value
                        ? deal?.back_office?.last_offer?.value.includes("$")
                          ? deal?.back_office?.last_offer?.value
                          : `$ ${deal?.back_office?.last_offer?.value}`
                        : "-"}
                    </p>
                  </td>
                  <td style={{ borderRight: "2px solid #E8E1EF" }}>
                    <p
                      style={{ fontSize: "12px" }}
                      className="text-content-table-deals"
                    >
                      {deal?.expected_offer
                        ? `$ ${Number(deal?.expected_offer)?.toLocaleString(
                            "es-ES"
                          )}`
                        : "-"}
                    </p>
                  </td>
                  <td>
                    <p
                      style={{ fontSize: "12px" }}
                      className="text-content-table-deals"
                    >
                      {deal?.back_office?.status}
                    </p>
                  </td>
                  <td>
                    <p
                      style={{ fontSize: "12px" }}
                      className="text-content-table-deals"
                    >
                      {deal?.back_office?.comments
                        ? deal?.back_office?.comments
                        : "-"}
                    </p>
                  </td>
                  <td>
                    <p
                      style={{ fontSize: "12px" }}
                      className="text-content-table-deals"
                    >
                      {deal?.back_office?.operation
                        ? deal?.back_office?.operation
                        : "-"}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      {deals?.length === 0 ? null : (
        <Pagination className="justify-content-center mt-2">
          <Pagination.Prev
            disabled={loading || page === 1}
            onClick={() => handlePageChange(page - 1)}
          />
          {[...Array(totalPages)].map((_, idx) => (
            <Pagination.Item
              key={idx + 1}
              active={page === idx + 1}
              disabled={loading}
              onClick={() => handlePageChange(idx + 1)}
            >
              {idx + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            disabled={loading || page === totalPages}
            onClick={() => handlePageChange(page + 1)}
          />
        </Pagination>
      )}
    </div>
  );
};

export default AcquisitionsTableComponent;
