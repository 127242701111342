import React, { Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { updateCampoType } from "../../services/servicesInspect";

const ModalChangeInspection = ({ modal, setModal, user, newType, setSelectedType }) => {
  const handleClose = () => setModal(false);
  const { deal_id } = useParams();

  function handledInpsType() {
    updateCampoType(user?.company, deal_id, newType, user);
    setSelectedType(newType);
    setModal(false);
  }

  return (
    <Modal show={modal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirmar Cambio de Inspección</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Estás seguro de que quieres cambiar el tipo de inspección a {newType === "virtual" ? "virtual" : "física"}?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handledInpsType}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalChangeInspection;
