import React, { useState, useEffect } from 'react';
import { Button, Spinner, Form, Modal, Table } from 'react-bootstrap';
import Select from 'react-select';
import '../../styles/pricingView.css';
import customSelectStyles from "../../styles/customSelectStyles";
import { changeAllPrices, getChangeHistory, getBrands, getModels, verifyChangePrices } from '../../services/sevicesPricingView';
import { useUser } from '../../../../context/UserContext';
import { MdEdit } from 'react-icons/md';
import toast from 'react-hot-toast';
import { colors } from '../../../../styles/colors';

export default function PricingModifyBaseComponent() {
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [modificationType, setModificationType] = useState('filter');
  const [filterType, setFilterType] = useState('marcaModelo');
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [percentage, setPercentage] = useState(''); // Initialize as an empty string
  const [filters, setFilters] = useState({
    marca: [],
    modelo: [],
    año: [],
    categoría: [],
  });

  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [isModelSelectDisabled, setIsModelSelectDisabled] = useState(true);
  const [vehiclesToModify, setVehiclesToModify] = useState(0);

  useEffect(() => {
    const loadBrands = async () => {
      try {
        const response = await getBrands();
        setBrands(response.brands.map(brand => ({ value: brand.id, label: brand.name })));
      } catch (error) {
        console.error('Error al cargar las marcas:', error);
      }
    };
    loadBrands();
  }, []);

  // Load models when exactly one brand is selected
  useEffect(() => {
    const loadModels = async () => {
      if (filters.marca.length === 1) {
        try {
          const response = await getModels(filters.marca[0].label);
          setModels(response.models.map(model => ({ value: model.id, label: model.name })));
          setIsModelSelectDisabled(false); // Enable model select
        } catch (error) {
          console.error('Error al cargar los modelos:', error);
        }
      } else {
        setModels([]);
        setIsModelSelectDisabled(true); // Disable model select
      }
    };
    loadModels();
  }, [filters.marca]);

  // Generate years from 2000 to the current year
  const years = Array.from({ length: new Date().getFullYear() - 1999 }, (_, i) => ({
    value: 2000 + i,
    label: (2000 + i).toString(),
  }));

  const handleModificationChange = (e) => {
    setModificationType(e.target.value);
  };

  const handleFilterTypeChange = (e) => {
    const newFilterType = e.target.value;
    setFilterType(newFilterType);

    // Reset filters when changing filter type
    setFilters({
      marca: [],
      modelo: [],
      año: [],
      categoría: [],
    });
  };

  const handleMarcaModeloChange = (selected) => {
    if (filterType === 'marcaModelo') {
      setFilters((prev) => ({ ...prev, marca: selected, modelo: [] }));
    }
  };

  const handleModeloChange = (selected) => {
    if (filterType === 'marcaModelo') {
      setFilters((prev) => ({ ...prev, modelo: selected }));
    }
  };

  const handleAñoChange = (selected) => {
    if (filterType === 'año') {
      setFilters((prev) => ({ ...prev, año: selected }));
    }
  };

  const handleCategoriaChange = (e) => {
    if (filterType === 'categoria') {
      const newCategory = e.target.value;
      setFilters((prev) => {
        const updatedCategories = e.target.checked
          ? [...prev.categoría, newCategory]
          : prev.categoría.filter((cat) => cat !== newCategory);
        return { ...prev, categoría: updatedCategories };
      });
    }
  };

  const toggleHistoryModal = async () => {
    setShowHistoryModal(!showHistoryModal);
    if (!showHistoryModal) {
      setLoadingHistory(true);
      try {
        const response = await getChangeHistory();
        setHistoryData(response.change_history.changes || []);
      } catch (error) {
        console.error('Error al obtener el historial de modificaciones', error);
      } finally {
        setLoadingHistory(false);
      }
    }
  };

  const toggleConfirmationModal = async () => {
    if (!showConfirmationModal) {
      try {
        const filtersToSend = {
          user_id: user?.uid,
          percentage: parseFloat(percentage.replace(',', '.')),
          brand_filter: filters.marca.map(item => item.label).join(','),
          model_filter: filters.modelo.map(item => item.label).join(','),
          year_filter: filters.año.map(item => item.value).join(','),
          category_filter: filters.categoría.join(','),
        };

        const response = await verifyChangePrices(filtersToSend);

        if (!response.error) {
          setVehiclesToModify(response.total_changes || 0);
        } else {
          setVehiclesToModify(0);
        }
      } catch (error) {
        console.error('Error al verificar los cambios:', error);
        setVehiclesToModify(0);
      }
    }

    setShowConfirmationModal(!showConfirmationModal);
  };

  const handleModify = async () => {
    setLoading(true);
    try {
      const filtersToSend = {};

      if (filters.marca.length > 0) {
        filtersToSend.brand_filter = filters.marca.map(item => item.label).join(',');
      }
      if (filters.modelo.length > 0) {
        filtersToSend.model_filter = filters.modelo.map(item => item.label).join(',');
      }
      if (filters.año.length > 0) {
        filtersToSend.year_filter = filters.año.map(item => item.value).join(',');
      }
      if (filters.categoría.length > 0) {
        filtersToSend.category_filter = filters.categoría.join(',');
      }
      const payload = {
        user_id: user?.uid,
        percentage: parseFloat(percentage.replace(',', '.')), // Convert the percentage with comma to a number
        ...filtersToSend,
      };
      const response = await changeAllPrices(payload);

      toast.success(response?.message, {
        style: {
          color: colors.text.primary,
          background: colors.neutral.green,
        }
      })
      // Restablecer los filtros después de aplicar los cambios
      setFilters({
        marca: [],
        modelo: [],
        año: [],
        categoría: [],
      });
      setPercentage('')
    } catch (error) {
      console.error('Error al aplicar los cambios:', error);
      // Maneja el error, muestra un mensaje de error, etc.
    } finally {
      setLoading(false);
      setShowConfirmationModal(false);
    }
  };

  const handlePercentageChange = (e) => {
    const value = e.target.value;
    if (/^[0-9,]*$/.test(value) && value.length <= 4 && (value.match(/,/g) || []).length <= 1) {
      setPercentage(value);
    }
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  return (
    <>
      {loading ? (
        <div className="text-center h-100 d-flex ">
          <Spinner
            animation="border"
            variant="primary"
            style={{
              margin: 'auto',
            }}
          />
        </div>
      ) : (
        <div className="containerAll p-4">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p className="deal-title discount-input-text">Modificar base</p>
            <MdEdit
              aria-label="editar"
              role="button"
              style={{ fontSize: '26px', color: '#6F42C1', cursor: 'pointer' }}
              onClick={toggleEditing}
            />
          </div>

          <Form>
            <div className="mb-4">
              <Form.Check
                type="radio"
                label="Modificar toda la base"
                name="modificationType"
                value="base"
                checked={modificationType === 'base'}
                onChange={handleModificationChange}
                className="form-check-label primary"
                id="modificar-toda-la-base"
              />
              <Form.Check
                type="radio"
                label="Aplicar filtro"
                name="modificationType"
                value="filter"
                checked={modificationType === 'filter'}
                onChange={handleModificationChange}
                className="form-check-label primary"
                id="aplicar-filtro"
              />
            </div>

            {modificationType === 'filter' && (
              <div className="mb-4">
                <Form.Check
                  type="radio"
                  label="Marca/Modelo"
                  name="filterType"
                  value="marcaModelo"
                  checked={filterType === 'marcaModelo'}
                  onChange={handleFilterTypeChange}
                  className="form-check-label primary"
                  id="marca-modelo"
                />
                <Form.Check
                  type="radio"
                  label="Año"
                  name="filterType"
                  value="año"
                  checked={filterType === 'año'}
                  onChange={handleFilterTypeChange}
                  className="form-check-label primary"
                  id="anio"
                />
                <Form.Check
                  type="radio"
                  label="Categoría"
                  name="filterType"
                  value="categoria"
                  checked={filterType === 'categoria'}
                  onChange={handleFilterTypeChange}
                  className="form-check-label primary"
                  id="categoria"
                />
              </div>
            )}

            {modificationType === 'filter' && filterType === 'marcaModelo' && (
              <div className="row mb-4">
                <div className="col">
                  <Form.Group controlId="marca">
                    <Form.Label>Marca</Form.Label>
                    <Select
                      className="custom-select-container"
                      styles={customSelectStyles}
                      isMulti
                      options={brands}
                      value={filters.marca}
                      onChange={handleMarcaModeloChange}
                      isDisabled={!isEditing}
                      placeholder="Todos"
                    />
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group controlId="modelo">
                    <Form.Label>Modelo</Form.Label>
                    <Select
                      className="custom-select-container"
                      styles={customSelectStyles}
                      isMulti
                      options={models}
                      value={filters.modelo}
                      onChange={handleModeloChange}
                      isDisabled={!isEditing || isModelSelectDisabled}
                      placeholder="Todos"
                    />
                  </Form.Group>
                </div>
              </div>
            )}

            {modificationType === 'filter' && filterType === 'año' && (
              <div className="mb-4">
                <Form.Group controlId="año">
                  <Form.Label>Año</Form.Label>
                  <Select
                    className="custom-select-container"
                    styles={customSelectStyles}
                    isMulti
                    options={years}
                    value={filters.año}
                    onChange={handleAñoChange}
                    isDisabled={!isEditing}
                    placeholder="Todos"
                  />
                </Form.Group>
              </div>
            )}

            {modificationType === 'filter' && filterType === 'categoria' && (
              <div className="mb-4">
                <Form.Group>
                  <Form.Label>Categoría</Form.Label>
                  <div>
                    {['A', 'B', 'C', 'D'].map((category) => (
                      <Form.Check
                        key={category}
                        type="checkbox"
                        label={category}
                        value={category}
                        className="form-check-inline"
                        onChange={handleCategoriaChange}
                        disabled={!isEditing}
                      />
                    ))}
                  </div>
                </Form.Group>
              </div>
            )}

            <div className="text-center mb-4">
              <input
                type="text"
                className="input-pricing text-center"
                value={percentage}
                onChange={handlePercentageChange}
                style={{ width: '100px' }}
                disabled={!isEditing}
                maxLength={4}
              />
            </div>

            <div className="text-center mb-4">
              <p className="text-muted discount-input-text">
                Ej: Si queres disminuir el precio un 10% el coeficiente será 0,9.
                <br />
                Si queres aumentar el pricing el coeficiente será 1,1.
              </p>
            </div>

            <div className="d-flex justify-content-between">
              <Button variant="outline-primary" onClick={toggleHistoryModal}>
                Historial de modificaciones
              </Button>
              <Button
                variant="primary"
                onClick={toggleConfirmationModal}
                disabled={!isEditing || !percentage || isNaN(parseFloat(percentage.replace(',', '.')))}
              >
                Aplicar cambios
              </Button>
            </div>
          </Form>
        </div>
      )}

      {/* Modal de Historial de Modificaciones */}
      <Modal show={showHistoryModal} onHide={toggleHistoryModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title className='view-title-page'>Historial de modificaciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingHistory ? (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <Table className="deals-table-style" responsive>
              <thead >
                <tr>
                  <th className="textHead">Fecha y hora</th>
                  <th className="textHead">Usuario</th>
                  <th className="textHead">Filtro</th>
                  <th className="textHead">Multiplicador</th>
                  <th className="textHead">Unidades mod.</th>
                </tr>
              </thead>
              <tbody>
                {historyData.length > 0 ? (
                  historyData.map((item, index) => (
                    <tr key={index}>
                      <td className="text-content-table-deals">{item.date}</td>
                      <td className="text-content-table-deals">{item.user}</td>
                      <td className="text-content-table-deals">{item.filter}</td>
                      <td className="text-content-table-deals">{item.change}</td>
                      <td className="text-content-table-deals">{item.number_of_changes}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No hay historial de modificaciones disponible.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </Modal.Body>
      </Modal>

      {/* Modal de Confirmación */}
      <Modal show={showConfirmationModal} onHide={toggleConfirmationModal} centered>
        <Modal.Body className="text-center">
          <h5 className='view-title-page'>¿Estás seguro de realizar este cambio?</h5>
          <p className='deal-title '>Vas a modificar <strong>{vehiclesToModify}</strong> vehículos</p>
          <div className="d-flex justify-content-center mt-4">
            <Button variant="outline-primary" className="me-2" onClick={toggleConfirmationModal}>
              No
            </Button>
            <Button variant="primary" onClick={handleModify}>
              Modificar
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
