import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputComponent from "../../../../components/InputComponent";
import { sendApoderado } from "../../services/TakeServices/HolderServices";

export default function LegalEntityModalComponent({
  modal,
  setModal,
  deal_id,
  setarrayPersonaJuridica,
  user,
}) {
  const { register, handleSubmit, reset } = useForm();

  const handleClose = () => setModal(false);

  const addPersonaJuridica = (data) => {
    setarrayPersonaJuridica((prevArray) => {
      const updatedArray = [...prevArray, data];
      sendApoderado(user, user?.company, deal_id, updatedArray);
      return updatedArray;
    });
    reset();
    setModal(false);
  };

  return (
    <>
      <Modal size="lg" show={modal} onHide={handleClose}>
        <form>
          <Modal.Header closeButton>
            <Modal.Title className="deal-title">Agregar titular</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
                <InputComponent
                  label={"Apoderado"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"name"}
                />
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"DNI Apoderado"}
                  placeholder=""
                  type="number"
                  register={register}
                  valueRegister={"dni"}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={handleSubmit(addPersonaJuridica)}
            >
              Agregar titular
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
