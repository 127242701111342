import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL_INSPECTION;

const axiosInstance = axios.create({
  baseURL: apiUrl,
  timeout: 20000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

const handleError = (error) => {
  if (error.response) {
    console.error('Error en la respuesta:', error.response.data);
  } else if (error.request) {
    console.error('No se recibió respuesta del servidor');
  } else {
    console.error('Error en la configuración de la solicitud:', error.message);
  }
};

export { apiUrl, axiosInstance, handleError };
