import React from 'react';
import { Button, Modal } from "react-bootstrap";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as locales from "react-date-range/dist/locale";
import { DateRange } from "react-date-range";
import { useState } from "react";

export default function ModalFilterDateNewComponent({
  openModal,
  setOpenModal,
  setState,
  state,
  onApply
}) {
  const [locale] = useState("es");

  const handleClose = () => {
    setOpenModal(false);
  };

  // Función para establecer el final del día
  const setEndOfDay = (date) => {
    date.setHours(23, 59, 59, 999);
    return date;
  };


  const handleChange = (item) => {
    let endDate = item.selection.endDate;
    setEndOfDay(endDate); // Usar la nueva función
    setState([item.selection]);
  };

  const sendApply = () => {
    const selectedDates = {
      from: state[0].startDate,
      to: state[0].endDate,
    };
    onApply(selectedDates);
    setOpenModal(false);
  };

  return (
    <Modal show={openModal} onHide={handleClose}>
      <DateRange
        editableDateInputs={true}
        onChange={handleChange}
        moveRangeOnFirstSelection={false}
        ranges={state}
        locale={locales[locale]}
        maxDate={new Date("2025-12-31")}
        minDate={new Date("2022-01-02")}
      />
      <div className="d-flex align-items-center justify-content-end">
        <Button
          size="sm"
          style={{ width: "65px", height: "30px" }}
          className="me-4 mb-4"
          onClick={sendApply}
        >
          Aplicar
        </Button>
      </div>
    </Modal>
  );
}