import { Form, Modal } from "react-bootstrap";
import { colors } from "../../styles/colors";
import { useEffect, useState } from "react";
import { sendCloseDeal } from "./servicesMenu";
import { useForm } from "react-hook-form";
import { MdClose } from "react-icons/md";
import shortSnapshotFirebase from "../../utils/ShortSnapshotFirebase";
import { teams } from "../../constants/teams";

const ButtonCloseDeal = ({ deal_id, user }) => {
  const [modal, setmodal] = useState(false);
  const [deal, setDeal] = useState(null);

  useEffect(() => {
    const callbackDeals = (data) => {
      setDeal(data);
    };
    const unsubscribeDeals = shortSnapshotFirebase(
      user?.company,
      "deals",
      deal_id,
      callbackDeals
    );
    return () => {
      unsubscribeDeals();
    };
  }, [deal_id, user?.company]);

  const { register, handleSubmit, reset } = useForm();
  const [valueRazon, setvalueRazon] = useState(false);
  const [valueNPS, setvalueNPS] = useState(true);
  const [valueTicketTrengo, setvalueTicketTrengo] = useState(false);
  const [valueTwoWeeks, setValueTwoWeeks] = useState(false);
  const [valueOneMonth, setValueOneMonth] = useState(false);

  const titleModal = {
    color: colors.primary.secondaryTwo,
    fontWeight: "500",
    fontSize: "16px",
    margin: "0px",
  };

  const btnYes = {
    backgroundColor: colors.primary.primary,
    border: "none",
    borderRadius: "8px",
    width: "100%",
    height: "50px",
    color: colors.neutral.light,
  };

  const closeDeal = (dataForm) => {
    const dateObject = {
      motivo_cierre: dataForm.motivo_cierre,
      comments: dataForm.comments,
      comunicar_cliente_sucursal: valueRazon,
      close_ticket_trengo: valueTicketTrengo,
      type_contact: valueOneMonth
        ? "contact_one_month"
        : valueTwoWeeks
        ? "contact_two_week"
        : false,
      NPS: valueNPS,
    };

    if (dataForm.motivo_cierre !== "Oferta baja") {
      const data = {
        "action_requested.action_id": "close",
        "action_requested.comments": "",
        "action_requested.user": user,
        "action_requested.close_data": dateObject,
      };
      sendCloseDeal(user?.company, deal_id, data);
    } else {
      const dataClose = {
        "action_requested.action_id": "close",
        "action_requested.comments": "",
        "action_requested.user": user,
        "action_requested.close_data": dateObject,
      };
      sendCloseDeal(user?.company, deal_id, dataClose);
      const data = {
        "action_requested.action_id": `${
          deal?.last_offer.offer_id === "final_offer"
            ? "offer-f"
            : deal?.last_offer.offer_id === "offer"
            ? "offer-i"
            : deal?.last_offer.offer_id === "range"
            ? "range"
            : deal?.last_offer.offer_id === "virtual_offer"
            ? "offer-v"
            : ""
        }-rejected`,
        "action_requested.comments": "",
        "action_requested.user": user,
        "action_requested.close_data": dateObject,
        "action_requested.type_offer": deal?.last_offer.offer_id,
      };
      setTimeout(() => {
        sendCloseDeal(user?.company, deal_id, data);
      }, 3000);
    }
    setmodal(false);
    reset();
  };

  const handleClose = () => setmodal(false);

  const handleChange = (e) => {
    setvalueRazon(
      e.target.value === "Rechazo en inspección sin OC" ||
        e.target.value === "Rechazo en inspección con OC"
    );
  };

  const handleCheckboxChange = () => {
    setvalueRazon(!valueRazon);
  };

  const handleCheckboxNPS = () => {
    if (user?.team !== teams.CS && user?.team !== teams.AS) {
      setvalueNPS(!valueNPS);
    }
  };

  const handleCheckboxChangeTicketTrengo = () => {
    setvalueTicketTrengo(!valueTicketTrengo);
  };

  const handleCheckboTwoWeeks = () => {
    setValueTwoWeeks((prevValue) => {
      if (!prevValue) {
        setValueOneMonth(false);
      }
      return !prevValue;
    });
  };

  const handleCheckboxOneMonth = () => {
    setValueOneMonth((prevValue) => {
      if (!prevValue) {
        setValueTwoWeeks(false);
      }
      return !prevValue;
    });
  };

  return (
    <>
      <div>
        <span onClick={() => setmodal(true)} style={{ cursor: "pointer" }}>
          <MdClose
            fontSize="20"
            color="white"
            style={{ backgroundColor: colors.status.red, borderRadius: "50%" }}
          />
        </span>
      </div>
      <Modal show={modal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <p style={titleModal}>Confirmar cierre del deal</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(closeDeal)}>
            <div className="d-flex align-items-start flex-column">
              <label className="label-input">Motivo de cierre</label>
              <Form.Select
                {...register("motivo_cierre")}
                onChange={(e) => handleChange(e)}
              >
                <option>Rechazo Comercial</option>
                <option>Rechazo en Inspección</option>
                <option>Oferta baja</option>
                <option>Cliente del Interior</option>
                <option>Solo queria cotizar</option>
                <option>Vendido</option>
                <option>Sin Respuesta</option>
                <option>Lead de Prueba</option>
                <option>Lead Duplicado</option>
                <option>Error de dato</option>
              </Form.Select>
              <label className="label-input mt-3">Comentario</label>
              <textarea
                className="inputModal"
                style={{ width: "100%", height: "110px" }}
                placeholder="Dejar observaciones adicionales"
                {...register("comments")}
              />
              <div className="d-flex align-items-center mt-3">
                <input
                  className="form-check-input"
                  style={{ margin: "0" }}
                  type="checkbox"
                  checked={valueRazon}
                  onChange={handleCheckboxChange}
                />
                <label
                  className="label-input-check ms-1"
                  style={{ margin: "0" }}
                >
                  Comunicar al cliente en sucursal
                </label>
              </div>
              <div className="d-flex align-items-center mt-1">
                <input
                  className="form-check-input"
                  style={{ margin: "0" }}
                  type="checkbox"
                  checked={valueNPS}
                  onChange={handleCheckboxNPS}
                />
                <label
                  className="label-input-check ms-1"
                  style={{ margin: "0" }}
                >
                  NPS
                </label>
              </div>
              <div className="d-flex align-items-center mt-1">
                <input
                  className="form-check-input"
                  style={{ margin: "0" }}
                  type="checkbox"
                  checked={valueTicketTrengo}
                  onChange={handleCheckboxChangeTicketTrengo}
                />
                <label
                  className="label-input-check ms-1"
                  style={{ margin: "0" }}
                >
                  Cerrar ticket en Trengo
                </label>
              </div>
              <div className="d-flex align-items-center mt-1">
                <input
                  className="form-check-input"
                  style={{ margin: "0" }}
                  type="checkbox"
                  checked={valueTwoWeeks}
                  onChange={handleCheckboTwoWeeks}
                />
                <label
                  className="label-input-check ms-1"
                  style={{ margin: "0" }}
                >
                  Contactar en 2 semanas
                </label>
              </div>
              <div className="d-flex align-items-center mt-1">
                <input
                  className="form-check-input"
                  style={{ margin: "0" }}
                  type="checkbox"
                  checked={valueOneMonth}
                  onChange={handleCheckboxOneMonth}
                />
                <label
                  className="label-input-check ms-1"
                  style={{ margin: "0" }}
                >
                  Contactar en 1 mes
                </label>
              </div>
              <button style={btnYes} className="mt-3" type="submit">
                Confirmar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ButtonCloseDeal;
