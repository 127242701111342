import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { MdEdit } from 'react-icons/md';
import toast from 'react-hot-toast';
import { colors } from '../../../../styles/colors';
import { getPricingSettings, savePricingSettings } from '../../services/sevicesPricingView';

export default function PricingDiscountsComponent() {
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [discounts, setDiscounts] = useState({
    range_parameters: [],
    adjustments: {
      gnc: { min_adjustment: '', max_adjustment: '' },
      car_kms_ranges: [{ min_adjustment: '', max_adjustment: '' }],
      category: { A: '', B: '', C: '', D: '', S: '' },
    },
  });

  useEffect(() => {
    getPricingSettings()
      .then((data) => {
        const initialData = {
          ...data,
          range_parameters: data.range_parameters.map((param) => ({
            ...param,
            min_value: param.min_value.toString(),
            max_value: param.max_value.toString(),
          })),
          adjustments: {
            ...data.adjustments,
            gnc: {
              min_adjustment: data.adjustments.gnc.min_adjustment.toString(),
              max_adjustment: data.adjustments.gnc.max_adjustment.toString(),
            },
            car_kms_ranges: data.adjustments.car_kms_ranges.map((range) => ({
              min_adjustment: range.min_adjustment.toString(),
              max_adjustment: range.max_adjustment.toString(),
            })),
            category: {
              A: data.adjustments.category.A.toString(),
              B: data.adjustments.category.B.toString(),
              C: data.adjustments.category.C.toString(),
              D: data.adjustments.category.D.toString(),
              S: data.adjustments.category.S.toString(),
            },
          },
        };
        setDiscounts(initialData);
        setLoading(false); // Solo se usa al cargar los datos iniciales
      })
      .catch((error) => {
        console.error('There was an error fetching the data!', error);
        setLoading(false);
      });
  }, []);

  const toggleEditing = () => setIsEditing(!isEditing);

  const handleInputChange = (e, section, field, index = null) => {
    const value = e.target.value;

    if (/^-?\d*\.?\d*$/.test(value)) {
      setDiscounts((prev) => {
        const updatedDiscounts = { ...prev };

        if (section === 'car_kms_ranges' && index !== null) {
          // Crear una copia del array para modificar solo el índice correspondiente
          const updatedCarKmsRanges = [...updatedDiscounts.adjustments.car_kms_ranges];
          updatedCarKmsRanges[index] = {
            ...updatedCarKmsRanges[index],
            [field]: value,  // Actualizar solo el campo específico en el índice correcto
          };
          updatedDiscounts.adjustments.car_kms_ranges = updatedCarKmsRanges;  // Actualizar el estado con el nuevo array
        } else {
          updatedDiscounts.adjustments[section] = {
            ...updatedDiscounts.adjustments[section],
            [field]: value,
          };
        }

        return updatedDiscounts;
      });
    }
  };


  const handleRangeInputChange = (e, index, field) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      const updatedParameters = [...discounts.range_parameters];
      updatedParameters[index][field] = value;
      setDiscounts({ ...discounts, range_parameters: updatedParameters });
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Activa el spinner en el botón

    const preparedDiscounts = {
      ...discounts,
      range_parameters: discounts.range_parameters.map((param) => ({
        ...param,
        min_value: parseFloat(param.min_value) || 0,
        max_value: parseFloat(param.max_value) || 0,
      })),
      adjustments: {
        ...discounts.adjustments,
        gnc: {
          min_adjustment: parseFloat(discounts.adjustments.gnc.min_adjustment) || 0,
          max_adjustment: parseFloat(discounts.adjustments.gnc.max_adjustment) || 0,
        },
        car_kms_ranges: discounts.adjustments.car_kms_ranges.map((range) => ({
          min_adjustment: parseFloat(range.min_adjustment) || 0,
          max_adjustment: parseFloat(range.max_adjustment) || 0,
        })),
        category: {
          A: parseFloat(discounts.adjustments.category.A) || 0,
          B: parseFloat(discounts.adjustments.category.B) || 0,
          C: parseFloat(discounts.adjustments.category.C) || 0,
          D: parseFloat(discounts.adjustments.category.D) || 0,
          S: parseFloat(discounts.adjustments.category.S) || 0,
        },
      },
    };

    savePricingSettings(preparedDiscounts)
      .then(() => {
        setIsEditing(false);
        setIsSubmitting(false); // Desactiva el spinner en el botón
        toast.success('Cambios guardados con éxito.', {
          style: {
            color: colors.text.primary,
            background: colors.neutral.green,
          },
        });
      })
      .catch((error) => {
        console.error('There was an error saving the data!', error);
        setIsSubmitting(false); // Desactiva el spinner en caso de error
      });
  };

  return (
    <>
      {loading ? (
        <div className="text-center h-100 d-flex">
          <Spinner
            animation="border"
            variant="primary"
            style={{
              margin: 'auto',
            }}
          />
        </div>
      ) : (
        <div className="containerAll">
          <form onSubmit={handleSave}>
            <div className="mt-1" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="deal-title ">Descuentos</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '30px', height: '30px' }}>
                  <MdEdit
                    data-testid="edit-button"
                    onClick={toggleEditing}
                    style={{ fontSize: '26px', color: '#6F42C1', cursor: 'pointer' }}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              {discounts.range_parameters.map((range, index) => (
                <div className="col mb-2" key={index}>
                  <p className="mb-1 discount-input-text">Menos de {range.max_kms.toLocaleString('de-DE')}km</p>
                  <div className="d-flex">
                    <span>
                      <input
                        type="text"
                        style={{ width: '80px' }}
                        className="input-pricing"
                        value={range.min_value}
                        disabled={!isEditing}
                        onChange={(e) => handleRangeInputChange(e, index, 'min_value')}
                      />
                      <p className="mb-1 max-min-text">min</p>
                    </span>
                    <span style={{ color: 'var(--border-cards)', paddingInline: '4px', paddingTop: '8px' }}>—</span>
                    <span>
                      <input
                        type="text"
                        style={{ width: '80px' }}
                        className="input-pricing"
                        value={range.max_value}
                        disabled={!isEditing}
                        onChange={(e) => handleRangeInputChange(e, index, 'max_value')}
                      />
                      <p className="mb-1 max-min-text">max</p>
                    </span>
                  </div>
                </div>
              ))}
            </div>

            <div className="divider mb-4 mt-3" />
            <div className="row justify-content-start">
              <div className="col-auto mb-2 me-4">
                <p className="mb-1 discount-input-text">GNC</p>
                <div className="d-flex">
                  <span>
                    <input
                      type="text"
                      style={{ width: '80px' }}
                      className="input-pricing"
                      value={discounts.adjustments.gnc.min_adjustment}
                      disabled={!isEditing}
                      onChange={(e) => handleInputChange(e, 'gnc', 'min_adjustment')}
                    />
                    <p className="mb-1 max-min-text">min</p>
                  </span>
                  <span style={{ color: 'var(--border-cards)', paddingInline: '4px', paddingTop: '8px' }}>—</span>
                  <span>
                    <input
                      type="text"
                      style={{ width: '80px' }}
                      className="input-pricing"
                      value={discounts.adjustments.gnc.max_adjustment}
                      disabled={!isEditing}
                      onChange={(e) => handleInputChange(e, 'gnc', 'max_adjustment')}
                    />
                    <p className="mb-1 max-min-text">max</p>
                  </span>
                </div>
              </div>

              <div className="col-auto mb-2">
                <p className="mb-1 discount-input-text">Entre 155 mil km y 200 mil km</p>
                <div className="d-flex">
                  <span>
                    <input
                      type="text"
                      style={{ width: '80px' }}
                      className="input-pricing"
                      value={discounts.adjustments.car_kms_ranges[0].min_adjustment}
                      disabled={!isEditing}
                      onChange={(e) => handleInputChange(e, 'car_kms_ranges', 'min_adjustment', 0)}  // El índice correcto (0) se pasa aquí
                    />
                    <p className="mb-1 max-min-text">min</p>
                  </span>
                  <span style={{ color: 'var(--border-cards)', paddingInline: '4px', paddingTop: '8px' }}>—</span>
                  <span>
                    <input
                      type="text"
                      style={{ width: '80px' }}
                      className="input-pricing"
                      value={discounts.adjustments.car_kms_ranges[0].max_adjustment}
                      disabled={!isEditing}
                      onChange={(e) => handleInputChange(e, 'car_kms_ranges', 'max_adjustment', 0)}  // El índice correcto (0) se pasa aquí
                    />
                    <p className="mb-1 max-min-text">max</p>
                  </span>
                </div>
              </div>
            </div>

            <div className="divider mb-4 mt-3" />
            <p className="deal-title ">Descuentos por categoría</p>
            <div className="row">
              {['A', 'B', 'C', 'D', 'S'].map((category) => (
                <div className="col mb-2" key={category}>
                  <p className="mb-1 discount-input-text">{category}:</p>
                  <input
                    data-testid={`input-category-${category}`}
                    type="text"
                    style={{ width: '80px' }}
                    className="input-pricing"
                    value={discounts.adjustments.category[category]}
                    disabled={!isEditing}
                    onChange={(e) => handleInputChange(e, 'category', category)}
                  />
                </div>
              ))}
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <Button type="submit" disabled={isSubmitting || !isEditing}>
                {isSubmitting ? (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                ) : (
                  'Guardar cambios'
                )}
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
