import React, { useEffect, useState } from "react";
import { Modal, Row, Button, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { colors } from "../../../../styles/colors";
import { useUser } from "../../../../context/UserContext";
import InputComponent from "../../../../components/InputComponent";
import {
  createPayments,
  editPayments,
} from "../../services/TakeServices/PaymentServices";
import { formatDecimalNumberTwo } from "../../../Pricing/services/servicesPricing";
import IconPercent from "../../images/icon-percent.svg";
import Swal from "sweetalert2";

export default function ModalPaymentScheduleComponent({
  setModal,
  modal,
  isEditing,
  setIsEditing,
  dataEditPayment,
  payments,
  titulares,
  money_reference,
  amount_to_pay_reference,
  isAdd,
  allPayments,
  totalTitularidad,
}) {
  const params = useParams();
  const { user } = useUser();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [valueTipoOperacion, setvalueTipoOperacion] = useState();
  const [valueMonto, setvalueMonto] = useState("");
  const [valuePorcentaje, setvaluePorcentaje] = useState("");
  const [isOtherTitular, setIsOtherTitular] = useState(false);

  useEffect(() => {
    if (isEditing) {
      setValue("titular", dataEditPayment?.titular);
      setValue("tipoOperacion", dataEditPayment?.tipoOperacion);
      // setValue("monto", dataEditPayment?.monto);
      // setvalueMonto(dataEditPayment?.monto);
      setValue("monto", formatDecimalNumberTwo(dataEditPayment?.monto));
      setvalueMonto(formatDecimalNumberTwo(dataEditPayment?.monto));
      setValue("porc", dataEditPayment?.porc);
      setvaluePorcentaje(dataEditPayment?.porc);
      setValue("moneda", dataEditPayment?.moneda);
      setValue("fechaAPagar", dataEditPayment?.fechaAPagar);
      setValue("fechaPago", dataEditPayment?.fechaPago);
    } else {
      setValue("moneda", money_reference);
      setvalueMonto(formatDecimalNumberTwo(`$${amount_to_pay_reference}`));
      setValue(
        "tipoOperacion",
        money_reference === "USD" ? "Efectivo" : "Transferencia"
      );
    }
  }, [isAdd, dataEditPayment, isEditing, setValue]);

  const editPaymentData = (data) => {
    const newObj = Object.assign({}, dataEditPayment, data);
    const indexPayment = payments.findIndex(
      (item) => item.payment_id === dataEditPayment.payment_id
    );
    payments[indexPayment] = newObj;
    editPayments(params.deal_id, payments, user);
    reset();
    setModal(false);
  };

  const addPayment = (data) => {
    if (isEditing) {
      //editar
      const nuevoPorcentaje = parseFloat(data.porc || 0);

      const totalSinEditar = allPayments.reduce((acc, item) => {
        if (item.titular !== data.titular) {
          return acc + (parseFloat(item.porc) || 0);
        }
        return acc;
      }, 0);

      const totalPorc = totalSinEditar + nuevoPorcentaje;

      if (totalPorc > 100) {
        Swal.fire({
          title: "Porcentaje de pago excedido",
          text: `El porcentaje total del pago no puede superar el 100%.`,
          icon: "error",
          confirmButtonColor: "#d33",
          confirmButtonText: "Aceptar",
        });
      } else {
        const updatedPayment = {
          ...data,
          monto: valueMonto,
        };
        editPaymentData(updatedPayment);
        toast.success("Informacion editada con exito.", {
          style: {
            color: colors.text.primary,
            background: colors.neutral.green,
          },
        });
        setvalueTipoOperacion();
        setvalueMonto("");
        setvaluePorcentaje("");
        reset();
        setModal(false);
      }
    } else if (valueMonto !== "$" && valuePorcentaje !== "%") {
      // crear
      const montoFormatt = valueMonto.toString().trim().replace("$", "");
      const valueMontoInput = parseFloat(
        montoFormatt.replace(/\./g, "").replace(",", ".")
      );
      const amountPaid = allPayments.reduce((acc, payment) => {
        const montoStr = payment?.monto?.toString().trim().replace("$", "");
        if (!montoStr) {
          return acc;
        }
        const monto = parseFloat(montoStr.replace(/\./g, "").replace(",", "."));
        if (isNaN(monto)) {
          return acc;
        }
        return acc + monto;
      }, 0);
      const totalStr = amount_to_pay_reference
        ?.toString()
        .trim()
        .replace("$", "");
      const totalAmountPaid = amountPaid + valueMontoInput;
      const total = parseFloat(totalStr.replace(/\./g, "").replace(",", "."));
      const estado = "pendiente";
      const file = "";

      const nuevoPorcentaje = parseFloat(data.porc || 0);
      const totalPorcentaje = totalTitularidad + nuevoPorcentaje;

      if (totalPorcentaje > 100) {
        Swal.fire({
          title: "Porcentaje de pago excedido",
          text: `El porcentaje total del pago no puede superar el 100%.`,
          icon: "error",
          confirmButtonColor: "#d33",
          confirmButtonText: "Aceptar",
        });
      } else {
        if (totalAmountPaid > total) {
          Swal.fire({
            title: "El monto ingresado es mayor al monto a pagar.",
            text: "¿Desea guardar igualmente el monto ingresado?",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Guardar",
          }).then((result) => {
            if (result.isConfirmed) {
              createPayments(
                params.deal_id,
                data.titular,
                data.tipoOperacion,
                valueMonto,
                data.porc,
                data.moneda,
                data.fechaAPagar,
                data.fechaPago,
                estado,
                file,
                user
              );
              toast.success("Informacion Guardada con exito.", {
                style: {
                  color: colors.text.primary,
                  background: colors.neutral.green,
                },
              });
              setvalueTipoOperacion();
              setvalueMonto("");
              setvaluePorcentaje("");
              reset();
              setModal(false);
            }
          });
        } else {
          createPayments(
            params.deal_id,
            data.titular,
            data.tipoOperacion,
            valueMonto,
            data.porc,
            data.moneda,
            data.fechaAPagar,
            data.fechaPago,
            estado,
            file,
            user
          );
          toast.success("Informacion Guardada con exito.", {
            style: {
              color: colors.text.primary,
              background: colors.neutral.green,
            },
          });
          setvalueTipoOperacion();
          setvalueMonto("");
          setvaluePorcentaje("");
          reset();
          setModal(false);
        }
      }
    }
  };

  const handleClose = () => {
    reset();
    setIsEditing(false);
    setIsOtherTitular(false);
    setModal(false);
  };

  const textStyle = {
    color: colors.primary.secondaryTwo,
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "0px",
  };

  const textError = {
    margin: "0px",
    color: colors.status.red,
    fontSize: "12px",
    width: "100%",
  };

  const handleInputChange = (e) => {
    setvalueTipoOperacion(e.target.value);
  };

  const handleApplyPercentage = () => {
    if (valueMonto && valuePorcentaje) {
      const montoNumerico = parseInt(
        valueMonto.replace(/\$/g, "").replace(/\./g, ""),
        10
      );
      const porcentajeNumerico = parseInt(valuePorcentaje, 10);

      const result = (montoNumerico * porcentajeNumerico) / 100;

      setvalueMonto(formatDecimalNumberTwo(`$${result}`));
      toast.success("Porcentaje aplicado al monto.", {
        style: {
          color: colors.text.primary,
          background: colors.neutral.green,
        },
      });
    } else {
      toast.error("Por favor, complete monto y porcentaje.", {
        style: {
          color: colors.text.primary,
          background: colors.status.red,
        },
      });
    }
  };

  const handleInputChangeMonto = (event) => {
    const inputValue = event.target.value;
    const cleanValue = inputValue.replace(/[^\d,]/g, "");
    const [integerPart, decimalPart] = cleanValue.split(",");
    const formattedInteger = integerPart
      ? parseInt(integerPart, 10).toLocaleString("es-AR")
      : "";
    const formattedValue =
      decimalPart !== undefined
        ? `${formattedInteger},${decimalPart}`
        : formattedInteger;
    setvalueMonto(`$${formattedValue}`);
    setvaluePorcentaje("");
  };

  const handleInputChangePorcentaje = (event) => {
    const inputValue = event.target.value;
    if (inputValue === "" || /^[1-9][0-9]?$|^100$/.test(inputValue)) {
      setvaluePorcentaje(inputValue);
    }
  };

  return (
    <>
      <Modal size="lg" show={modal} onHide={handleClose} centered>
        <form onSubmit={handleSubmit(addPayment)}>
          <Modal.Header closeButton>
            <Modal.Title className="deal-title">
              {isEditing ? "Editar" : "Crear"} Pago
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6} className="mb-2">
                <p style={textStyle} className="mb-2">
                  Titular
                </p>
                {!isOtherTitular ? (
                  <Form.Select
                    className="deal-select"
                    {...register("titular", { required: !isOtherTitular })}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === "Otro") {
                        setIsOtherTitular(true);
                        setValue("titular", ""); // Limpia el valor anterior
                      } else {
                        setIsOtherTitular(false);
                        setValue("titular", value);
                      }
                    }}
                  >
                    {titulares?.map((info, index) => (
                      <option key={index}>{info?.account_holder}</option>
                    ))}
                    <option value="Otro">Otro</option>
                  </Form.Select>
                ) : (
                  <>
                    <input
                      className="input-deal"
                      {...register("titular", { required: true })}
                      placeholder="Ingrese nombre del titular"
                    />
                    {errors.titular && (
                      <p style={textError} className="mt-1">
                        El campo titular es requerido
                      </p>
                    )}
                  </>
                )}
              </Col>
              <Col lg={6}>
                <p style={textStyle} className="mb-2">
                  Tipo de operación
                </p>
                <Form.Select
                  className="select-order"
                  style={{ width: "100%", color: colors.primary.secondaryTwo }}
                  {...register("tipoOperacion")}
                  onChange={(e) => handleInputChange(e)}
                >
                  <option>Transferencia</option>
                  <option>Efectivo</option>
                  <option>Permuta</option>
                  <option>Cheque a 30 días</option>
                  <option>Cheque a 60 días</option>
                  <option>Cheque a 90 días</option>
                  <option>Cheque a 120 días</option>
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <p style={textStyle} className="mb-2">
                  Monto
                </p>
                <input
                  className="input-deal"
                  {...register("monto", {
                    required: valueMonto !== "" ? false : true,
                  })}
                  value={valueMonto}
                  onChange={handleInputChangeMonto}
                />
                {errors.monto ? (
                  <p style={textError} className="mt-1">
                    El campo monto es requerido
                  </p>
                ) : null}
              </Col>
              <Col lg={3}>
                <p style={textStyle} className="mb-2">
                  Porcentaje
                </p>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <input
                      type="number"
                      disabled={valueTipoOperacion === "Permuta"}
                      className="input-deal me-2"
                      style={{
                        width: "70px",
                        height: "34px",
                        paddingRight: "30px",
                      }}
                      {...register("porc", {
                        required: valueTipoOperacion !== "Permuta",
                      })}
                      value={valuePorcentaje}
                      onChange={handleInputChangePorcentaje}
                      max={100}
                    />
                    <img
                      src={IconPercent}
                      alt="icon-percent"
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        pointerEvents: "none",
                        height: "20px",
                      }}
                    />
                  </div>
                  <Button
                    variant="primary"
                    onClick={handleApplyPercentage}
                    style={{ height: "34px", width: "80px" }}
                  >
                    Aplicar
                  </Button>
                </div>
                {errors.porc || valuePorcentaje === "%" ? (
                  <p style={textError} className="mt-1">
                    El campo porcentaje es requerido
                  </p>
                ) : null}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col lg={6} className="mt-1">
                <p style={textStyle} className="mb-2">
                  Moneda
                </p>
                <Form.Select
                  className="select-order"
                  style={{ width: "100%", color: colors.primary.secondaryTwo }}
                  {...register("moneda")}
                >
                  <option value="ARS">Pesos (ARS)</option>
                  <option value="USD">Dólares (USD)</option>
                </Form.Select>
              </Col>
              <Col lg={6} className="mt-1">
                <div>
                  <p style={textStyle} className="mb-2">
                    Fecha a pagar
                  </p>
                  <input
                    disabled={valueTipoOperacion === "Permuta" ? true : false}
                    style={{ width: "100%" }}
                    className="input-deal"
                    type="date"
                    {...register("fechaAPagar", {
                      required: valueTipoOperacion !== "Permuta" ? true : false,
                    })}
                  />
                </div>
                {errors.fechaAPagar ? (
                  <p style={textError} className="mt-1">
                    El campo fecha a pagar es requerido
                  </p>
                ) : null}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col lg={6} className="mt-1">
                <p style={textStyle} className="mb-2">
                  Fecha de pago
                </p>
                <InputComponent
                  disabled={valueTipoOperacion === "Permuta" ? true : false}
                  placeholder=""
                  type="date"
                  pattern="\d{4}-\d{2}-\d{2}"
                  register={register}
                  valueRegister={"fechaPago"}
                />
              </Col>
              <Col lg={6}></Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit">
              {isEditing ? "Editar" : "Crear"} Pago
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
