import React from "react";
import { Table } from "react-bootstrap";
import {
  guantera,
  listadoServices,
} from "../../../../../../constants/index";
import { colors } from "../../../../../../styles/colors";

export default function GuanteraComponent({
  disabled,
  register,
  dataGuantera,
}) {
  const styledisabled = {
    backgroundColor: colors.gris[100],
  };

  return (
    <div className="table-container">
      <p style={{ fontSize: "14px", marginBottom: "4px" }}>
        Accesorios - Guantera
      </p>
      <Table
        bordered
        className="check-box-table"
        style={disabled ? styledisabled : {}}
      >
        <thead>
          <tr className="table-header">
            <th></th>
            <th>Si</th>
            <th>No</th>
            <th>N/C</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Segunda llave </td>
            {dataGuantera?.segunda_llave !== undefined
              ? guantera.map((item, i) => (
                <td key={i}>
                  <input
                    className="form-check-input"
                    type="radio"
                    disabled={disabled}
                    defaultChecked={
                      disabled === true &&
                        item.value === dataGuantera.segunda_llave
                        ? true
                        : false
                    }
                    {...register(`${item.register}`)}
                    value={`${item.value}`}
                  />
                </td>
              ))
              : null}
          </tr>
          <tr>
            <td>Listado de services realizados </td>
            {dataGuantera?.service !== undefined
              ? listadoServices.map((item, i) => (
                <td key={i}>
                  <input
                    className="form-check-input"
                    type="radio"
                    disabled={disabled}
                    defaultChecked={
                      disabled === true && item.value === dataGuantera.service
                        ? true
                        : false
                    }
                    {...register(`${item.register}`)}
                    value={`${item.value}`}
                  />
                </td>
              ))
              : null}
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
