import React from "react";
import { Table } from "react-bootstrap";
import {
  seguridadMatafuegos,
  seguridadValiza,
  seguridadChaleco,
  seguridadBotiquin,
  seguridadLingaRemolque,
  seguridadOtros,
} from "./../../../../../../constants/index";
import { colors } from "../../../../../../styles/colors";

export default function SeguridadComponent({
  disabled,
  register,
  dataSeguridad,
}) {
  const styledisabled = {
    backgroundColor: colors.gris[100],
  };

  return (
    <div className="table-container">
      <p style={{ fontSize: "14px", marginBottom: "4px" }}>
        Accesorios - Seguridad
      </p>
      <Table
        bordered
        className="check-box-table"
        style={disabled ? styledisabled : {}}
      >
        <thead>
          <tr className="table-header">
            <th></th>
            <th>Si</th>
            <th>No</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Matafuegos</td>
            {dataSeguridad?.matafuegos !== undefined
              ? seguridadMatafuegos.map((item, i) => (
                <td key={i}>
                  <input
                    className="form-check-input"
                    type="radio"
                    disabled={disabled}
                    defaultChecked={
                      disabled === true &&
                        item.value === dataSeguridad.matafuegos
                        ? true
                        : false
                    }
                    {...register(`${item.register}`)}
                    value={`${item.value}`}
                  />
                </td>
              ))
              : null}
          </tr>
          <tr>
            <td>Balizas</td>
            {dataSeguridad?.valiza !== undefined
              ? seguridadValiza.map((item, i) => (
                <td key={i}>
                  <input
                    className="form-check-input"
                    type="radio"
                    disabled={disabled}
                    defaultChecked={
                      disabled === true && item.value === dataSeguridad.valiza
                        ? true
                        : false
                    }
                    {...register(`${item.register}`)}
                    value={`${item.value}`}
                  />
                </td>
              ))
              : null}
          </tr>
          <tr>
            <td>Chaleco</td>
            {dataSeguridad?.chaleco !== undefined
              ? seguridadChaleco.map((item, i) => (
                <td key={i}>
                  <input
                    className="form-check-input"
                    type="radio"
                    disabled={disabled}
                    defaultChecked={
                      disabled === true &&
                        item.value === dataSeguridad.chaleco
                        ? true
                        : false
                    }
                    {...register(`${item.register}`)}
                    value={`${item.value}`}
                  />
                </td>
              ))
              : null}
          </tr>
          <tr>
            <td>Botiquín</td>
            {dataSeguridad?.botiquin !== undefined
              ? seguridadBotiquin.map((item, i) => (
                <td key={i}>
                  <input
                    className="form-check-input"
                    type="radio"
                    disabled={disabled}
                    defaultChecked={
                      disabled === true &&
                        item.value === dataSeguridad.botiquin
                        ? true
                        : false
                    }
                    {...register(`${item.register}`)}
                    value={`${item.value}`}
                  />
                </td>
              ))
              : null}
          </tr>
          <tr>
            <td>Linga remolque</td>
            {dataSeguridad?.linga_remolque !== undefined
              ? seguridadLingaRemolque.map((item, i) => (
                <td key={i}>
                  <input
                    className="form-check-input"
                    type="radio"
                    disabled={disabled}
                    defaultChecked={
                      disabled === true &&
                        item.value === dataSeguridad.linga_remolque
                        ? true
                        : false
                    }
                    {...register(`${item.register}`)}
                    value={`${item.value}`}
                  />
                </td>
              ))
              : null}
          </tr>
          <tr>
            <td>Otros </td>
            {dataSeguridad?.otros !== undefined
              ? seguridadOtros.map((item, i) => (
                <td key={i}>
                  <input
                    className="form-check-input"
                    type="radio"
                    disabled={disabled}
                    defaultChecked={
                      disabled === true && item.value === dataSeguridad.otros
                        ? true
                        : false
                    }
                    {...register(`${item.register}`)}
                    value={`${item.value}`}
                  />
                </td>
              ))
              : null}
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
