import { axiosInstance, handleError } from "../../../services/apiConfigInsp";

export const getInspectionAppointments = async ({
  init_date,
  end_date,
  status,
  is_confirm,
  branch_id,
  page,
  page_size,
  pagination,
}) => {
  try {
    const params = new URLSearchParams({
      init_date,
      end_date,
      pagination
    });

    if(branch_id){
      params.append("appointmemt_location", branch_id)
    };

    if (is_confirm === true || is_confirm === false) {
      params.append('is_confirm', is_confirm);
    };

    if (pagination) {
      params.append("page", page);
      params.append("page_size", page_size);
    };

    if (Array.isArray(status)) {
      status.forEach((s) => params.append("status", s));
    } else if (status) {
      params.append("status", status);
    };

    const response = await axiosInstance.get(
      `/appointment-inspection/all?${params.toString()}`
    );

    if (pagination) {
      return response.data;
    } else {
      return { items: response.data };
    }
  } catch (error) {
    handleError(error);
    return {
      error: true,
      message: "Error al obtener los datos de inspecciones",
    };
  }
};
export const getAcquisitions = async ({
  init_date,
  end_date,
  status,
  is_confirm,
  branch_id,
  page,
  page_size,
  pagination,
}) => {
  try {
    const params = new URLSearchParams({
      init_date,
      end_date,
      pagination,
    });

    if(branch_id){
      params.append("appointmemt_location", branch_id)
    };

    if (is_confirm === true || is_confirm === false) {
      params.append('is_confirm', is_confirm);
    };

    if (pagination) {
      params.append("page", page);
      params.append("page_size", page_size);
    };

    if (Array.isArray(status)) {
      status.forEach((s) => params.append("status", s));
    } else if (status) {
      params.append("status", status);
    };

    const response = await axiosInstance.get(
      `/acquisitions/all?${params.toString()}`
    );

    if (pagination) {
      return response.data;
    } else {
      return { items: response.data };
    }
  } catch (error) {
    handleError(error);
    return {
      error: true,
      message: "Error al obtener los datos de Acquisitions",
    };
  }
};

