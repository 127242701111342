import React, { useEffect, useState } from "react";
import { Modal, Row, Button, Col, Form } from "react-bootstrap";
import InputComponent from "../../../../components/InputComponent";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { colors } from "./../../../../styles/colors";
import { useUser } from "../../../../context/UserContext";
import {
  createAccount,
  editAccount,
} from "../../services/TakeServices/PaymentServices";

export default function ModalAddOrEditAccountComponent({
  setModal,
  modal,
  isEditing,
  dataEditAccount,
  bank_accounts,
  titulares,
  titulares_array,
}) {
  const params = useParams();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const { user } = useUser();
  const [isOtherTitular, setIsOtherTitular] = useState(false);

  useEffect(() => {
    if (isEditing) {
      setValue("cbu", dataEditAccount?.cbu);
      setValue("bank", dataEditAccount?.bank);
      setValue("account_holder", dataEditAccount?.account_holder);
      setValue("CUIT", dataEditAccount?.CUIT);
    }
  }, [dataEditAccount, isEditing, setValue]);

  const textError = {
    margin: "0px",
    color: colors.status.red,
    fontSize: "12px",
    width: "100%",
  };

  const editAccountData = (data) => {
    const newObj = Object.assign({}, dataEditAccount, data);
    const indexAccount = bank_accounts.findIndex(
      (item) => item.id === dataEditAccount.id
    );
    bank_accounts[indexAccount] = newObj;
    editAccount(params.deal_id, bank_accounts, user);
    reset();
    setModal(false);
  };

  const addAccount = (data) => {
    if (isEditing) {
      //editar
      editAccountData(data);
      toast.success("Informacion editada con exito.", {
        style: {
          color: colors.text.primary,
          background: colors.neutral.green,
        },
      });
    } else {
      //crear
      createAccount(
        params.deal_id,
        data.cbu,
        data.bank,
        data.account_holder,
        data.CUIT,
        user
      );
      toast.success("Informacion Guardada con exito.", {
        style: {
          color: colors.text.primary,
          background: colors.neutral.green,
        },
      });
    }
    reset();
    setIsOtherTitular(false);
    setModal(false);
  };

  const handleClose = () => {
    reset();
    setIsOtherTitular(false);
    setModal(false);
  };

  return (
    <>
      <Modal size="lg" show={modal} onHide={handleClose}>
        <form onSubmit={handleSubmit(addAccount)}>
          <Modal.Header closeButton>
            <Modal.Title className="deal-title">
              {isEditing ? "Editar" : "Crear"} cuenta
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
                <InputComponent
                  label={"CBU"}
                  placeholder=""
                  type="number"
                  register={register}
                  valueRegister={"cbu"}
                />
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"Banco"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"bank"}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="select-container">
                  <label className="label-input">Titular de la cuenta</label>
                  {!isOtherTitular ? (
                    <Form.Select
                      className="deal-select"
                      {...register("account_holder", {
                        required: !isOtherTitular,
                      })}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === "Otro") {
                          setIsOtherTitular(true);
                          setValue("account_holder", "");
                        } else {
                          setIsOtherTitular(false);
                          setValue("account_holder", value);
                        }
                      }}
                    >
                      {titulares_array?.map((info, index) => (
                        <option key={index}>{info?.nombre_completo}</option>
                      ))}
                      <option value="Otro">Otro</option>
                    </Form.Select>
                  ) : (
                    <>
                      <input
                        className="input-deal"
                        {...register("account_holder", { required: true })}
                        placeholder="Ingrese nombre del titular"
                      />
                      {errors.titular && (
                        <p style={textError} className="mt-1">
                          El campo titular es requerido
                        </p>
                      )}
                    </>
                  )}
                </div>
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"CUIT"}
                  placeholder=""
                  type="number"
                  register={register}
                  valueRegister={"CUIT"}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit">
              {isEditing ? "Editar" : "Crear"} cuenta
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
