import React from "react";
import { Table } from "react-bootstrap";
import {
  generalesCrique,
  generalesRuedaAuxilio,
} from "../../../../../../constants";
import { generalesLlave } from "./../../../../../../constants/index";
import { colors } from "../../../../../../styles/colors";

export default function GeneralesComponent({
  disabled,
  register,
  dataGenerales,
}) {
  const styledisabled = {
    backgroundColor: colors.gris[100],
  };

  return (
    <div className="table-container">
      <p style={{ fontSize: "14px", marginBottom: "4px" }}>
        Accesorios - Generales
      </p>
      <Table
        bordered
        className="check-box-table"
        style={disabled ? styledisabled : {}}
      >
        <thead>
          <tr className="table-header">
            <th></th>
            <th>Si</th>
            <th>No</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Crique (gato) </td>
            {dataGenerales?.crique !== undefined
              ? generalesCrique.map((item, i) => (
                <td key={i}>
                  <input
                    className="form-check-input"
                    type="radio"
                    disabled={disabled}
                    defaultChecked={
                      disabled === true && item.value === dataGenerales.crique
                        ? true
                        : false
                    }
                    {...register(`${item.register}`)}
                    value={`${item.value}`}
                  />
                </td>
              ))
              : null}
          </tr>
          <tr>
            <td>Llave</td>
            {dataGenerales?.llave !== undefined
              ? generalesLlave.map((item, i) => (
                <td key={i}>
                  <input
                    className="form-check-input"
                    type="radio"
                    disabled={disabled}
                    defaultChecked={
                      disabled === true && item.value === dataGenerales.llave
                        ? true
                        : false
                    }
                    {...register(`${item.register}`)}
                    value={`${item.value}`}
                  />
                </td>
              ))
              : null}
          </tr>
          <tr>
            <td>Rueda auxilio</td>
            {dataGenerales?.rueda_auxilio !== undefined
              ? generalesRuedaAuxilio.map((item, i) => (
                <td key={i}>
                  <input
                    className="form-check-input"
                    type="radio"
                    disabled={disabled}
                    defaultChecked={
                      disabled === true &&
                        item.value === dataGenerales.rueda_auxilio
                        ? true
                        : false
                    }
                    {...register(`${item.register}`)}
                    value={`${item.value}`}
                  />
                </td>
              ))
              : null}
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
