import React, { useEffect, useState } from "react";
import useFirebaseOnSnapshot from "../../../../hooks/useFirebaseOnSnapshot";
import { useUser } from "../../../../context/UserContext";
import { useParams } from "react-router-dom";
import VirtualInspTabsComponent from "./VirtualInspTabsComponent";
import PhysicalInspectionComponent from "./PhysicalInspectionComponent";
import InspectionSectionsTabsComponent from "./InspectionSectionsTabsComponent";

export default function InspectionComponent({ dataAppointment }) {
  const { deal_id } = useParams();
  const { user } = useUser();

  const [dataResults, setdataResults] = useState();
  const [dataCheck, setdataCheck] = useState();
  const [dataResultFiles, setDataResultFiles] = useState();
  const { data } = useFirebaseOnSnapshot(user?.company, deal_id, "check");

  useEffect(() => {
    setdataResults(data.results);
    setdataCheck(data.check);
    setDataResultFiles(data.results?.files);
  }, [data]);

  return (
    <>
      <PhysicalInspectionComponent dataAppointment={dataAppointment} inspType={data?.type} />
      {data?.type === "virtual" ? (
        <div className="containerAll">
          <VirtualInspTabsComponent deal_id={deal_id} dataCheck={dataCheck} />
        </div>
      ) : (
        <div className="containerAll">
          <InspectionSectionsTabsComponent
            dataAppointment={dataAppointment}
            deal_id={deal_id}
            dataResults={dataResults}
            dataResultFiles={dataResultFiles}
            setDataResultFiles={setDataResultFiles} />
        </div>
      )}
    </>
  );
}