import React, { useEffect, useState } from "react";
import FinalOfferPricerModal from "./Modals/Pricer/FinalOfferPricerModal";
import InitialRangePricerModal from "./Modals/Pricer/InitialRangePricerModal";
import SalePricePricerModal from "./Modals/Pricer/SalePricePricerModal";
import VirtualOfferPricerModal from "./Modals/Pricer/VirtualOfferPricerModal";
import { useParams } from "react-router-dom";
import { useUser } from "../../../context/UserContext";
import InicialOfferPricerModal from "./Modals/Pricer/InicialOfferPricerModal";
import { getStatusColor } from "./../../../utils/StatusColor";
import { StatusIconComponent } from "./../../../components";
import LongSnapshotFirebase from "../../../utils/LongSnapshotFirebase";
import { colors } from "../../../styles/colors";

export default function PricerPricingComponent({
  finalOffer,
  rangeInitial,
  initialOffer,
  virtualOffer,
  salePrice,
  latestKey,
  setActiveModalPricing,
}) {
  const { deal_id } = useParams();
  const { user } = useUser();

  const [showRangeInitial, setshowRangeInitial] = useState(false);
  const [showOfferInitial, setshowOfferInitial] = useState(false);
  const [showOfferFinal, setshowOfferFinal] = useState(false);
  const [showOfferVirtual, setshowOfferVirtual] = useState(false);
  const [showSalePrice, setShowSalePrice] = useState(false);

  const [dataHistoryOfferI, setdataHistoryOfferI] = useState();
  const [dataHistoryFinalOffer, setdataHistoryFinalOffer] = useState();
  const [dataHistoryRange, setdataHistoryRange] = useState();
  const [dataHistoryVirtualOffer, setdataHistoryVirtualOffer] = useState();
  const [dataHistory, setdataHistory] = useState();
  const [valueCotizacion, setvalueCotizacion] = useState("");

  const [initialAptoConsignacion, setInitialAptoConsignacion] = useState(null);

  const quotationList = [
    "final_offer",
    "offer",
    "range",
    "sale_price",
    "virtual_offer",
  ];

  useEffect(() => {
    const callbackPricing = (dataPricing) => {
      setdataHistoryOfferI(dataPricing?.offer.history);
      setdataHistoryFinalOffer(dataPricing?.final_offer.history);
      setdataHistoryRange(dataPricing?.range.history);
      setdataHistoryVirtualOffer(dataPricing?.virtual_offer.history);

      setInitialAptoConsignacion(
        dataPricing?.final_offer?.consignment_ready ?? null
      );
    };
    const unsubscribePricing = LongSnapshotFirebase(
      user?.company,
      deal_id,
      "data",
      "pricing",
      callbackPricing
    );
    return () => {
      unsubscribePricing();
    };
  }, [deal_id, user?.company]);

  const handleShowRangeInitial = (value) => {
    setvalueCotizacion(value || "");
    setshowRangeInitial(true);
    setdataHistory(dataHistoryRange);
    setActiveModalPricing(true);
  };

  const handleShowOfferInitial = (value) => {
    setvalueCotizacion(value || "");
    setshowOfferInitial(true);
    setdataHistory(dataHistoryOfferI);
    setActiveModalPricing(true);
  };

  const handleShowOfferVirtual = (value) => {
    setvalueCotizacion(value || "");
    setshowOfferVirtual(true);
    setdataHistory(dataHistoryVirtualOffer);
    setActiveModalPricing(true);
  };

  const handleShowOfferFinal = (value) => {
    setvalueCotizacion(value || "");
    setshowOfferFinal(true);
    setdataHistory(dataHistoryFinalOffer);
    setActiveModalPricing(true);

    setInitialAptoConsignacion(finalOffer?.consignment_ready ?? null);
  };

  const handleShowSalePrice = (value) => {
    setvalueCotizacion(value || "");
    setShowSalePrice(true);
    setActiveModalPricing(true);
  };

  return (
    <>
      {rangeInitial?.enable === true && (
        <div
          className="containerPricing"
          onClick={() => handleShowRangeInitial(rangeInitial?.value)}
          style={{
            pointerEvents:
              quotationList[2] === latestKey ||
                rangeInitial?.status === "requested"
                ? "auto"
                : "none",
          }}
        >
          <p className="textTitle">Rango inicial</p>
          <div className="divider-pricing" />
          <div className="text-and-icon">
            <p
              className="textPricing"
              style={{
                color: getStatusColor(rangeInitial?.status),
              }}
            >
              {rangeInitial?.value
                ? rangeInitial?.value.startsWith("$")
                  ? rangeInitial?.value
                  : "$" + rangeInitial?.value
                : "$00.000.000"}
            </p>

            {rangeInitial?.status && (
              <StatusIconComponent status={rangeInitial?.status} />
            )}
          </div>
        </div>
      )}
      {initialOffer?.enable === true && (
        <div
          className="containerPricing"
          onClick={() => handleShowOfferInitial(initialOffer?.value)}
        >
          <p className="textTitle">Oferta inicial</p>
          <div className="divider-pricing" />
          <div className="text-and-icon">
            <p
              className="textPricing"
              style={{
                color: getStatusColor(initialOffer?.status),
              }}
            >
              {initialOffer?.value
                ? initialOffer?.value.startsWith("$")
                  ? initialOffer?.value
                  : "$" + initialOffer?.value
                : "$00.000.000"}
            </p>
            {initialOffer?.status && (
              <StatusIconComponent status={initialOffer?.status} />
            )}
          </div>
        </div>
      )}
      {virtualOffer?.enable === true && (
        <div
          className="containerPricing"
          onClick={() => handleShowOfferVirtual(virtualOffer?.value)}
          style={{
            pointerEvents:
              quotationList[4] === latestKey ||
                virtualOffer?.status === "requested"
                ? "auto"
                : "none",
          }}
        >
          <p className="textTitle">Oferta virtual</p>
          <div className="divider-pricing" />
          <div className="text-and-icon">
            <p
              className="textPricing"
              style={{
                color: getStatusColor(virtualOffer?.status),
              }}
            >
              {virtualOffer?.value
                ? virtualOffer?.value.startsWith("$")
                  ? virtualOffer?.value
                  : "$" + virtualOffer?.value
                : "$00.000.000"}
            </p>

            {virtualOffer?.status && (
              <StatusIconComponent status={virtualOffer?.status} />
            )}
          </div>
        </div>
      )}
      {finalOffer?.enable === true && (
        <div
          className="containerPricing"
          onClick={() => handleShowOfferFinal(finalOffer?.value)}
          style={{
            pointerEvents:
              quotationList[0] === latestKey ||
                finalOffer?.status === "requested"
                ? "auto"
                : "none",
          }}
        >
          <p className="textTitle">Oferta final</p>
          <div className="divider-pricing" />
          <div className="text-and-icon">
            <p
              className="textPricing"
              style={{
                color: getStatusColor(finalOffer?.status),
              }}
            >
              {finalOffer?.value
                ? finalOffer?.value.startsWith("$")
                  ? finalOffer?.value
                  : "$" + finalOffer?.value
                : "$00.000.000"}
            </p>
            {finalOffer?.status && (
              <StatusIconComponent status={finalOffer?.status} />
            )}
          </div>
        </div>
      )}
      {salePrice?.enable === true && (
        <div
          className="containerPricing"
          onClick={() => handleShowSalePrice(salePrice?.value_price)}
        >
          <p className="textTitle">Precio de venta</p>
          <div className="divider-pricing" />
          <div className="text-and-icon">
            <p
              className="textPricing"
              style={{
                color:
                  salePrice?.value_price !== ""
                    ? colors.primary.primary
                    : colors.primary.secondaryLight,
              }}
            >
              {salePrice?.value_price
                ? salePrice?.value_price.startsWith("$")
                  ? salePrice?.value_price
                  : "$" + salePrice?.value_price
                : "$00.000.000"}
              {salePrice?.value_days
                ? " - " + salePrice?.value_days + " días"
                : ""}
            </p>

            {salePrice?.status && (
              <StatusIconComponent status={salePrice?.status} />
            )}
          </div>
        </div>
      )}
      <InitialRangePricerModal
        deal_id={deal_id}
        setshowRangeInitial={setshowRangeInitial}
        showRangeInitial={showRangeInitial}
        user={user}
        dataHistory={dataHistory}
        valueCotizacion={valueCotizacion}
        setActiveModalPricing={setActiveModalPricing}
      />
      <InicialOfferPricerModal
        deal_id={deal_id}
        setshowOfferInitial={setshowOfferInitial}
        showOfferInitial={showOfferInitial}
        user={user}
        dataHistory={dataHistory}
        setActiveModalPricing={setActiveModalPricing}
      />
      <VirtualOfferPricerModal
        deal_id={deal_id}
        setshowOfferVirtual={setshowOfferVirtual}
        showOfferVirtual={showOfferVirtual}
        user={user}
        dataHistory={dataHistory}
        setActiveModalPricing={setActiveModalPricing}
      />
      <FinalOfferPricerModal
        deal_id={deal_id}
        setshowOfferFinal={setshowOfferFinal}
        showOfferFinal={showOfferFinal}
        user={user}
        dataHistory={dataHistory}
        setActiveModalPricing={setActiveModalPricing}
        initialAptoConsignacion={initialAptoConsignacion}
        valueCotizacion={valueCotizacion}
      />
      <SalePricePricerModal
        deal_id={deal_id}
        setShowSalePrice={setShowSalePrice}
        showSalePrice={showSalePrice}
        user={user}
        setActiveModalPricing={setActiveModalPricing}
      />
    </>
  );
}
