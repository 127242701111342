// FilterContext.js
import React, { createContext, useContext, useState } from 'react';

const FilterContext = createContext();

export function useFilters() {
  return useContext(FilterContext);
}

export function FilterProvider({ children }) {
  const [filters, setFilters] = useState({
    init_date: '',
    end_date: '',
    status: [],
  });

  const [page, setPage] = useState(1);

  const value = {
    filters,
    setFilters,
    page,
    setPage,
  };

  return (
    <FilterContext.Provider value={value}>
      {children}
    </FilterContext.Provider>
  );
}
