import React, { useEffect, useState } from "react";
import {
  AcquisitionsTableComponent,
  MainFilterDateComponent,
} from "../components";
import '../../../styles/style/ViewPages.css'
import { getAcquisitions } from "../services/newTablesSevices";
import { useDeals } from "../../../context/DealsContext";
import { useFilters } from "../../../context/FilterContext";


export default function AcquisitionsViewPage() {
  const { setViewName } = useDeals();
  const { filters, page, setFilters, setPage } = useFilters();

  useEffect(() => {
    setViewName("acquisitionsView");
  }, []);

  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const fetchDeals = async () => {
    setLoading(true);
    try {
      const responseData = await getAcquisitions({
        init_date: filters.init_date,
        end_date: filters.end_date,
        status: filters.status,
        is_confirm: filters.is_confirm === "si"? true: (filters.is_confirm === "no"? false: undefined),
        branch_id: filters.branch_id? filters.branch_id: "",
        page,
        page_size: pageSize,
        pagination: true,
      });

      if (!responseData.error) {
        setDeals(responseData.items);
        setTotalPages(responseData.total_pages);
      }
    } catch (error) {
      console.error("Error fetching deals:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDeals();
  }, [filters, page]);

  const handleStatusChange = (status) => {
    setFilters((prevFilters) => {
      const currentStatus = Array.isArray(prevFilters.status)
        ? prevFilters.status
        : [];
      const newStatus = currentStatus.includes(status)
        ? currentStatus.filter((s) => s !== status)
        : [...currentStatus, status];

      return { ...prevFilters, status: newStatus };
    });
    setPage(1);
  };

  return (
    <div className="p-3 h-100">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="view-title-page">Acquisitions</h1>
        <MainFilterDateComponent />
      </div>
      <AcquisitionsTableComponent
        deals={deals}
        loading={loading}
        onStatusChange={handleStatusChange}
        totalPages={totalPages}
      />
    </div>
  );
}
