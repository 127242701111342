import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BiBellPlus } from "react-icons/bi";
import { updateActionDone } from "../services/servicesStates";
import ReminderModalComponent from "./ReminderModalComponent";
import { colors } from "../../../styles/colors";

const ModalActions = ({
  showModalRevision,
  setshowModalRevision,
  user,
  deal_id,
  actionId,
  idAction,
  nameAction,
  date,
}) => {
  const { register, handleSubmit, reset } = useForm();
  const [modalReminder, setmodalReminder] = useState(false);

  const handleRevisionModal = () => {
    setshowModalRevision(false);
  };

  const endAction = (dataComment, status) => {
    const data = {
      "action_status.action_id": actionId,
      "action_status.id": idAction,
      "action_status.user": user,
      "action_status.comments": dataComment.comments,
      "action_status.date": new Date(),
      "action_status.status": status,
    };
    updateActionDone(user?.company, deal_id, data);
    reset();
    setshowModalRevision(false);
  };

  return (
    <Modal
      show={showModalRevision}
      onHide={handleRevisionModal}
      centered
      dialogClassName="myModal"
    >
      <Modal.Body>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div
            className="d-flex  align-items-center justify-content-between"
            style={{ width: "100%" }}
          >
            <div>
              <p className="titleModal">{nameAction}</p>
              <p
                style={{
                  color: colors.neutral.lightTwo,
                  margin: "0px",
                  fontSize: "13px",
                }}
              >
                {new Date(date?.seconds * 1000).toLocaleDateString()}
              </p>
            </div>
            <BiBellPlus
              fontSize="24px"
              color={colors.primary.primary}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setmodalReminder(true);
              }}
            />
            <ReminderModalComponent
              modal={modalReminder}
              setmodal={setmodalReminder}
              user={user}
              deal_id={deal_id}
              actionId={actionId}
              nameActions={nameAction}
              setshowModalRevision={setshowModalRevision}
            />
          </div>
          <form style={{ width: "100%" }}>
            <textarea
              className="inputModal mt-3"
              style={{ width: "100%", height: "110px" }}
              placeholder="Dejar comentario opcional"
              {...register("comments")}
            />
            <div
              style={{ width: "100%" }}
              className="d-flex flex-column align-items-center justify-content-between"
            >
              <button
                variant="primary"
                className="btnSendPricing mb-2 mt-3"
                type="submit"
                onClick={handleSubmit((data) => endAction(data, "done"))}
              >
                Completar acción
              </button>
              <button
                variant="primary"
                className="btnCancelRevision mb-2"
                onClick={handleSubmit((data) => endAction(data, "cancelled"))}
              >
                Cancelar acción
              </button>
            </div>
          </form>
          <button
            variant="secondary"
            className="btnCancelarModal mt-3"
            onClick={() => setshowModalRevision(false)}
          >
            Cerrar
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalActions;
