import { db } from "../../firebase/config";
import {
  collection,
  query,
  where,
  and,
  onSnapshot,
  getDocs,
  getDoc,
  doc,
  orderBy,
  limit,
} from "firebase/firestore";

const isSameDate = function (date, last_appointment_date) {
  const compareDate1 = new Date(last_appointment_date); // Get the current date
  compareDate1.setHours(0, 0, 0, 0); // Set the time to midnight for accurate comparison

  const compareDate2 = new Date(date); // Convert the input date to a Date object
  compareDate2.setHours(0, 0, 0, 0); // Set the time to midnight for accurate comparison

  return compareDate1.getTime() === compareDate2.getTime();
};

const formatDate = function (date) {
  return (
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2)
  );
};

export class IncomingLeadsView {
  constructor(filters, companyData, user) {
    this.user = user || null;
    this.companyData = companyData || null;
    this.filters = filters || null;
    this.loadMore = true;
    this.countingDealsPending = false;
  }

  getDatesForQuery() {
    let fromDate = this.filters.selectedDates.from;
    let toDate = this.filters.selectedDates.to;
    if (fromDate === "") {
      fromDate = new Date();
      fromDate.setDate(fromDate.getDate());
      fromDate.setHours(0, 0, 0, 0);
    }
    if (toDate === "") {
      toDate = new Date();
      toDate.setDate(toDate.getDate() + 1);
      toDate.setHours(0, 0, 0, 0);
    }
    return [fromDate, toDate];
  }

  buildQuery() {
    const filters = this.filters;

    // Create the base query with the date filters
    let baseQuery = query(
      collection(db, "companies", this.user?.company, "deals")
    );

    baseQuery = query(baseQuery, where("status.status", "==", "ABIERTO"));

    // Order filter
    const orderDirection = "desc";
    const dateFilterField = "created";
    baseQuery = query(baseQuery, orderBy(dateFilterField, orderDirection));

    const limitDeals = this.companyData?.settings?.deals_init_limit;
    baseQuery = query(baseQuery, limit(limitDeals * filters.limitFactor));

    return baseQuery;
  }

  getLoadMore(snapshotDeals) {
    return;
  }

  async processDeals(firebaseDeals, setDeals, setLoadingDeals) {
    let processedDeals = [];
    let processedDealsIds = [];
    let [fromDate, toDate] = this.getDatesForQuery();
    for (const deal of firebaseDeals) {
      if (deal.status === "created_from_landing") {
        continue;
      }
      if (!processedDealsIds.includes(deal.id)) {
        let processedDeal = {};
        const date = new Date(deal.created);
        processedDeal.deal_data = deal;
        processedDeal.date = formatDate(date);
        processedDeal.hour =
          ("0" + date.getHours()).slice(-2) +
          ":" +
          ("0" + date.getMinutes()).slice(-2);
        processedDeal.cs = deal.user_team?.CS || "";
        processedDeal.actions = deal.actions || [];
        processedDeal.car = deal.car || "";
        processedDeal.name = deal.name || "";
        processedDeal.car_id = deal.car_id || "";
        processedDeal.trengo_ticket_id = deal.trengo_ticket_id || "";
        processedDeal.user_team = deal.user_team || {};
        processedDeal.current_user_team = deal.current_user_team || {};
        processedDeal.id = deal.id;
        processedDeal.status = deal.status.phase;
        processedDeals.push(processedDeal);
        processedDealsIds.push(deal.id);
      }
    }
    try {
      // Update the state with the processedDeals array
      setDeals(processedDeals);
      setLoadingDeals(false);
    } catch (error) {
      console.error(error);
      setLoadingDeals(false);
      // Handle the error
    }
  }

  doSnapshot(firebaseQuery, setFirebaseDeals, setLoadMore) {
    return onSnapshot(firebaseQuery, async (querySnapshot) => {
      // Inspecciones
      let snapshotDeals = querySnapshot.docs.map((docSnapshot) => {
        let deal_data = docSnapshot.data();
        deal_data.id = docSnapshot.id;
        return deal_data;
      });
      if (
        snapshotDeals.length <
        this.companyData?.settings?.deals_init_limit * this.filters.limitFactor
      ) {
        setLoadMore(false);
      } else {
        setLoadMore(true);
      }
      const filteredDeals = snapshotDeals.filter(
        (deal) => typeof deal?.actions?.requested_datetime !== "string"
      );
      setFirebaseDeals(filteredDeals);
    });
  }
}
