import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Container,
  Form,
  Row,
  Col,
  Badge,
  Button,
} from "react-bootstrap";
import { MdClose, MdFilterList } from "react-icons/md";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Select from "react-select";
import customSelectStyles from "../../styles/customSelectStyles";
import {
  getBrands,
  getMinAndMaxPrices,
  getModels,
  getVersions,
} from "../../services/sevicesPricingView";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";

export default function PricingFilterButtonComponent({
  filterValues,
  setFilterValues,
  setCurrentPage,
}) {
  const [openFilters, setOpenFilters] = useState(false);
  const [stopperFilters, setStopperFilters] = useState(
    filterValues.stopper_filter ? filterValues.stopper_filter.split(",") : []
  );
  const [categoryFilters, setCategoryFilters] = useState(
    filterValues.category_filter ? filterValues.category_filter.split(",") : []
  );
  const [years, setYears] = useState([]);
  const [rangeValues, setRangeValues] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [orderByPrices, setOrderByPrices] = useState(filterValues.order_by_prices || "");
  const [orderByName, setOrderByName] = useState(filterValues.order_by_name || "");
  const [orderByYear, setOrderByYear] = useState(filterValues.order_by_year || "");
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [versions, setVersions] = useState([]);
  const [showRange, setShowRange] = useState();

  let isPageWide = useMediaQuery("(min-width: 460px)");

  useEffect(() => {
    async function fetchData() {
      try {
        const brandsData = await getBrands();
        const transformedBrands = brandsData.brands.map((brand) => ({
          value: brand.name,
          label: brand.name,
        }));
        setBrands(transformedBrands);
        if (selectedBrands.length === 1) {
          try {
            const data = await getModels(selectedBrands[0].value);
            setModels(data.models);
          } catch (error) {
            console.error(error);
          }
        } else {
          setModels([]);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [selectedBrands]);

  useEffect(() => {
    async function fetchData() {
      try {
        const minAndMaxData = await getMinAndMaxPrices();
        const { min_price, max_price } = minAndMaxData;
        setRangeValues([parseFloat(min_price), parseFloat(max_price)]);
        const formattedMinPrice = parseFloat(min_price).toLocaleString("es-AR");
        const formattedMaxPrice = parseFloat(max_price).toLocaleString("es-AR");
        setShowRange([formattedMinPrice, formattedMaxPrice]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
    const generateYears = () => {
      const currentYear = new Date().getFullYear();
      const yearsArray = [];
      for (let year = 2000; year <= currentYear; year++) {
        yearsArray.push({ value: year.toString(), label: year.toString() });
      }
      return yearsArray;
    };
    setYears(generateYears());
  }, []);

  const handleBrandChange = (selectedOptions) => {
    setSelectedBrands(selectedOptions);
    const selectedValues = selectedOptions.map((option) => option.value).join(",");
    setFilterValues((prevState) => ({
      ...prevState,
      brand_filter: selectedValues,
    }));
    setCurrentPage(1);
  };

  const handleModelChange = async (selectedModel) => {
    setFilterValues((prevState) => ({
      ...prevState,
      model_filter: selectedModel || "",
    }));
    setCurrentPage(1);
    if (selectedModel) {
      try {
        const data = await getVersions(filterValues.brand_filter, selectedModel);
        setVersions(data.versions);
      } catch (error) {
        console.error(error);
      }
    } else {
      setVersions([]);
    }
  };

  const handleVersionChange = (selectedVersion) => {
    setFilterValues((prevState) => ({
      ...prevState,
      version_filter: selectedVersion || "",
    }));
    setCurrentPage(1);
  };

  const handleNameOrderChange = (selectedOrder) => {
    setOrderByName(selectedOrder);
    setFilterValues((prevState) => ({
      ...prevState,
      order_by_name: selectedOrder,
      order_by_prices: "",
      order_by_year: "",
    }));
    setOrderByPrices("");
    setOrderByYear("");
    setCurrentPage(1);
  };
  const handlePriceOrderChange = (selectedOrder) => {
    setOrderByPrices(selectedOrder);
    setFilterValues((prevState) => ({
      ...prevState,
      order_by_prices: selectedOrder,
      order_by_name: "",
      order_by_year: "",
    }));
    setOrderByName("");
    setOrderByYear("");
    setCurrentPage(1);
  };
  const handleYearOrderChange = (selectedOrder) => {
    setOrderByYear(selectedOrder);
    setFilterValues((prevState) => ({
      ...prevState,
      order_by_year: selectedOrder,
      order_by_name: "",
      order_by_prices: "",
    }));
    setOrderByName("");
    setOrderByPrices("");
    setCurrentPage(1);
  };

  const handleSliderChange = (values) => {
    const formattedMinPrice = parseFloat(values[0]).toLocaleString("es-AR");
    const formattedMaxPrice = parseFloat(values[1]).toLocaleString("es-AR");
    setShowRange([formattedMinPrice, formattedMaxPrice]);
    setFilterValues((prevState) => ({
      ...prevState,
      price_filter_min: values[0],
      price_filter_max: values[1],
    }));
    setCurrentPage(1);
  };

  const handleYearsToggle = (selectedOptions) => {
    setSelectedYears(selectedOptions);
    const selectedValues = selectedOptions.map((option) => option.value).join(",");
    setFilterValues((prevState) => ({
      ...prevState,
      year_filter: selectedValues,
    }));
    setCurrentPage(1);
  };

  const stopperLabels = ["SI", "NO", "Hasta 80.000KMS", "Hasta 100.000KMS"];
  const categoryLabels = ["A", "B", "C", "D", "S", "X"];
  const sortingOptions = [
    { value: "asc", label: "Ascendente" },
    { value: "desc", label: "Descendente" },
  ];

  const handleStopperToggle = (stopper) => {
    let newStopperFilters;
    if (stopperFilters.includes(stopper)) {
      newStopperFilters = stopperFilters.filter((s) => s !== stopper);
    } else {
      newStopperFilters = [...stopperFilters, stopper];
    }
    setStopperFilters(newStopperFilters);
    setFilterValues((prevState) => ({
      ...prevState,
      stopper_filter: newStopperFilters.join(","),
    }));
    setCurrentPage(1);
  };

  const handleCategoryToggle = (category) => {
    let newCategoryFilters;
    if (categoryFilters.includes(category)) {
      newCategoryFilters = categoryFilters.filter((c) => c !== category);
    } else {
      newCategoryFilters = [...categoryFilters, category];
    }
    setCategoryFilters(newCategoryFilters);
    setFilterValues((prevState) => ({
      ...prevState,
      category_filter: newCategoryFilters.join(","),
    }));
    setCurrentPage(1);
  };

  const handleFilterBadgeRemove = (filterType, itemToRemove) => {
    if (filterType === "stopper_filter") {
      const newStopperFilters = stopperFilters.filter((s) => s !== itemToRemove);
      setStopperFilters(newStopperFilters);
      setFilterValues((prevState) => ({
        ...prevState,
        stopper_filter: newStopperFilters.join(","),
      }));
      setCurrentPage(1);
      return;
    } else if (filterType === "category_filter") {
      const newCategoryFilters = categoryFilters.filter((c) => c !== itemToRemove);
      setCategoryFilters(newCategoryFilters);
      setFilterValues((prevState) => ({
        ...prevState,
        category_filter: newCategoryFilters.join(","),
      }));
      setCurrentPage(1);
      return;
    } else if (filterType === "brand_filter") {
      const currentValues = filterValues.brand_filter
        ? filterValues.brand_filter.split(",")
        : [];
      const newValues = currentValues.filter((v) => v !== itemToRemove);
      setFilterValues((prevState) => ({
        ...prevState,
        brand_filter: newValues.join(","),
      }));
      setSelectedBrands((prev) => prev.filter((b) => b.value !== itemToRemove));
      setCurrentPage(1);
      return;
    } else if (filterType === "year_filter") {
      const currentValues = filterValues.year_filter
        ? filterValues.year_filter.split(",")
        : [];
      const newValues = currentValues.filter((y) => y !== itemToRemove);
      setFilterValues((prevState) => ({
        ...prevState,
        year_filter: newValues.join(","),
      }));
      setSelectedYears((prev) => prev.filter((y) => y.value !== itemToRemove));
      setCurrentPage(1);
      return;
    } else if (filterType === "model_filter") {
      setFilterValues((prevState) => ({
        ...prevState,
        model_filter: "",
      }));
      setModels([]);
      setVersions([]);
      setCurrentPage(1);
      return;
    } else if (filterType === "version_filter") {
      setFilterValues((prevState) => ({
        ...prevState,
        version_filter: "",
      }));
      setVersions([]);
      setCurrentPage(1);
      return;
    } else if (filterType === "order_by_prices" || filterType === "order_by_name" || filterType === "order_by_year") {
      setFilterValues((prevState) => ({
        ...prevState,
        [filterType]: "",
      }));
      if (filterType === "order_by_prices") setOrderByPrices("");
      if (filterType === "order_by_name") setOrderByName("");
      if (filterType === "order_by_year") setOrderByYear("");
      setCurrentPage(1);
      return;
    } else if (filterType === "price_filter_min" || filterType === "price_filter_max") {
      setFilterValues((prevState) => ({
        ...prevState,
        price_filter_min: 0,
        price_filter_max: 600000,
      }));
      setCurrentPage(1);
      return;
    }
  };


  function clearFilters() {
    setStopperFilters([]);
    setCategoryFilters([]);
    setSelectedBrands([]);
    setSelectedYears([]);
    setOrderByName("");
    setOrderByPrices("");
    setOrderByYear("");
    setFilterValues({
      stopper_filter: "",
      category_filter: "",
      brand_filter: "",
      year_filter: "",
      model_filter: "",
      version_filter: "",
      price_filter_min: 0,
      price_filter_max: 600000,
      order_by_prices: "",
      order_by_name: "",
      order_by_year: "",
    });
    setCurrentPage(1);
    setOpenFilters(false);
  }

  return (
    <Dropdown
      show={openFilters}
      onToggle={(isOpen) => setOpenFilters(isOpen)}
      autoClose="outside"
    >
      <Dropdown.Toggle id="dropdown-basic">
        <MdFilterList className="filter-icon-button" fontSize={18} />
        Filtro
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{
          width: isPageWide ? "40vw" : "100vw",
          padding: "1rem",
          zIndex: "1",
          boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.20)",
        }}
        className="mt-2"
      >
        <Container className="mb-2 mt-2">
          <p className="tc-ref-text">Filtros aplicados:</p>
          <div className="d-inline-flex flex-wrap">
            {Object.entries(filterValues).map(([filterType, filterValue]) => {
              if (typeof filterValue === "string" && filterValue.trim() !== "") {
                return filterValue.split(",").map((item, index) => {
                  const itemTrimmed = item.trim();
                  if (itemTrimmed === "") return null;
                  return (
                    <Badge
                      key={`${filterType}-${index}`}
                      className="custom-badge me-2 mb-3 d-flex align-items-center fw-normal"
                      onClick={() => handleFilterBadgeRemove(filterType, itemTrimmed)}
                    >
                      {itemTrimmed}
                      <MdClose
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          cursor: "pointer",
                          marginLeft: "3px",
                        }}
                      />
                    </Badge>
                  );
                });
              }
              return null;
            })}
          </div>
          <div className="divider" />
        </Container>
        <Container className="mb-2 mt-2">
          <p className="tc-ref-text me-3">Filtrar por stopper</p>
          <div className="d-flex ">
            {stopperLabels.map((stopper, index) => (
              <div className="w-100" key={stopper}>
                <Form.Check
                  type="checkbox"
                  id={`stopper-checkbox-${stopper}-${index}`}
                  label={stopper}
                  checked={stopperFilters.includes(stopper)}
                  onChange={() => handleStopperToggle(stopper)}
                />
              </div>
            ))}
          </div>
        </Container>
        <Container className="mb-2 mt-2 ">
          <p className="tc-ref-text">Filtrar por categoria</p>
          <div className=" d-flex flex-row mb-2">
            {categoryLabels.map((category, index) => (
              <div className="w-100" key={category}>
                <Form.Check
                  type="checkbox"
                  id={`stopper-checkbox-${category}-${index}`}
                  label={category}
                  checked={categoryFilters.includes(category)}
                  onChange={() => handleCategoryToggle(category)}
                />
              </div>
            ))}
          </div>
          <div className="divider" />
        </Container>
        <Row>
          <Col xs={12} lg={6} className="mb-2 mt-1">
            <Container className="mb-2 mt-2">
              <p className="tc-ref-text">Rango de precio USD:</p>
              <div style={{ padding: "4px 16px" }}>
                {showRange ? `$ ${showRange[0]} - $ ${showRange[1]}` : ""}
                <Slider
                  className="slider-range"
                  min={rangeValues[0]}
                  max={rangeValues[1]}
                  step={1000}
                  range
                  value={[
                    filterValues.price_filter_min,
                    filterValues.price_filter_max,
                  ]}
                  onChange={handleSliderChange}
                />
              </div>
            </Container>
          </Col>
          <Col xs={12} lg={6} className="mb-2 mt-1">
            <Container className="mb-2 mt-2">
              <p className="tc-ref-text mb-4">Ordenar por precio</p>
              {sortingOptions.map((option) => (
                <Form.Check
                  key={option.value}
                  inline
                  label={option.label}
                  type="radio"
                  id={`sort${option.value}`}
                  name={`sortingPrices_${option.value}`}
                  value={option.value}
                  checked={orderByPrices === option.value}
                  onChange={() => handlePriceOrderChange(option.value)}
                  disabled={orderByName || orderByYear}
                />
              ))}
            </Container>
          </Col>
        </Row>
        <div className="divider" />
        <Row className="mt-2 ">
          <Col xs={12} lg={6}>
            <Container className="mb-2 mt-2">
              <p className="tc-ref-text">Filtro de Marca</p>
              <Select
                styles={customSelectStyles}
                isMulti
                value={selectedBrands}
                onChange={handleBrandChange}
                options={brands}
                placeholder="Seleccione"
              />
            </Container>
            <Container className="mb-2 mt-2">
              <p className="tc-ref-text">Filtro de modelo</p>
              <Form.Select
                className="filter-select"
                value={filterValues.model_filter || ""}
                onChange={(e) =>
                  handleModelChange(e.target.selectedOptions[0].text)
                }
                disabled={
                  !filterValues.brand_filter || selectedBrands.length > 1
                }
              >
                <option value="">
                  {!filterValues.model_filter
                    ? "Seleccione"
                    : filterValues.model_filter}
                </option>
                {models?.map((model, i) => (
                  <option key={i} value={model.id}>
                    {model.name}
                  </option>
                ))}
              </Form.Select>
            </Container>

            <Container className="mb-2 mt-2">
              <p className="tc-ref-text">Filtro de versión</p>
              <Form.Select
                className="filter-select"
                value={filterValues.version_filter || ""}
                onChange={(e) =>
                  handleVersionChange(e.target.selectedOptions[0].text)
                }
                disabled={
                  !filterValues.model_filter || selectedBrands.length > 1
                }
              >
                <option value="">
                  {!filterValues.version_filter
                    ? "Seleccione"
                    : filterValues.version_filter}
                </option>
                {versions?.map((version, i) => (
                  <option key={i} value={version.id}>
                    {version.name}
                  </option>
                ))}
              </Form.Select>
            </Container>
            <Container className="mb-2 mt-3">
              <p className="tc-ref-text mb-2">
                Ordenar por marca, modelo y versión
              </p>
              {sortingOptions.map((option) => (
                <Form.Check
                  key={option.value}
                  inline
                  label={option.label}
                  type="radio"
                  id={`sort${option.value}`}
                  name={`sortingNames_${option.value}`}
                  value={option.value}
                  checked={orderByName === option.value}
                  onChange={() => handleNameOrderChange(option.value)}
                  disabled={orderByPrices || orderByYear}
                />
              ))}
            </Container>
          </Col>
          <Col xs={12} lg={6}>
            <Container className="mb-2 mt-2">
              <p className="tc-ref-text ">Filtro de Año</p>
              <Select
                styles={customSelectStyles}
                isMulti
                value={selectedYears}
                onChange={handleYearsToggle}
                options={years}
                placeholder="Seleccione"
              />
            </Container>
            <Container className="mb-2 mt-2">
              <p className="tc-ref-text mb-2">Ordenar por años</p>
              {sortingOptions.map((option) => (
                <Form.Check
                  key={option.value}
                  inline
                  label={option.label}
                  type="radio"
                  id={`sort${option.value}`}
                  name={`sortingYears_${option.value}`}
                  value={option.value}
                  checked={orderByYear === option.value}
                  onChange={() => handleYearOrderChange(option.value)}
                  disabled={orderByPrices || orderByName}
                />
              ))}
            </Container>
            <Button
              variant="outline-primary"
              onClick={clearFilters}
              className="float-end w-100 mt-2"
            >
              Limpiar filtros
            </Button>
          </Col>
        </Row>
      </Dropdown.Menu>
    </Dropdown>
  );
}
