import React, { useEffect, useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { FaCheck } from "react-icons/fa";
import { updateUser } from '../../services/usersConfigServices';
import toast from 'react-hot-toast';
import { colors } from './../../../../styles/colors';

export default function TeamModalComponent({ fetchDataUsers, show, onHide, teamsColors, teams, selectedUser }) {
  const [selectedTeam, setSelectedTeam] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);


  useEffect(() => {
    if (selectedUser && selectedUser.team) {
      setSelectedTeam(selectedUser.team);
    } else {
      setSelectedTeam('');
    }
  }, [selectedUser]);

  const handleTeamClick = (team) => {
    setSelectedTeam(team);
  };

  const handleSaveChanges = async () => {
    setIsSubmitting(true);
    try {
      const response = await updateUser({
        user_id: selectedUser?.user_id,
        name: selectedUser?.name,
        team: selectedTeam,
        company: 'san_jorge',
      });
      toast.success("Team asignado al usuario con exito.", {
        style: {
          color: colors.text.primary,
          background: colors.neutral.green,
        },
      });
      setIsSubmitting(false);
      onHide();
      return fetchDataUsers();
    } catch (error) {
      setIsSubmitting(false);
      toast.error('Error al guardar los datos. ' + error, {
        style: {
          color: colors.text.primary,
          background: colors.error.dark,
        },
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <div className='p-4'>
        <p className='add-card-title-modal mb-2 text-center'>Asignar Rol</p>
        <div className="d-flex flex-wrap mb-4">
          {[teams.slice(0, 3), teams.slice(3, 6)].map((teamGroup, index) => (
            <div key={index} className="d-flex flex-grow-1 mb-2">
              {teamGroup.map((team) => (
                <Button
                  key={team.value}
                  onClick={() => handleTeamClick(team.value)} // Envía el valor (value) al manejar el clic
                  className="flex-grow-1 me-1 ms-1"
                  style={{
                    width: 'calc(33.33% - 8px)',
                    backgroundColor: `var(${teamsColors[team.value]})`,
                    borderColor: `var(${teamsColors[team.value]})`
                  }}
                >
                  {team.label}
                  {selectedTeam === team.value && <FaCheck className="ms-1" />}
                </Button>
              ))}
            </div>
          ))}
        </div>
        <Button variant="primary" onClick={handleSaveChanges} size='lg' style={{ width: '100%', fontSize: '16px' }}>
          {isSubmitting ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : (
            'Guardar cambios'
          )}
        </Button>
      </div>
    </Modal>
  );
}
