import React from "react";
import { Row } from "react-bootstrap";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { HiOutlineDownload } from "react-icons/hi";
import { uploadFile } from "../firebase/config";
import { generateId } from "../utils/IdGenerator";
import EmptyStateComponent from "./EmptyStateComponent";
import { colors } from "../styles/colors";
import PhotoCarouselComponent from "../modules/Deal/components/inspection/PhotoCarouselComponent";

const DragAndDropComponent = ({ dataFiles, setDataFiles, disabled }) => {
  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const droppedFiles = [...e.dataTransfer.files];
    Array.from(droppedFiles).forEach(async (file) => {
      const result = await uploadFile(file);
      setDataFiles((state) => [
        ...state,
        {
          name: file.name,
          size: file.size,
          urlFirestore: result,
          url: URL.createObjectURL(file),
          id: generateId(),
          type: file.type,
        },
      ]);
    });
  };

  const sendFiles = async (e) => {
    const selectedFiles = [...e.target.files];
    Array.from(selectedFiles).forEach(async (file) => {
      const result = await uploadFile(file);
      setDataFiles((state) => [
        ...state,
        {
          name: file.name,
          size: file.size,
          urlFirestore: result,
          url: URL.createObjectURL(file),
          id: generateId(),
          type: file.type,
        },
      ]);
    });
  };

  const handledRemoveFiles = (data) => {
    setDataFiles((state) => state.filter((item) => item.url !== data.url));
  };

  const textStyle = {
    color: colors.primary.secondaryTwo,
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "0px",
    marginTop: "12px",
  };

  return (
    <>
      {!disabled ? (
        <>
          <p style={textStyle}>Archivos Adjuntos</p>
          <Row>
            <div
              className="mt-2 drag-and-drop-zone d-flex align-items-center justify-content-center"
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <p style={{ margin: "0px" }}>
                Arrastra archivos aquí o{" "}
                <label
                  htmlFor="upload-img"
                  className="attch-input"
                  style={{ marginTop: "0px" }}
                >
                  <span className="attach-icon-text">
                    selecciona el archivo desde tu computadora
                  </span>
                </label>
                <input
                  type="file"
                  id="upload-img"
                  multiple={true}
                  onChange={sendFiles}
                  style={{ display: "none" }}
                />{" "}
              </p>
            </div>
          </Row>
          {dataFiles.length === 0 ? (
            <EmptyStateComponent />
          ) : (
            <Row>
              <div className="d-flex flex-wrap align-items-start flex-column">
                {dataFiles?.map((file, i) => (
                  <div key={i} className="position-relative m-2">
                    {file.type === "video/mp4" ? (
                      <div className="grid-video">
                        <video
                          className="videos"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                            borderRadius: "6px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            window.open(file.urlFirestore, "_blank")
                          }
                        >
                          <source src={file.urlFirestore} type="video/mp4" />
                        </video>
                      </div>
                    ) : file.type === "application/pdf" ||
                      file.type ===
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                      file.type ===
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <p
                          className="attach-icon-text"
                          style={{ marginBottom: "0px" }}
                        >
                          {file.name}
                        </p>
                        <RiDeleteBin6Fill
                          className="delete-attach-icon ms-2"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => handledRemoveFiles(file)}
                        />
                      </div>
                    ) : (
                      <div className="d-flex align-items-end justify-content-center">
                        <img
                          src={file.urlFirestore}
                          alt={"file"}
                          onClick={() =>
                            window.open(file.urlFirestore, "_blank")
                          }
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                            borderRadius: "6px",
                            cursor: "pointer",
                          }}
                        />
                        <RiDeleteBin6Fill
                          className="delete-attach-icon"
                          onClick={() => handledRemoveFiles(file)}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </Row>
          )}
        </>
      ) : (
        <>
          <p style={textStyle}>Archivos Adjuntos</p>
          {dataFiles?.length > 0 ? (
            <>
              {dataFiles
                .filter(
                  (file) =>
                    file.type === "application/pdf" ||
                    file.type ===
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                    file.type ===
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                )
                .map((file, i) => (
                  <div
                    key={i}
                    className="d-flex align-items-center justify-content-start"
                  >
                    <p
                      className="attach-icon-text"
                      style={{ marginBottom: "0px" }}
                    >
                      {file.name}
                    </p>
                    <a
                      href={`${file.urlFirestore}`}
                      download={file.name}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <HiOutlineDownload
                        className="ms-2 me-2"
                        fontSize="22px"
                      />
                    </a>
                  </div>
                ))}
              <Row>
                <PhotoCarouselComponent
                  filesInspect={dataFiles.filter((file) =>
                    file.type.startsWith("image/")
                  )}
                />
              </Row>
            </>
          ) : (
            <EmptyStateComponent />
          )}
        </>
      )}
    </>
  );
};
export default DragAndDropComponent;
