// ModalVehicle.js
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import VehicleManual from "./VehicleManual";
import { useForm } from "react-hook-form";
import VehicleFunctional from "./VehicleFunctional";
import {
  saveDataCarBasics,
  saveDataKmsGnc,
} from "../../../services/servicesBaseInfo";
import { toast } from "react-hot-toast";
import { colors } from "../../../../../styles/colors";
import InputComponent from "../../../../../components/InputComponent";

const ModalVehicle = ({ modal, setModal, dataVehiculo, user, deal_id }) => {
  const { register, setValue, handleSubmit } = useForm();
  const [dataYear, setdataYear] = useState("");
  const [dataBrand, setdataBrand] = useState("");
  const [dataBrandId, setdataBrandId] = useState("");
  const [dataModel, setdataModel] = useState("");
  const [dataModelId, setdataModelId] = useState("");
  const [dataVersion, setdataVersion] = useState("");
  const [dataVersionId, setdataVersionId] = useState("");
  const [brands, setbrands] = useState([]);
  const [models, setmodels] = useState([]);
  const [versions, setversions] = useState([]);
  const [valueTypeCar, setvalueTypeCar] = useState("Info auto");

  useEffect(() => {
    setValue("gnc", dataVehiculo?.gnc);
    setValue("kms", dataVehiculo?.kms);
  }, [dataVehiculo, setValue]);

  const handleClose = () => {
    setModal(false);
  };

  const handleInputChange = (e) => {
    setvalueTypeCar(e.target.value);
  };

  const sendDataVehicleBasics = (data) => {
    const array_data = {
      brand: valueTypeCar === "Manual" ? data.brand : dataBrand,
      brand_id: valueTypeCar === "Manual" ? "" : dataBrandId,
      model: valueTypeCar === "Manual" ? data.model : dataModel,
      model_id: valueTypeCar === "Manual" ? "" : dataModelId,
      version: valueTypeCar === "Manual" ? data.version : dataVersion,
      version_id: valueTypeCar === "Manual" ? "" : dataVersionId,
      year: valueTypeCar === "Manual" ? data.year : dataYear,
      type: valueTypeCar,
      gnc: data.gnc,
      kms: data.kms,
    };
    saveDataCarBasics(array_data, user?.company, deal_id, user);
    toast.success("Información guardada con éxito.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setModal(false);
  };

  const sendEditGncKms = (data) => {
    const array_data = {
      gnc: data.gnc,
      kms: data.kms,
    };

    saveDataKmsGnc(array_data, user?.company, deal_id, user);
    toast.success("Información guardada con éxito.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setModal(false);
  };

  return (
    <Modal
      show={modal}
      centered
      onHide={handleClose}
      data-testid="modal-vehicle"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="add-card-title-modal">Editar auto</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Tabs
            defaultActiveKey="1"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="1" title="Vehiculo">
              <form
                onSubmit={handleSubmit(sendDataVehicleBasics)}
                data-testid="vehiculo-form"
              >
                <p className="deal-subtitle" style={{ marginTop: "0px" }}>
                  Tipo
                </p>
                <Form.Group >
                  <Form.Label htmlFor="tipoVehiculo" className="label-input">
                    Tipo de Vehiculo
                  </Form.Label>
                  <Form.Select
                    className="deal-select"
                    onChange={(e) => handleInputChange(e)}
                    data-testid="tipoVehiculo-select"
                  >
                    <option>Info auto</option>
                    <option>Manual</option>
                  </Form.Select>
                </Form.Group>
                <div className="divider mt-3 mb-1" />
                <p className="deal-subtitle">Vehiculo</p>
                {valueTypeCar === "Manual" ? (
                  <VehicleManual
                    register={register}
                    setValue={setValue}
                    dataVehiculo={dataVehiculo}
                  />
                ) : (
                  <VehicleFunctional
                    register={register}
                    disabled={false}
                    dataVehiculo={dataVehiculo}
                    setdataYear={setdataYear}
                    setdataBrand={setdataBrand}
                    setdataBrandId={setdataBrandId}
                    setdataModel={setdataModel}
                    setdataModelId={setdataModelId}
                    setdataVersion={setdataVersion}
                    setdataVersionId={setdataVersionId}
                    brands={brands}
                    models={models}
                    versions={versions}
                    setbrands={setbrands}
                    setmodels={setmodels}
                    setversions={setversions}
                    setValue={setValue}
                  />
                )}
                <Button
                  variant="primary"
                  type="submit"
                  style={{ width: "100%" }}
                  className="mt-2"
                  data-testid="submit-vehicle-basics"
                >
                  Enviar
                </Button>
              </form>
            </Tab>
            <Tab eventKey="2" title="Gnc - Kms">
              <form
                onSubmit={handleSubmit(sendEditGncKms)}
                data-testid="gnc-kms-form"
              >
                <Row>
                  <Col lg={6}>
                    <Form.Group >
                      <Form.Label htmlFor="gnc-select" className="deal-select-title">
                        Gnc
                      </Form.Label>
                      <Form.Select
                        className="deal-select"
                        {...register("gnc")}
                        data-testid="gnc-select"
                      >
                        <option value={true}>Si</option>
                        <option value={false}>No</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group >
                      <Form.Label htmlFor="kms-input" className="deal-select-title">
                        Kilometros
                      </Form.Label>
                      <InputComponent
                        id="kms-input"
                        label={"Kilometros"}
                        placeholder={"Ingresar kms"}
                        register={register}
                        valueRegister={"kms"}
                        data-testid="kms-input"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  variant="primary"
                  type="submit"
                  style={{ width: "100%" }}
                  className="mt-2"
                  data-testid="submit-gnc-kms"
                >
                  Enviar
                </Button>
              </form>
            </Tab>
          </Tabs>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ModalVehicle;
