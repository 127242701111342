import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase/config";

Object.filter = function (mainObject, filterFunction) {
  return Object.keys(mainObject)
    .filter(function (ObjectKey) {
      return filterFunction(mainObject[ObjectKey]);
    })
    .reduce(function (result, ObjectKey) {
      result[ObjectKey] = mainObject[ObjectKey];
      return result;
    }, {});
};

export const saveUserData = async (data, company, deal_id, user) => {
  const user_array = {
    email: data.email,
    name: data.name,
    phone: data.phone,
    user_update_data: user,
  };

  const basics_array = {
    "source.source": data.source,
    "source.medium": data.medium,
    patente: data.patente,
    user_update_data: user,
    "car.kms": data.kms,
    paracaidas:
      data.paracaidas === "true"
        ? true
        : data.paracaidas === "false"
        ? false
        : undefined,
    permute: data.permute,
    direction: data.direction,
    barrio_partido: data.barrio_partido,
    bonus: data.bond,
    amount_bonus: data.amount_bond,
    prendado: data.prendado,
  };

  const user_data = Object.filter(user_array, function (info) {
    return info !== undefined;
  });

  const basics = Object.filter(basics_array, function (info) {
    return info !== undefined;
  });

  try {
    const basicsRef = doc(
      db,
      "companies",
      company,
      "deals",
      deal_id,
      "data",
      "basics"
    );
    await updateDoc(basicsRef, basics);

    const userDataRef = doc(
      db,
      "companies",
      company,
      "deals",
      deal_id,
      "data",
      "user_data"
    );
    await updateDoc(userDataRef, user_data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateFieldPermute = async (data, deal_id, user) => {
  try {
    const takeDocRef = doc(db, "companies", user?.company, "deals", deal_id);
    await updateDoc(takeDocRef, { permute: data });
  } catch (error) {
    console.error("Error actualizando la permuta: ", error);
    return error;
  }
};

export const saveDataCarBasics = async (data, company, deal_id, user) => {
  const basics_array = {
    "car.brand": data.brand,
    "car.model": data.model,
    "car.version": data.version,
    "car.year": data.year,
    "car.brand_id": data.brand_id,
    "car.model_id": data.model_id,
    "car.version_id": data.version_id,
    "car.type": data.type,
    "car.gnc": data.gnc,
    "car.kms": data.kms,
    user_update_data: user,
  };

  try {
    const basicsRef = doc(
      db,
      "companies",
      company,
      "deals",
      deal_id,
      "data",
      "basics"
    );
    await updateDoc(basicsRef, basics_array);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const saveDataKmsGnc = async (data, company, deal_id, user) => {
  const basics_array = {
    "car.gnc": data.gnc,
    "car.kms": data.kms,
    user_update_data: user,
  };

  try {
    const basicsRef = doc(
      db,
      "companies",
      company,
      "deals",
      deal_id,
      "data",
      "basics"
    );
    await updateDoc(basicsRef, basics_array);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const barriosCABA = [
  "Agronomía",
  "Almagro",
  "Balvanera",
  "Barracas",
  "Belgrano",
  "Boedo",
  "Caballito",
  "Chacarita",
  "Coghlan",
  "Colegiales",
  "Constitución",
  "Flores",
  "Floresta",
  "La Boca",
  "La Paternal",
  "Liniers",
  "Mataderos",
  "Monte Castro",
  "Montserrat",
  "Nueva Pompeya",
  "Núñez",
  "Palermo",
  "Parque Avellaneda",
  "Parque Chacabuco",
  "Parque Chas",
  "Parque Patricios",
  "Puerto Madero",
  "Recoleta",
  "Retiro",
  "Saavedra",
  "San Cristóbal",
  "San Nicolás",
  "San Telmo",
  "Vélez Sarsfield",
  "Versalles",
  "Villa Crespo",
  "Villa del Parque",
  "Villa Devoto",
  "Villa Gral. Mitre",
  "Villa Lugano",
  "Villa Luro",
  "Villa Ortúzar",
  "Villa Pueyrredón",
  "Villa Real",
  "Villa Riachuelo",
  "Villa Santa Rita",
  "Villa Soldati",
  "Villa Urquiza",
];

export const partidosBuenosAires = [
  "Adolfo Alsina",
  "Adolfo Gonzales Chaves",
  "Alberti",
  "Almirante Brown",
  "Arrecifes",
  "Avellaneda",
  "Ayacucho",
  "Azul",
  "Bahía Blanca",
  "Balcarce",
  "Baradero",
  "Benito Juárez",
  "Berazategui",
  "Berisso",
  "Bolívar",
  "Bragado",
  "Brandsen",
  "Campana",
  "Cañuelas",
  "Capitán Sarmiento",
  "Carlos Casares",
  "Carlos Tejedor",
  "Carmen de Areco",
  "Castelli",
  "Chacabuco",
  "Chascomús",
  "Chivilcoy",
  "Colón",
  "Coronel Dorrego",
  "Coronel Pringles",
  "Coronel Rosales",
  "Coronel Suárez",
  "Daireaux",
  "Dolores",
  "Ensenada",
  "Escobar",
  "Esteban Echeverría",
  "Exaltación de la Cruz",
  "Ezeiza",
  "Florencio Varela",
  "Florentino Ameghino",
  "General Alvarado",
  "General Alvear",
  "General Arenales",
  "General Belgrano",
  "General Guido",
  "General Juan Madariaga",
  "General La Madrid",
  "General Las Heras",
  "General Lavalle",
  "General Paz",
  "General Pinto",
  "General Pueyrredón",
  "General Rodríguez",
  "General San Martín",
  "General Viamonte",
  "General Villegas",
  "Guaminí",
  "Hipólito Yrigoyen",
  "Hurlingham",
  "Ituzaingó",
  "José C. Paz",
  "Junín",
  "La Costa",
  "La Matanza",
  "La Plata",
  "Lanús",
  "Laprida",
  "Las Flores",
  "Leandro N. Alem",
  "Lincoln",
  "Lobería",
  "Lobos",
  "Lomas de Zamora",
  "Luján",
  "Magdalena",
  "Maipú",
  "Malvinas Argentinas",
  "Mar Chiquita",
  "Marcos Paz",
  "Mercedes",
  "Merlo",
  "Monte",
  "Moreno",
  "Morón",
  "Navarro",
  "Necochea",
  "Nueve de Julio",
  "Olavarría",
  "Patagones",
  "Pehuajó",
  "Pellegrini",
  "Pergamino",
  "Pila",
  "Pilar",
  "Pinamar",
  "Presidente Perón",
  "Puan",
  "Punta Indio",
  "Quilmes",
  "Ramallo",
  "Rauch",
  "Rivadavia",
  "Rojas",
  "Roque Pérez",
  "Saavedra",
  "Saladillo",
  "Salliqueló",
  "Salto",
  "San Andrés de Giles",
  "San Antonio de Areco",
  "San Cayetano",
  "San Fernando",
  "San Isidro",
  "San Miguel",
  "San Nicolás",
  "San Pedro",
  "San Vicente",
  "Tandil",
  "Tapalqué",
  "Tigre",
  "Tordillo",
  "Tornquist",
  "Trenque Lauquen",
  "Tres Arroyos",
  "Tres de Febrero",
  "Tres Lomas",
  "Vicente López",
  "Villarino",
  "Zárate",
];

export const provinciasArgentina = [
  "Catamarca",
  "Chaco",
  "Chubut",
  "Córdoba",
  "Corrientes",
  "Entre Ríos",
  "Formosa",
  "Jujuy",
  "La Pampa",
  "La Rioja",
  "Mendoza",
  "Misiones",
  "Neuquén",
  "Río Negro",
  "Salta",
  "San Juan",
  "San Luis",
  "Santa Cruz",
  "Santa Fe",
  "Santiago del Estero",
  "Tierra del Fuego",
  "Tucumán",
];
