import React, { useCallback, useEffect, useState } from "react";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import InputComponent from "./../../../../components/InputComponent";
import { useUser } from "../../../../context/UserContext";
import { useForm } from "react-hook-form";
import { colors } from "./../../../../styles/colors";
import { toast } from "react-hot-toast";
import {
  barriosCABA,
  partidosBuenosAires,
  provinciasArgentina,
  saveUserData,
  updateFieldPermute,
} from "./../../services/servicesBaseInfo";
import VehicleView from "./Vehicle/VehicleView";
import ModalVehicle from "./Vehicle/ModalVehicle";
import shortSnapshotFirebase from "../../../../utils/ShortSnapshotFirebase";
import { formatNumber } from "../../../Pricing/services/servicesPricing";

export default function BasicInfoComponent({
  disabled,
  deal_id,
  basicInfo,
  userData,
  toggleEditing,
  companyData,
  setModal,
  modal,
}) {
  const { user } = useUser();
  const { register, handleSubmit, setValue } = useForm();
  const [dataSource, setDataSource] = useState("");
  const [dataConsignment, setDataConsignment] = useState();
  const [valueDirection, setValueDirection] = useState("");
  const [valueBond, setValueBond] = useState("");
  const [valuePrendado, setValuePrendado] = useState("");
  const [amountBond, setAmountBond] = useState("");

  useEffect(() => {
    const callbackDeals = (data) => {
      setDataSource(data?.source);
      setDataConsignment(data?.consignment);
    };
    const unsubscribeDeals = shortSnapshotFirebase(
      user?.company,
      "deals",
      deal_id,
      callbackDeals
    );
    return () => {
      unsubscribeDeals();
    };
  }, [deal_id, user?.company]);

  const updateDealBasic = useCallback(
    (data) => {
      const values = {
        email: data.email,
        name: data.nombre,
        phone: data.telefono,
        patente: data.patente,
        kms: data.kilometraje,
        source: data.source,
        medium: data.medium,
        paracaidas: data.paracaidas,
        direction: data.direction,
        barrio_partido: data.barrio_partido,
        bond: data.bond,
        prendado: data.prendado,
        amount_bond: data.amount_bond,
        permute: data.permute === "true",
      };
      try {
        saveUserData(values, user?.company, deal_id, user);
        updateFieldPermute(values.permute, deal_id, user);
        toast.success("Informacion Guardada con exito.", {
          style: {
            color: colors.text.primary,
            background: colors.neutral.green,
          },
        });
        toggleEditing();
      } catch (error) {
        console.log(error);
        toast.error("Error al guardar los datos.", {
          style: {
            color: colors.text.primary,
            background: colors.error.dark,
          },
        });
      }
    },
    [deal_id, toggleEditing, user]
  );

  const landingMap = {
    landing_san_jorge: "Landing San Jorge",
    san_jorge: "Landing San Jorge",
    landing_auta: "Landing Auta",
    web_auta: "Web Auta",
    auta: "Landing Auta",
  };

  const formattedValue = landingMap[dataSource] || dataSource;

  useEffect(() => {
    setValue("patente", basicInfo?.patente);
    setValue("email", userData?.email);
    setValue("telefono", userData?.phone);
    setValue("nombre", userData?.name);
    setValue("source", formattedValue);
    setValue("medium", basicInfo?.source?.medium);
    setValue("paracaidas", basicInfo?.paracaidas);
    setValue("permute", basicInfo?.permute);
    setValue("direction", basicInfo?.direction);
    setValue("bond", basicInfo?.bonus);
    setValue("prendado", basicInfo?.prendado);
    setValuePrendado(basicInfo?.prendado);
    setValueBond(basicInfo?.bonus);

    const initialAmountBond = formatNumber(basicInfo?.amount_bonus || "0");
    setValue("amount_bond", `$${initialAmountBond}`);
    setAmountBond(`$${initialAmountBond}`);

    setValueDirection(basicInfo?.direction);
    setValue("barrio_partido", basicInfo?.barrio_partido);
  }, [basicInfo, userData, formattedValue, updateDealBasic, setValue]);

  const textStyle = {
    color: colors.primary.secondaryTwo,
    fontWeight: "bold",
    fontSize: "14px",
    marginBottom: "0px",
    marginTop: "12px",
  };

  const handleInputChange = (e) => {
    setValueDirection(e.target.value);
  };

  const handleInputChangeBond = (e) => {
    setValueBond(e.target.value);
  };

  const handleInputChangePrendado = (e) => {
    setValuePrendado(e.target.value);
  };

  const handleInputChangeAmountBond = (event) => {
    let inputValue = event.target.value;

    const numericValue = inputValue.replace(/[^\d.]/g, "");

    const formattedValue = formatNumber(numericValue);

    const valueWithDollar = `$${formattedValue}`;

    setAmountBond(valueWithDollar);
  };

  return (
    <>
      <ModalVehicle
        modal={modal}
        setModal={setModal}
        dataVehiculo={basicInfo?.car}
        user={user}
        deal_id={deal_id}
      />
      <form onSubmit={handleSubmit(updateDealBasic)}>
        <div>
          <div className="d-flex align-items-center justify-content-end">
            <Button
              hidden={disabled}
              type="submit"
              style={{ marginTop: "-37px", marginRight: "160px" }}
            >
              Guardar cambios
            </Button>
          </div>
          <VehicleView
            dataVehiculo={basicInfo?.car}
            register={register}
            textStyle={textStyle}
          />
          <Row>
            {basicInfo?.car?.stopper === true ? (
              <Col lg={6}>
                <InputComponent
                  id="motivoStopper"
                  disabled={true}
                  label={"Motivo del stopper"}
                  placeholder={basicInfo?.car?.closed_desc}
                  register={register}
                />
              </Col>
            ) : null}
            <Col lg={4}>
              <InputComponent
                id="kilometraje"
                disabled={true}
                label={"Kilometraje"}
                placeholder={formatNumber(basicInfo?.car?.kms ?? "")}
                register={register}
              />
            </Col>
            <Col xs={6} lg={4}>
              <InputComponent
                id="patente"
                disabled={disabled}
                label={"Patente"}
                placeholder={"Ingresar patente"}
                register={register}
                valueRegister={"patente"}
              />
            </Col>
          </Row>
        </div>
        <div className="divider mt-1 mb-1" />
        <Row>
          <p style={textStyle} className="mb-2">
            Agendamiento
          </p>
          <Col xs={6} lg={3}>
            <div className="select-container">
              <div className="d-flex align-items-center justify-content-between">
                <label className="label-input" htmlFor="paracaidas">
                  Paracaidas
                </label>
              </div>
              <Form.Select
                id="paracaidas"
                className="deal-select"
                disabled={disabled}
                {...register("paracaidas")}
              >
                <option value={false}>No</option>
                <option value={true}>Si</option>
              </Form.Select>
            </div>
          </Col>
          <Col xs={6} lg={3}>
            <div className="select-container">
              <div className="d-flex align-items-center justify-content-between">
                <label className="label-input" htmlFor="permuta">
                  Permuta
                </label>
              </div>
              <Form.Select
                id="permuta"
                className="deal-select"
                disabled={disabled || dataConsignment ? true : false}
                {...register("permute")}
              >
                <option value={true}>Si</option>
                <option value={false}>No</option>
              </Form.Select>
            </div>
          </Col>
          <Col xs={6} lg={3}>
            <div className="select-container">
              <div className="d-flex align-items-center justify-content-between">
                <label className="label-input" htmlFor="bono">
                  Bono
                </label>
              </div>
              <Form.Select
                id="bono"
                className="deal-select"
                disabled={disabled}
                {...register("bond")}
                value={valueBond}
                onChange={handleInputChangeBond}
              >
                <option>Si</option>
                <option>No</option>
              </Form.Select>
            </div>
          </Col>
          {valueBond === "Si" ? (
            <Col xs={6} lg={3}>
              <div className="select-container">
                <div className="d-flex align-items-center justify-content-between">
                  <label className="label-input" htmlFor="bono">
                    Monto bono
                  </label>
                </div>
                <input
                  className="input-deal"
                  disabled={disabled}
                  placeholder={"$0.000.000"}
                  type="text"
                  {...register("amount_bond")}
                  value={amountBond}
                  onChange={handleInputChangeAmountBond}
                />
              </div>
            </Col>
          ) : null}
          <Col xs={6} lg={3}>
            <div className="select-container">
              <div className="d-flex align-items-center justify-content-between">
                <label className="label-input" htmlFor="prendado">
                  Prendado
                </label>
              </div>
              <Form.Select
                id="prendado"
                className="deal-select"
                disabled={disabled}
                {...register("prendado")}
                value={valuePrendado}
                onChange={handleInputChangePrendado}
              >
                <option>Si</option>
                <option>No</option>
              </Form.Select>
            </div>
          </Col>
        </Row>
        <div className="divider mt-1 mb-1" />
        <div>
          <p style={textStyle} className="mb-2">
            Usuario
          </p>
          <Row>
            <Col xs={6} lg={4}>
              <InputComponent
                id="nombre"
                disabled={disabled}
                label={"Nombre"}
                placeholder={"Nombre"}
                register={register}
                valueRegister={"nombre"}
              />
            </Col>
            <Col xs={6} lg={4}>
              <InputComponent
                id="email"
                disabled={disabled}
                label={"Email"}
                placeholder={"email@email.com"}
                register={register}
                valueRegister={"email"}
              />
            </Col>
            <Col xs={6} lg={4}>
              <InputComponent
                id="telefono"
                disabled={disabled}
                label={"Teléfono"}
                placeholder={"000 0 000 000"}
                register={register}
                valueRegister={"telefono"}
              />
            </Col>
            <Col xs={6} lg={4}>
              <div className="select-container">
                <div className="d-flex align-items-center justify-content-between">
                  <label className="label-input" htmlFor="direccion">
                    Dirección
                  </label>
                </div>
                <Form.Select
                  id="direction"
                  className="deal-select"
                  disabled={disabled}
                  {...register("direction")}
                  value={valueDirection}
                  onChange={(e) => handleInputChange(e)}
                >
                  <option value="">Seleccione una opción</option>
                  <option value="CABA">CABA</option>
                  <option value="Provincia de Bs. As.">
                    Provincia de Bs. As.
                  </option>
                  {provinciasArgentina.map((data, i) => (
                    <option value={data} key={i}>
                      {data}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
            {valueDirection === "CABA" ||
            valueDirection === "Provincia de Bs. As." ? (
              <Col xs={6} lg={4}>
                <div className="select-container">
                  <div className="d-flex align-items-center justify-content-between">
                    <label className="label-input" htmlFor="direccion">
                      {valueDirection === "CABA" ? "Barrio" : "Partido"}
                    </label>
                  </div>
                  <Form.Select
                    id="barrio_partido"
                    className="deal-select"
                    disabled={disabled}
                    {...register("barrio_partido")}
                  >
                    <option value="">Seleccione una opción</option>
                    {valueDirection === "CABA"
                      ? barriosCABA.map((barrio, index) => (
                          <option value={barrio} key={index}>
                            {barrio}
                          </option>
                        ))
                      : partidosBuenosAires.map((partido, index) => (
                          <option value={partido} key={index}>
                            {partido}
                          </option>
                        ))}
                  </Form.Select>
                </div>
              </Col>
            ) : null}
          </Row>
        </div>
        <div className="divider" />
        <div>
          <p style={textStyle} className="mb-2">
            Fuente
          </p>
          <Row>
            <Col lg={4}>
              <InputComponent
                id="source"
                disabled={true}
                label={"Fuente"}
                placeholder={"De dónde proviene el lead"}
                register={register}
                valueRegister={"source"}
              />
            </Col>
            <Col lg={4}>
              <InputComponent
                id="medium"
                disabled={disabled}
                label={"Medio"}
                placeholder={""}
                register={register}
                valueRegister={"medium"}
              />
            </Col>
          </Row>
        </div>
      </form>
    </>
  );
}
