import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputComponent from "../../../../components/InputComponent";
import { useUser } from "../../../../context/UserContext";
import { updateTitular } from "../../services/TakeServices/HolderServices";
import Swal from "sweetalert2";

export default function ModalEditFinalConsumerComponent({
  modal,
  setModal,
  deal_id,
  titularEdit,
  titularId,
  arrayConsumidorFinal,
  setHolderType,
  totalTitularidad,
}) {
  const { register, handleSubmit, reset, setValue } = useForm();
  const [valuePorcentaje, setvaluePorcentaje] = useState("");
  const { user } = useUser();

  React.useEffect(() => {
    setValue("nombre_completo", titularEdit?.nombre_completo);
    setValue("cuil_dni", titularEdit?.cuil_dni);
    setValue("estado_civil", titularEdit?.estado_civil);
    setValue("conyuge", titularEdit?.conyuge);
    setValue("domicilio_fiscal", titularEdit?.domicilio_fiscal);
    setValue("domicilio_real", titularEdit?.domicilio_real);
    setValue("titularidad", titularEdit?.titularidad);
    setValue("mail", titularEdit?.mail);
  }, [setValue, titularEdit]);

  const addTitular = (data) => {
    const nuevoPorcentaje = parseFloat(data.titularidad || 0);

    const totalSinEditar = arrayConsumidorFinal.reduce((acc, item) => {
      if (item.cuil_dni !== titularId) {
        return acc + (parseFloat(item.titularidad) || 0);
      }
      return acc;
    }, 0);

    const totalConNuevaTitularidad = totalSinEditar + nuevoPorcentaje;

    if (totalConNuevaTitularidad > 100) {
      Swal.fire({
        title: "Porcentaje excedido",
        text: `La suma de titularidades no puede superar el 100%.`,
        icon: "error",
        confirmButtonColor: "#d33",
        confirmButtonText: "Aceptar",
      });
    } else {
      const updatedTitular = { ...titularEdit, ...data };

      const updatedArray = arrayConsumidorFinal.map((item) =>
        item.cuil_dni === titularId ? updatedTitular : item
      );

      updateTitular(deal_id, updatedArray, user);
      reset();
      setModal(false);
      setHolderType("Consumidor final");
    }
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleInputChangePorcentaje = (event) => {
    const inputValueWithoutPercentage = event.target.value;

    if (
      inputValueWithoutPercentage === "" ||
      /^[1-9][0-9]?$|^100$/.test(inputValueWithoutPercentage)
    ) {
      setvaluePorcentaje(inputValueWithoutPercentage);
    }
  };

  return (
    <>
      <Modal size="lg" show={modal} onHide={handleClose}>
        <form>
          <Modal.Header closeButton>
            <Modal.Title className="deal-title">Editar titular</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
                <InputComponent
                  label={"Nombre completo"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"nombre_completo"}
                />
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"DNI/CUIL"}
                  placeholder=""
                  type="number"
                  register={register}
                  valueRegister={"cuil_dni"}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="select-container">
                  <label className="label-input">Estado civil</label>
                  <Form.Select
                    className="deal-select"
                    {...register("estado_civil")}
                  >
                    <option>Casado</option>
                    <option>Soltero</option>
                    <option>Divorciado</option>
                    <option>Viudo</option>
                  </Form.Select>
                </div>
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"Domicilio fiscal del vehículo (cédula)"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"domicilio_fiscal"}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <InputComponent
                  label={"Domicilio real del titular"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"domicilio_real"}
                />
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"Mail"}
                  placeholder=""
                  type="email"
                  register={register}
                  valueRegister={"mail"}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <label className="label-input">Titularidad (%)</label>
                <input
                  type="number"
                  className="input-deal"
                  style={{ width: "100%" }}
                  {...register("titularidad")}
                  value={valuePorcentaje}
                  onChange={handleInputChangePorcentaje}
                  max={100}
                />
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"Nombre del conyugue (opcional)"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"conyuge"}
                />
              </Col>
              <Col lg={6}></Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleSubmit(addTitular)}>
              Editar titular
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
