import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import '../styles/style/AccordionButtom.css'; // Importamos un archivo CSS para los estilos personalizados

const AccordionButton = ({ text, icon, children, useInCompany }) => {
  const [open, setOpen] = useState(!useInCompany);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div style={{ cursor: "pointer" }}>
      <div className='custom-btn'
        onClick={handleToggle}>
        <div>
          <span className="accordion-theme-icon">{icon}</span>
          <span className="text">{text}</span>
        </div>
        {open ? <MdExpandLess className="icon-accordion" /> : <MdExpandMore className="icon-accordion" />}
      </div>

      <Collapse in={open}>
        <div>
          {children}
        </div>
      </Collapse>
    </div >
  );
};

export default AccordionButton;