import React from "react";
import { Form } from "react-bootstrap";

const SelectYesNoComponent = ({
  disabled,
  titleLabel,
  register,
  nameRegister,
}) => {
  return (
    <div className="select-container">
      <div className="d-flex align-items-center justify-content-between">
        <label className="label-input">{titleLabel}</label>
      </div>
      <Form.Select
        aria-label={titleLabel}
        className="deal-select"
        disabled={disabled}
        {...register(`${nameRegister}`)}
      >
        <option value="" disabled hidden>
          Seleccione una opción
        </option>
        <option>Si</option>
        <option>No</option>
      </Form.Select>
    </div>
  );
};

export default SelectYesNoComponent;
