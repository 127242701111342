export const views = {
  TASKS: "",
  CHECKVIEW: "checkView",
  TAKEVIEW: "takeView",
  INCOMINGLEADSVIEW: "incomingLeadsView",
  PENDINGREPORTSVIEW: "pendingReportsView",
};

export const getNamesViews = (view) => {
  let name = "";
  switch (view) {
    case "incomingLeadsView":
      name = "Nuevos";
      break;
    case "pendingReportsView":
      name = "Informes pendientes";
      break;
    case "acquisitionsView":
      name = "Acquisitions";
      break;
    case "checkView":
      name = "Inspecciones";
      break;
    default:
      name = "Tareas";
  }

  return name;
};

export const getRoutesNames = (routes) => {
  let name = "";
  switch (routes) {
    case "incomingLeadsView":
      name = "incoming-leads";
      break;
    case "pendingReportsView":
      name = "pending-reports";
      break;
    case "acquisitionsView":
      name = "Acquisitions";
      break;
    case "checkView":
      name = "inspections";
      break;
    default:
      name = "tasks";
  }
  return name;
};