import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import { MdAdd, MdEdit, MdFileUpload, MdRemoveRedEye } from "react-icons/md";
import { HiOutlineDownload } from "react-icons/hi";
import { RiDeleteBin6Fill } from "react-icons/ri";
import ModalAddOrEditAccountComponent from "./ModalAddOrEditAccountComponent";
import ModalPaymentScheduleComponent from "./ModalPaymentScheduleComponent";
import "../../../../styles/style/tableStyle.css";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { useUser } from "../../../../context/UserContext";
import ModalPaymentFileComponent from "./ModalPaymentFileComponent";
import {
  deleteAccount,
  deletePayments,
  editPayments,
} from "../../services/TakeServices/PaymentServices";
import { colors } from "../../../../styles/colors";
import IconAlert from "../../images/icon-alert.svg";
import { formatDecimalNumberTwo } from "../../../Pricing/services/servicesPricing";

export default function PaymentTabComponent({
  disabled,
  takeData,
  textStyle,
  titulares_array,
}) {
  const [addOrEditAccount, setAddOrEditAccount] = React.useState(false);
  const [addOrEditPayment, setAddOrEditPayment] = useState(false);
  const [paymentFileModal, setPaymentFileModal] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(null);
  const [dataEditAccount, setDataEditAccount] = React.useState(null);
  const [dataEditPayment, setDataEditPayment] = React.useState(null);
  const [isAdd, setIsAdd] = React.useState(false);

  const { user } = useUser();
  const params = useParams();

  const styledisabled = {
    backgroundColor: colors.gris[100],
  };
  const cellStyle = {
    color: colors.neutral.lightTwo,
  };

  const [totalTitularidad, setTotalTitularidad] = useState(0);

  React.useEffect(() => {
    const total = takeData?.payment?.payments.reduce(
      (acc, item) => acc + (parseFloat(item.porc) || 0),
      0
    );
    setTotalTitularidad(total);
  }, [takeData?.payment?.payments]);

  const totalAmountPaid = takeData?.payment?.payments.reduce((acc, payment) => {
    const montoStr = payment?.monto?.toString().trim().replace("$", "");
    if (!montoStr) {
      return acc;
    }
    const monto = parseFloat(montoStr.replace(/\./g, "").replace(",", "."));
    if (isNaN(monto)) {
      return acc;
    }
    return acc + monto;
  }, 0);

  const totalAmountToPay = (() => {
    const totalStr = takeData?.amount_to_pay?.total
      ?.toString()
      .trim()
      .replace("$", "");
    if (!totalStr) {
      return 0;
    }
    const total = parseFloat(totalStr.replace(/\./g, "").replace(",", "."));
    if (isNaN(total)) {
      return 0;
    }
    return total;
  })();

  function deleteAccountModal(data) {
    Swal.fire({
      title: "Borrar cuenta",
      text: "¿Estas seguro de que quieres eliminarla?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then((resultado) => {
      if (resultado.value) {
        deleteAccount(
          params.deal_id,
          data.id,
          data.cbu,
          data.bank,
          data.account_holder,
          data.CUIT,
          user
        );
      } else {
        console.log("*NO se elimina la venta*");
      }
    });
  }

  function deletePaymentModal(data) {
    Swal.fire({
      title: "Borrar Pago",
      text: "¿Estas seguro de que quieres eliminar el pago?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then((resultado) => {
      if (resultado.value) {
        deletePayments(params.deal_id, data, user);
      } else {
        console.log("*NO se elimina el pago*");
      }
    });
  }

  const fileAction = (data) => {
    if (data.file) {
      Swal.fire({
        title: "Borrar Pago",
        text: "¿Estas seguro de que quieres eliminar el Comprobante?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((resultado) => {
        if (resultado.value) {
          data.file = null;
          const indexPayment = takeData?.payment?.payments.findIndex(
            (item) => item.id === data.id
          );
          takeData.payment.payments[indexPayment] = data;
          editPayments(params.deal_id, takeData.payment.payments, user);
        } else {
          console.log("*NO se elimina el comprobante*");
        }
      });
    } else {
      setPaymentFileModal(true);
      setDataEditPayment(data);
    }
  };

  const editAccount = (data) => {
    setDataEditAccount(data);
    setAddOrEditAccount(true);
    setIsEditing(true);
  };

  const editPayment = (data) => {
    setDataEditPayment(data);
    setAddOrEditPayment(true);
    setIsEditing(true);
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <p style={textStyle}>Cuentas</p>
        {
          <Button
            size="sm"
            hidden={disabled}
            data-testid="add-account-button"
            onClick={() => {
              setAddOrEditAccount(true);
              setIsEditing(false);
            }}
          >
            <MdAdd
              style={{
                fontSize: "18px",
              }}
            />
          </Button>
        }
      </div>
      <Table
        bordered
        className="discount-table"
        style={disabled ? styledisabled : {}}
      >
        <thead>
          <tr
            style={{
              borderBottom: disabled ? "" : "2px solid #864BFA",
            }}
          >
            <th className="table-title">CBU</th>
            <th className="table-title">Banco</th>
            <th className="table-title">Titular de la cuenta</th>
            <th className="table-title d-flex align-items-center justify-content-between">
              CUIT/CUIL
            </th>
          </tr>
        </thead>
        <tbody>
          {takeData?.payment?.bank_accounts.map((data, i) => (
            <tr key={i}>
              <td style={cellStyle} className="table-body">
                {data.cbu}
              </td>
              <td style={cellStyle} className="table-body">
                {data.bank}
              </td>
              <td style={cellStyle} className="table-body">
                {data.account_holder}
              </td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="table-body"
              >
                {data.CUIT}
                {!disabled ? (
                  <div>
                    <MdEdit
                      onClick={() => {
                        editAccount(data);
                      }}
                      style={{
                        fontSize: "24px",
                        color: colors.primary.primary,
                        cursor: "pointer",
                      }}
                      data-testid="edit-account-icon"
                    />
                    <RiDeleteBin6Fill
                      style={{
                        fontSize: "24px",
                        color: colors.status.red,
                        cursor: "pointer",
                      }}
                      onClick={() => deleteAccountModal(data)}
                      data-testid="delete-account-icon"
                    />
                  </div>
                ) : (
                  ""
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="divider" style={{ marginTop: "16px" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <p style={textStyle}>Cronograma de pago</p>
        <Button
          size="sm"
          hidden={disabled}
          disabled={totalAmountPaid >= totalAmountToPay}
          onClick={() => {
            setIsAdd(!isAdd);
            setAddOrEditPayment(true);
            setIsEditing(false);
          }}
        >
          <MdAdd
            style={{
              fontSize: "18px",
            }}
          />
        </Button>
      </div>
      <Table
        bordered
        responsive
        className="discount-table"
        style={disabled ? styledisabled : {}}
      >
        <thead>
          <tr
            className="table-header"
            style={{
              borderBottom: disabled ? "" : "2px solid #864BFA",
            }}
          >
            <th className="table-title">Títular</th>
            <th className="table-title">Tipo de operación</th>
            <th className="table-title">Monto</th>
            <th className="table-title">%</th>
            <th className="table-title">Moneda</th>
            <th className="table-title">Fecha a pagar</th>
            <th className="table-title">Fecha de pago</th>
            <th className="table-title">Estado</th>
            {!disabled ? <th className="table-title">Acciones</th> : null}
            {!disabled ? <th className="table-title">Comprobante</th> : null}
          </tr>
        </thead>
        <tbody>
          {takeData?.payment?.payments.map((item, i) => {
            return (
              <tr key={i}>
                <td className="table-body">{item.titular}</td>
                <td className="table-body">{item.tipoOperacion}</td>
                <td className="table-body">
                  {formatDecimalNumberTwo(item.monto)}
                </td>
                <td className="table-body">
                  {item.porc ? item.porc + "%" : null}
                </td>
                <td className="table-body">{item.moneda}</td>
                <td className="table-body">{item.fechaAPagar}</td>
                <td className="table-body">{item.fechaPago}</td>
                <td className="table-body">{item.estado}</td>
                {!disabled ? (
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                    className="table-body"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <MdEdit
                        onClick={() => {
                          editPayment(item);
                        }}
                        style={{
                          fontSize: "24px",
                          color: colors.status.blue,
                          cursor: "pointer",
                        }}
                        data-testid="edit-payment-icon"
                      />
                      <RiDeleteBin6Fill
                        style={{
                          fontSize: "24px",
                          color: colors.status.red,
                          cursor: "pointer",
                        }}
                        data-testid="delete-payment-icon"
                        onClick={() => deletePaymentModal(item)}
                      />
                    </div>
                  </td>
                ) : null}
                {!disabled ? (
                  <td>
                    {!item.file ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <span>CARGAR</span>
                        <MdFileUpload
                          onClick={() => {
                            fileAction(item);
                          }}
                          style={{
                            fontSize: "24px",
                            color: colors.status.blue,
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ) : (
                      <div className="d-flex align-items-center justify-content-between">
                        {item?.file[0]?.url ? (
                          <a
                            href={`${item?.file[0]?.url}`}
                            download={item?.file[0]?.name}
                            rel="noreferrer"
                          >
                            <HiOutlineDownload fontSize="22px" />
                          </a>
                        ) : null}
                        {item?.file[0]?.urlFirestore ? (
                          <a
                            href={item?.file[0]?.urlFirestore}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <MdRemoveRedEye fontSize={24} />
                          </a>
                        ) : null}
                        <RiDeleteBin6Fill
                          style={{
                            fontSize: "24px",
                            color: colors.status.red,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            fileAction(item);
                          }}
                        />
                      </div>
                    )}
                  </td>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {totalTitularidad < 100 && totalTitularidad > 0 && (
        <div className="d-flex align-items-center justify-content-start">
          <img src={IconAlert} alt="icon-alert" className="me-1" />
          <p
            style={{
              color: colors.status.red,
              fontWeight: 600,
              margin: "0px",
            }}
          >
            El cronograma no está cargado al 100%. Por favor, verifique.
          </p>
        </div>
      )}
      <ModalAddOrEditAccountComponent
        modal={addOrEditAccount}
        setModal={setAddOrEditAccount}
        isEditing={isEditing}
        dataEditAccount={dataEditAccount}
        bank_accounts={takeData?.payment?.bank_accounts}
        titulares={
          takeData?.payment?.titulares?.tipo_inscripcion === "Persona juridica"
            ? takeData?.payment?.titulares?.persona_juridica.apoderados
            : takeData?.payment?.titulares?.personas_fisicas
        }
        titulares_array={titulares_array}
      />
      <ModalPaymentFileComponent
        modal={paymentFileModal}
        setModal={setPaymentFileModal}
        dataEditPayment={dataEditPayment}
        payments={takeData?.payment?.payments}
      />
      <ModalPaymentScheduleComponent
        modal={addOrEditPayment}
        setModal={setAddOrEditPayment}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        dataEditPayment={dataEditPayment}
        payments={takeData?.payment?.payments}
        titulares={takeData?.payment?.bank_accounts}
        money_reference={takeData?.amount_to_pay?.money}
        amount_to_pay_reference={
          takeData?.amount_to_pay?.money === "ARS"
            ? takeData?.amount_to_pay?.total
            : takeData?.amount_to_pay?.total_usd
        }
        isAdd={isAdd}
        allPayments={takeData?.payment?.payments}
        totalTitularidad={totalTitularidad}
      />
    </div>
  );
}
