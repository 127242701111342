import React, { useState, useEffect } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import AssignmentsTableComponent from "../components/usersComponents/AssignmentsTableComponent";
import TrengoComponent from "../components/usersComponents/TrengoComponent";
import FilterUsersComponent from "../components/usersComponents/FilterUsersComponent";
import { getUsers } from "../services/usersConfigServices";
import TeamsTableComponent from "../components/usersComponents/TeamsTableComponent";
import { useUser } from "../../../context/UserContext";
import { SpinnerFullScreenComponent, ServerErrorComponent } from "../../../components";

export default function UsersViewPage() {
  const [activeView, setActiveView] = useState("teams");
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useUser();

  const teams = [
    { value: "Admin", label: "Admin" },
    { value: "AS", label: "Advisor AS" },
    { value: "CS", label: "Advisor CS" },
    { value: "Pricing", label: "Pricer" },
    { value: "BackOffice", label: "Back office" },
    { value: "Inspeccion", label: "Inspector" },
  ];

  const fetchDataUsers = async () => {
    try {
      const usersResponse = await getUsers({ params: { company: user?.company } });
      setUsersData(usersResponse.users);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const handleButtonClick = (view) => {
    setActiveView(view);
  };

  useEffect(() => {
    fetchDataUsers();
  }, []);

  if (loading) {
    return <SpinnerFullScreenComponent />;
  }

  if (error) {
    return <ServerErrorComponent error={error} />;
  }

  return (
    <div className="p-3 h-100 ">
      <div className=" w-100 d-flex justify-content-between">
        <ButtonGroup className="button-group-container" style={{ height: "48px" }}>
          {user?.team === "Admin" ? (
            <Button
              className="navegation-button-group"
              variant={activeView === "teams" ? "primary" : "outline-primary"}
              onClick={() => handleButtonClick("teams")}
            >
              Usuarios
            </Button>
          ) : null}
          {user?.team === "Admin" ||
            user?.uid === "z2kmjfUdO7XSuhkBZiLZPjK4mFx2" ? (
            <Button
              className="navegation-button-group"
              variant={activeView === "assignments" ? "primary" : "outline-primary"}
              onClick={() => handleButtonClick("assignments")}
            >
              Asignaciones
            </Button>
          ) : null}
          {/* {user?.team === "Admin" || user?.team === "CS" ? (
            <Button
              className="navegation-button-group"
              variant={activeView === "trengo" ? "primary" : "outline-primary"}
              onClick={() => handleButtonClick("trengo")}
            >
              Tickets Trengo
            </Button>
          ) : null} */}
        </ButtonGroup>
        {activeView === "teams" ? (
          <FilterUsersComponent
            activeView={activeView}
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
            teams={teams}
          />
        ) : null}
      </div>
      {activeView === "teams" && (
        <TeamsTableComponent
          selectedTeam={selectedTeam}
          usersData={usersData}
          teams={teams}
          fetchDataUsers={fetchDataUsers}
        />
      )}
      {activeView === "assignments" && <AssignmentsTableComponent />}
      {activeView === "trengo" && <TrengoComponent />}
    </div>
  );
}
