import React, { useState } from "react";
import { Table, Row, Col, Button, Form, Alert } from "react-bootstrap";
import InputComponent from "../../../../components/InputComponent";
import { MdAdd, MdEdit } from "react-icons/md";
import "../../../../styles/style/tabsStyle.css";
import "../../../../styles/style/tableStyle.css";
import { RiDeleteBin6Fill } from "react-icons/ri";
import FinalConsumerModalComponent from "./FinalConsumerModalComponent";
import LegalEntityModalComponent from "./LegalEntityModalComponent";
import ModalEditFinalConsumerComponent from "./ModalEditFinalConsumerComponent";
import ModalEditLegalEntityComponent from "./ModalEditLegalIntityComponent";
import IconAlert from "../../images/icon-alert.svg";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { colors } from "../../../../styles/colors";
import {
  deletePersonaJuridica,
  deleteTitular,
  updateDataTake,
} from "../../services/TakeServices/HolderServices";
import Swal from "sweetalert2";

export default function HolderComponent({
  disabled,
  deal_id,
  setarrayConsumidorFinal,
  arrayConsumidorFinal,
  setarrayConsumidorFinalDelete,
  arrayConsumidorFinalDelete,
  arrayPersonaJuridicaDelete,
  setarrayPersonaJuridicaDelete,
  setarrayPersonaJuridica,
  arrayPersonaJuridica,
  dataEmpresa,
  dataTipoInscripcion,
  user,
  toggleEditing,
  textStyle,
}) {
  const { register, setValue, handleSubmit } = useForm();

  const [finalConsumerModal, setFinalConsumerModal] = React.useState(false);
  const [finalConsumerModalEdit, setfinalConsumerModalEdit] = useState(false);
  const [legalEntityModal, setLegalEntityModal] = React.useState(false);
  const [legaEntityModalEdit, setlegaEntityModalEdit] = useState();
  const [titularEdit, settitularEdit] = useState();
  const [titularId, settitularId] = useState();
  const [apoderadoEdit, setapoderadoEdit] = useState();
  const [apoderadoId, setapoderadoId] = useState();
  const [holderType, setHolderType] = useState("Persona juridica");
  const [totalTitularidad, setTotalTitularidad] = useState(0);

  React.useEffect(() => {
    const total = arrayConsumidorFinal.reduce(
      (acc, item) => acc + (parseFloat(item.titularidad) || 0),
      0
    );
    setTotalTitularidad(total);
  }, [arrayConsumidorFinal]);

  React.useEffect(() => {
    setValue(
      "tipo_inscripcion",
      dataTipoInscripcion !== "" ? dataTipoInscripcion : holderType
    );
    setHolderType(
      dataTipoInscripcion !== "" ? dataTipoInscripcion : holderType
    );
    setValue("validada", dataEmpresa?.validada);
    setValue("tipo_empresa", dataEmpresa?.tipo_empresa);
    setValue("domicilio_fiscal", dataEmpresa?.domicilio_fiscal);
  }, [dataTipoInscripcion, dataEmpresa, setValue]);

  const updateInfoTake = (data) => {
    updateDataTake(user, deal_id, data);
    toast.success("Informacion Guardada con exito.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    toggleEditing();
  };
  const captureType = (e) => {
    setHolderType(e.target.value);
  };

  const updateTitularFunction = (data) => {
    setfinalConsumerModalEdit(true);
    settitularEdit(data);
    settitularId(data.cuil_dni);
  };

  const updatePersonaJuridica = (data) => {
    setlegaEntityModalEdit(true);
    setapoderadoEdit(data);
    setapoderadoId(data.dni);
  };

  function deleteConsumidorFinalModal(data) {
    Swal.fire({
      title: "Borrar titular",
      text: "¿Estas seguro de que quieres eliminar el titular?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then((resultado) => {
      if (resultado.value) {
        deleteTitular(user, user?.company, deal_id, data);
      } else {
        console.log("*NO se elimina el pago*");
      }
    });
  }
  function deletePersonaJuridicaModal(data) {
    Swal.fire({
      title: "Borrar persona juridica",
      text: "¿Estas seguro de que quieres eliminar el apoderado?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then((resultado) => {
      if (resultado.value) {
        deletePersonaJuridica(user, user?.company, deal_id, data);
      } else {
        console.log("*NO se elimina el pago*");
      }
    });
  }

  return (
    <>
      <Form onSubmit={handleSubmit(updateInfoTake)}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p style={textStyle} className="mb-2">
            Titulares
          </p>
          {!disabled ? (
            <Button type="submit" disabled={disabled}>
              Guardar cambios
            </Button>
          ) : null}
        </div>
        <Row>
          <Col lg={6}>
            <div className="select-container">
              <label className="label-input">Tipo de Inscripción</label>
              <Form.Select
                className="deal-select"
                disabled={disabled}
                {...register("tipo_inscripcion")}
                onChange={captureType}
              >
                <option value="" disabled hidden>
                  Seleccione una opción
                </option>
                <option>Consumidor final</option>
                <option>Persona juridica</option>
              </Form.Select>
            </div>
          </Col>
        </Row>
        <>
          {holderType === "Consumidor final" ? (
            <>
              <Table
                bordered
                style={{ marginTop: "16px" }}
                className="discount-table"
              >
                <thead>
                  <tr className="table-header">
                    <th>Nombre completo</th>
                    <th>DNI/CUIL</th>
                    <th>Estado Civil</th>
                    <th>Domicilio fiscal</th>
                    <th>Domicilio real del titular</th>
                    <th>Mail</th>
                    <th>Titularidad (%)</th>
                    <th
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                      }}
                    >
                      Nombre del conyugue{" "}
                      {
                        <Button
                          size="sm"
                          hidden={disabled}
                          disabled={totalTitularidad >= 100}
                          onClick={() => {
                            setFinalConsumerModal(true);
                          }}
                          className="ms-1"
                        >
                          <MdAdd
                            style={{
                              fontSize: "22px",
                            }}
                          />
                        </Button>
                      }
                    </th>
                  </tr>
                </thead>
                <FinalConsumerModalComponent
                  modal={finalConsumerModal}
                  deal_id={deal_id}
                  setModal={setFinalConsumerModal}
                  setarrayConsumidorFinal={setarrayConsumidorFinal}
                  totalTitularidad={totalTitularidad}
                  user={user}
                />
                <ModalEditFinalConsumerComponent
                  modal={finalConsumerModalEdit}
                  setModal={setfinalConsumerModalEdit}
                  deal_id={deal_id}
                  titularEdit={titularEdit}
                  titularId={titularId}
                  arrayConsumidorFinal={arrayConsumidorFinal}
                  setHolderType={setHolderType}
                  totalTitularidad={totalTitularidad}
                />
                <tbody>
                  {arrayConsumidorFinal?.map((info, i) => (
                    <tr className="table-body" key={i}>
                      <td>{info.nombre_completo}</td>
                      <td>{info.cuil_dni}</td>
                      <td>{info.estado_civil}</td>
                      <td>{info.domicilio_fiscal}</td>
                      <td>{info.domicilio_real}</td>
                      <td>{info.mail}</td>
                      <td>{info.titularidad}</td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {info.conyuge}{" "}
                        {!disabled ? (
                          <>
                            <div>
                              <MdEdit
                                onClick={() => updateTitularFunction(info)}
                                style={{
                                  fontSize: "24px",
                                  color: colors.primary.primary,
                                  cursor: "pointer",
                                }}
                              />
                              <RiDeleteBin6Fill
                                style={{
                                  fontSize: "24px",
                                  color: colors.status.red,
                                  cursor: "pointer",
                                }}
                                // onClick={() => deleteFila(i)}
                                onClick={() => deleteConsumidorFinalModal(info)}
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {totalTitularidad < 100 && totalTitularidad > 0 && (
                <div className="d-flex align-items-center justify-content-start">
                  <img src={IconAlert} alt="icon-alert" className="me-1" />
                  <p
                    style={{
                      color: colors.status.red,
                      fontWeight: 600,
                      margin: "0px",
                    }}
                  >
                    La suma de las titularidades es menor a 100%. Por favor,
                    verifique.
                  </p>
                </div>
              )}
            </>
          ) : (
            <>
              {/* <form onSubmit={handleSubmit(updatePersonaJuridica)}> */}
              <Row>
                <Col xs={6} lg={4}>
                  <InputComponent
                    label={"Nombre de la empresa"}
                    placeholder={dataEmpresa?.empresa}
                    type="text"
                    register={register}
                    disabled={disabled}
                    valueRegister={"empresa"}
                  />
                </Col>
                <Col xs={6} lg={4}>
                  <InputComponent
                    label={"CUIT"}
                    placeholder={dataEmpresa?.cuit}
                    type="number"
                    disabled={disabled}
                    register={register}
                    valueRegister={"cuit"}
                  />
                </Col>
                <Col xs={6} lg={4}>
                  <InputComponent
                    label={"Domicilio fiscal"}
                    placeholder={dataEmpresa?.domicilio_fiscal}
                    type="text"
                    register={register}
                    disabled={disabled}
                    valueRegister={"domicilio_fiscal"}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6} lg={4}>
                  <InputComponent
                    label={"Mail"}
                    placeholder={dataEmpresa?.mail}
                    type="email"
                    disabled={disabled}
                    register={register}
                    valueRegister={"mail"}
                  />
                </Col>
                <Col xs={6} lg={4}>
                  <label className="label-input">Empresa validada</label>
                  <Form.Select
                    className="deal-select mb-4"
                    disabled={disabled}
                    {...register("validada")}
                  >
                    <option value="" disabled hidden>
                      Seleccione una opción
                    </option>
                    <option>Si</option>
                    <option>No</option>
                  </Form.Select>
                </Col>
                <Col xs={6} lg={4}>
                  <label className="label-input">Tipo de empresa</label>
                  <Form.Select
                    className="deal-select"
                    disabled={disabled}
                    {...register("tipo_empresa")}
                  >
                    <option value="" disabled hidden>
                      Seleccione una opción
                    </option>
                    <option>Sociedad Anonima</option>
                    <option>SRL</option>
                  </Form.Select>
                </Col>
              </Row>
              {/* </form> */}
              <Table bordered className="discount-table">
                <thead>
                  <tr className="table-header">
                    <th>Apoderado</th>
                    <th
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      DNI apoderado{" "}
                      {
                        <Button
                          size="sm"
                          hidden={disabled}
                          onClick={() => {
                            setLegalEntityModal(true);
                          }}
                        >
                          <MdAdd
                            style={{
                              fontSize: "22px",
                            }}
                          />
                        </Button>
                      }
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <LegalEntityModalComponent
                    modal={legalEntityModal}
                    setModal={setLegalEntityModal}
                    deal_id={deal_id}
                    setarrayPersonaJuridica={setarrayPersonaJuridica}
                    user={user}
                  />
                  <ModalEditLegalEntityComponent
                    modal={legaEntityModalEdit}
                    setModal={setlegaEntityModalEdit}
                    deal_id={deal_id}
                    apoderadoEdit={apoderadoEdit}
                    apoderadoId={apoderadoId}
                    arrayPersonaJuridica={arrayPersonaJuridica}
                  />
                  {arrayPersonaJuridica?.map((info, i) => (
                    <tr className="table-body" key={i}>
                      <td>{info.name}</td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {info.dni}{" "}
                        {!disabled ? (
                          <>
                            <div>
                              <MdEdit
                                onClick={() => updatePersonaJuridica(info)}
                                style={{
                                  fontSize: "24px",
                                  color: colors.primary.primary,
                                  cursor: "pointer",
                                }}
                              />
                              <RiDeleteBin6Fill
                                style={{
                                  fontSize: "24px",
                                  color: colors.status.red,
                                  cursor: "pointer",
                                }}
                                onClick={() => deletePersonaJuridicaModal(info)}
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </>
      </Form>
    </>
  );
}
