import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import {
  formatNumber,
  updateOfferPostFinal,
} from "../../../services/servicesPricing";
import toast from "react-hot-toast";
import { colors } from "../../../../../styles/colors";

const FinalOfferPricerModal = ({
  deal_id,
  setshowOfferFinal,
  showOfferFinal,
  user,
  dataHistory,
  setActiveModalPricing,
  initialAptoConsignacion,
  valueCotizacion,
}) => {
  // Determinar si es la primera vez
  const isFirstTime =
    initialAptoConsignacion === undefined || initialAptoConsignacion === null;

  // Valor por defecto para aptoConsignacion
  const defaultAptoConsignacion = isFirstTime ? false : initialAptoConsignacion;

  // Estados del formulario
  const [valueOfferFinal, setValueOfferFinal] = useState(valueCotizacion || "");
  const [aptoConsignacion, setAptoConsignacion] = useState(
    defaultAptoConsignacion
  );

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (showOfferFinal) {
      setValueOfferFinal(valueCotizacion || "");
      setAptoConsignacion(defaultAptoConsignacion);
    }
  }, [showOfferFinal, defaultAptoConsignacion, valueCotizacion]);

  const hasAptoConsignacionChanged =
    !isFirstTime && aptoConsignacion !== defaultAptoConsignacion;
  const disableOtherActions = hasAptoConsignacionChanged;

  const handleCloseOfferFinal = () => {
    setshowOfferFinal(false);
    setActiveModalPricing(false);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatNumber(inputValue);
    setValueOfferFinal(formattedValue);
  };

  const handleSaveChanges = () => {
    const data = {
      "final_offer.consignment_user": user,
      "final_offer.consignment_ready": aptoConsignacion,
    };
    updateOfferPostFinal(user?.company, deal_id, data);
    toast.success("Cambios guardados.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setshowOfferFinal(false);
    setActiveModalPricing(false);
  };

  const sendPricingOfferFinal = () => {
    // Validación del campo valueOfferFinal
    if (!valueOfferFinal) {
      setErrors({ value: "La oferta final es requerida" });
      return;
    }

    const data = {
      "final_offer.user": user,
      "final_offer.consignment_user": user,
      "final_offer.consignment_ready": aptoConsignacion,
    };
    if (valueOfferFinal) {
      data["final_offer.value"] = valueOfferFinal;
    }
    updateOfferPostFinal(user?.company, deal_id, data);
    toast.success("Pricing enviado.", {
      style: {
        color: colors.text.primary,
        background: colors.neutral.green,
      },
    });
    setshowOfferFinal(false);
    setActiveModalPricing(false);
  };

  return (
    <div>
      <Modal
        show={showOfferFinal}
        onHide={handleCloseOfferFinal}
        centered
        dialogClassName="myModal"
      >
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="titleModal">Oferta final</p>
            <div style={{ width: "100%" }}>
              <div className="d-flex align-items-center mt-2">
                <input
                  className="inputModal"
                  style={{ width: "100%" }}
                  placeholder="$000.000.000"
                  type="text"
                  value={valueOfferFinal}
                  onChange={handleInputChange}
                  disabled={disableOtherActions}
                />
              </div>
              {errors.value && (
                <p
                  style={{
                    margin: "0px",
                    color: colors.status.red,
                    fontSize: "12px",
                    width: "100%",
                  }}
                  className="mt-2"
                >
                  {errors.value}
                </p>
              )}
              {/* Sección para los botones de radio */}
              <div className="mt-2 text-center">
                <Form.Group>
                  <Form.Label className="mb-22 textModalConsignment">
                    ¿Vehículo apto para consignación?
                  </Form.Label>
                  <div className="d-flex justify-content-center align-items-center">
                    <Form.Check
                      type="radio"
                      label="Sí"
                      value="si"
                      name="aptoConsignacion"
                      inline
                      checked={aptoConsignacion === true}
                      onChange={() => setAptoConsignacion(true)}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      value="no"
                      name="aptoConsignacion"
                      inline
                      checked={aptoConsignacion === false}
                      onChange={() => setAptoConsignacion(false)}
                    />
                  </div>
                </Form.Group>
              </div>
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-center flex-column justify-content-between mt-2"
              >
                {!disableOtherActions && (
                  <button
                    type="button"
                    className="btnSendPricing mb-2 mt-2"
                    onClick={sendPricingOfferFinal}
                  >
                    Enviar pricing
                  </button>
                )}
              </div>
            </div>
            {!isFirstTime && hasAptoConsignacionChanged && (
              <button
                className="btnSendPricing mb-2"
                onClick={handleSaveChanges}
              >
                Guardar cambios
              </button>
            )}
            <div className="mt-2" style={{ width: "100%" }}>
              {dataHistory?.length > 0 &&
                dataHistory.map((info, i) => (
                  <div
                    className="d-flex align-items-center justify-content-between dividerHistory mb-3"
                    style={{ width: "100%" }}
                    key={i}
                  >
                    <p className="textHistory mb-2">${info.value}</p>
                    <p className="textHistory mb-2">
                      {new Date(info.date.seconds * 1000).toLocaleDateString()} -{" "}
                      {new Date(info.date.seconds * 1000).toLocaleTimeString(
                        "en-US",
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    </p>
                  </div>
                ))}
            </div>
            <button
              variant="secondary"
              onClick={handleCloseOfferFinal}
              className="btnCancelarModal"
            >
              Cancelar
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FinalOfferPricerModal;
