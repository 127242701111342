import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { InputComponent } from "../../../../components";
import { useForm } from "react-hook-form";
import {
  updateConsignment,
  updateFieldConsignament,
} from "../../services/TakeServices/ConsignmentServices";
import { formatNumber } from "../../../Pricing/services/servicesPricing";
import toast from "react-hot-toast";
import { colors } from "../../../../styles/colors";

export const ConsignmentTabComponent = ({
  disabled,
  deal_id,
  user,
  takeData,
  selectedType,
  toggleEditing,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [typeMoney, setTypeMoney] = useState("ARS");
  const [priceConsignment, setPriceConsignment] = useState("");
  const [typeChange, setTypeChange] = useState("");
  const [comision, setComision] = useState("");

  useEffect(() => {
    setValue("precio_consignacion", takeData?.precio_consignacion);
    setValue("moneda", takeData?.moneda || "ARS");
    setTypeMoney(takeData?.moneda || "ARS");
    setValue("tipo_cambio", takeData?.tipo_cambio);

    if (takeData?.comision) {
      const formattedCommission = `${takeData.comision.replace("%", "")}%`;
      setComision(formattedCommission);
      setValue("comision", formattedCommission);
    } else {
      setComision("");
    }

    setValue("dias_consignacion", takeData?.dias_consignacion);
    setValue("monto_comision", takeData?.monto_comision);
  }, [setValue, takeData]);

  const handleInputChangeCommission = (event) => {
    let inputValue = event.target.value.replace("%", "");
    if (!isNaN(inputValue) && inputValue !== "") {
      const formattedValue = `${inputValue}%`;
      setComision(formattedValue);
      setValue("comision", formattedValue);
    } else {
      setComision("");
      setValue("comision", "");
    }
  };

  useEffect(() => {
    const commissionValue = takeData?.comision;
    if (commissionValue && !commissionValue.includes("%")) {
      setComision(`${commissionValue}%`);
    } else if (commissionValue) {
      setComision(commissionValue);
    } else {
      setComision("");
    }
  }, [setValue, takeData]);

  const handleInputChange = (e) => {
    setTypeMoney(e.target.value);
  };

  const handleInputChangePrice = (event) => {
    const inputValue = event.target.value.replace(/\D/g, "");
    const formattedValue = `$${formatNumber(inputValue)}`;
    setPriceConsignment(formattedValue);
    setValue("precio_consignacion", formattedValue);
  };

  const handleInputChangeTypeChange = (event) => {
    const inputValue = event.target.value.replace(/\D/g, "");
    const formattedValue = `$${formatNumber(inputValue)}`;
    setTypeChange(formattedValue);
    setValue("tipo_cambio", formattedValue);
  };

  useEffect(() => {
    if (takeData?.precio_consignacion) {
      const formattedPrice = `$${formatNumber(takeData.precio_consignacion)}`;
      setPriceConsignment(formattedPrice);
      setValue("precio_consignacion", formattedPrice);
    } else {
      setPriceConsignment("");
    }

    if (takeData?.tipo_cambio) {
      const formattedTypeChange = `$${formatNumber(takeData.tipo_cambio)}`;
      setTypeChange(formattedTypeChange);
      setValue("tipo_cambio", formattedTypeChange);
    } else {
      setTypeChange("");
    }
  }, [takeData, setValue]);

  const parseStringToNumber = (stringNumber) => {
    if (!stringNumber) return 0;
    return Number(stringNumber.replace(/\./g, "").replace("$", ""));
  };

  const formatNumberWithThousandsSeparator = (number) => {
    return number.toLocaleString("es-ES");
  };

  const parsePercentage = (value) => {
    if (!value) return 0;
    return parseFloat(value.replace("%", "")) || 0;
  };

  const calculateMontoComision = () => {
    const precioConsignacion = parseStringToNumber(
      takeData?.precio_consignacion
    );
    const comisionValue = parsePercentage(comision);
    if (!precioConsignacion || !comisionValue) return 0;
    return formatNumberWithThousandsSeparator(
      (precioConsignacion * comisionValue) / 100
    );
  };

  const calculateMontoComisionUSD = () => {
    const tipoCambio = parseStringToNumber(takeData?.tipo_cambio);
    const comisionValue = parsePercentage(comision);
    if (!tipoCambio || !comisionValue) return 0;

    const resultado = Math.round(tipoCambio / comisionValue);
    return formatNumberWithThousandsSeparator(resultado);
  };

  const calculateMontoUSD = () => {
    const precioConsignacion = parseStringToNumber(
      takeData?.precio_consignacion
    );
    const tipoCambio = parseStringToNumber(takeData?.tipo_cambio);
    if (!precioConsignacion || !tipoCambio) return 0;

    const resultado = Math.round(precioConsignacion / tipoCambio);
    return formatNumberWithThousandsSeparator(resultado);
  };

  const sendConsignacion = async (data) => {
    const cleanedData = {
      ...data,
      comision: `${data?.comision?.replace("%", "")}`,
      precio_consignacion: data?.precio_consignacion?.replace("$", ""),
      tipo_cambio: data?.tipo_cambio?.replace("$", ""),
    };

    try {
      await updateConsignment(cleanedData, deal_id, user);
      await updateFieldConsignament(
        selectedType === "toma" ? false : true,
        deal_id,
        user
      );
      toast.success("Información guardada con éxito.", {
        style: {
          color: colors.text.primary,
          background: colors.neutral.green,
        },
      });
      toggleEditing();

      if (cleanedData.comision) {
        setComision(`${cleanedData.comision}%`);
      }
    } catch (error) {
      toast.error("Error al guardar los datos.", {
        style: {
          color: colors.text.primary,
          background: colors.error.dark,
        },
      });
      console.error("Error saving data:", error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(sendConsignacion)}>
        <div className="d-flex align-items-center justify-content-end">
          {!disabled ? (
            <Button type="submit" disabled={disabled}>
              Guardar cambios
            </Button>
          ) : null}
        </div>
        <Row>
          <Col lg={4}>
            <div style={{ marginBottom: "12px", marginTop: "8px" }}>
              <label className="label-input">Precio Consignación</label>
              <input
                className="input-deal"
                style={{ width: "100%" }}
                type="text"
                disabled={disabled}
                {...register("precio_consignacion", {
                  required: true,
                })}
                value={priceConsignment}
                onChange={handleInputChangePrice}
              />
              {errors.precio_consignacion && (
                <span className="error-message">
                  Este campo es obligatorio.
                </span>
              )}
            </div>
          </Col>
          <Col lg={4}>
            <div style={{ marginBottom: "12px", marginTop: "8px" }}>
              <label className="label-input">Comisión (%)</label>
              <input
                className="input-deal"
                style={{ width: "100%" }}
                type="text"
                disabled={disabled}
                {...register("comision", {
                  required: true,
                })}
                value={comision}
                onChange={handleInputChangeCommission}
              />
              {errors.comision && (
                <span className="error-message">
                  Este campo es obligatorio.
                </span>
              )}
            </div>
          </Col>
          <Col lg={4}>
            <InputComponent
              label={"Comisión (ARS)"}
              placeholder={calculateMontoComision()}
              type="number"
              register={register}
              valueRegister={"monto_comision"}
              disabled={true}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <InputComponent
              label={"Días en Consignación"}
              placeholder=""
              type="number"
              register={register}
              valueRegister={"dias_consignacion"}
              disabled={disabled}
              required={true}
            />
            {errors.dias_consignacion && (
              <span className="error-message">Este campo es obligatorio.</span>
            )}
          </Col>
          <Col lg={4}>
            <div className="select-container">
              <label className="label-input">Moneda</label>
              <Form.Select
                className="deal-select"
                {...register("moneda", {
                  required: true,
                })}
                onChange={(e) => handleInputChange(e)}
                disabled={disabled}
              >
                <option>ARS</option>
                <option>USD</option>
              </Form.Select>
              {errors.moneda && (
                <span className="error-message">
                  Este campo es obligatorio.
                </span>
              )}
            </div>
          </Col>
          {typeMoney === "USD" ? (
            <>
              <Col lg={4}>
                <div style={{ marginBottom: "12px", marginTop: "8px" }}>
                  <label className="label-input">Tipo de cambio</label>
                  <input
                    className="input-deal"
                    style={{ width: "100%" }}
                    type="text"
                    disabled={disabled}
                    {...register("tipo_cambio", {
                      required: true,
                    })}
                    value={typeChange}
                    onChange={handleInputChangeTypeChange}
                  />
                  {errors.tipo_cambio && (
                    <span className="error-message">
                      Este campo es obligatorio.
                    </span>
                  )}
                </div>
              </Col>
              <Col lg={4}>
                <div style={{ marginBottom: "12px", marginTop: "8px" }}>
                  <label className="label-input">Precio consignación USD</label>
                  <input
                    className="input-deal"
                    placeholder={calculateMontoUSD()}
                    style={{ width: "100%" }}
                    disabled={true}
                    type="text"
                  />
                </div>
              </Col>
              <Col lg={4}>
                <InputComponent
                  label={"Comisión (USD)"}
                  placeholder={calculateMontoComisionUSD()}
                  type="number"
                  register={register}
                  valueRegister={"monto_comision_usd"}
                  disabled={true}
                />
              </Col>
            </>
          ) : null}
        </Row>
      </form>
    </>
  );
};
