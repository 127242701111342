import { db } from "../../../../firebase/config";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";

export const updateDataTake = async (user, deal_id, data) => {
  const persona_juridica_array = {
    "payment.titulares.persona_juridica.cuit": data.cuit,
    "payment.titulares.persona_juridica.empresa": data.empresa,
    "payment.titulares.persona_juridica.validada": data.validada,
    "payment.titulares.persona_juridica.domicilio_fiscal":
      data.domicilio_fiscal,
    "payment.titulares.persona_juridica.mail": data.mail,
    "payment.titulares.persona_juridica.tipo_empresa": data.tipo_empresa,
  };

  const type_inscripcion_array = {
    "payment.titulares.tipo_inscripcion": data.tipo_inscripcion,
  };

  const persona_juridica = Object.fromEntries(
    Object.entries(persona_juridica_array).filter(
      ([_, value]) => value !== undefined && value !== ""
    )
  );

  const takeData = {
    ...persona_juridica,
    user_update_data: user,
  };

  const takeDocRef = doc(
    db,
    "companies",
    user?.company,
    "deals",
    deal_id,
    "data",
    "take"
  );

  if (data.tipo_inscripcion) {
    try {
      await updateDoc(takeDocRef, type_inscripcion_array);
    } catch (error) {
      console.error("Error updating tipo_inscripcion:", error);
    }
  }

  try {
    await updateDoc(takeDocRef, takeData);
  } catch (error) {
    console.error("Error updating takeData:", error);
  }
};

export const sendApoderado = async (user, company, deal_id, data) => {
  const takeDocRef = doc(
    db,
    "companies",
    company,
    "deals",
    deal_id,
    "data",
    "take"
  );

  try {
    await updateDoc(takeDocRef, {
      "payment.titulares.persona_juridica.apoderados": arrayUnion(...data),
      user_update_data: user,
    });
  } catch (error) {
    console.error("Error sending apoderado:", error);
  }
};

export const deletePersonaJuridica = async (user, company, deal_id, data) => {
  const takeDocRef = doc(
    db,
    "companies",
    company,
    "deals",
    deal_id,
    "data",
    "take"
  );

  try {
    await updateDoc(takeDocRef, {
      "payment.titulares.persona_juridica.apoderados": arrayRemove(data),
      user_update_data: user,
    });
  } catch (error) {
    console.error("Error deleting persona juridica:", error);
  }
};

export const updateApoderado = async (deal_id, newArray, user) => {
  const takeDocRef = doc(
    db,
    "companies",
    user?.company,
    "deals",
    deal_id,
    "data",
    "take"
  );

  try {
    await updateDoc(takeDocRef, {
      "payment.titulares.persona_juridica.apoderados": newArray,
    });

    await updateDoc(takeDocRef, { user_update_data: user });
  } catch (error) {
    console.error("Error updating apoderado:", error);
  }
};

export const sendTitular = async (user, company, deal_id, data) => {
  const takeDocRef = doc(
    db,
    "companies",
    company,
    "deals",
    deal_id,
    "data",
    "take"
  );

  try {
    await updateDoc(takeDocRef, {
      "payment.titulares.personas_fisicas": arrayUnion(...data),
      user_update_data: user,
    });
  } catch (error) {
    console.error("Error sending titular:", error);
  }
};

export const deleteTitular = async (user, company, deal_id, data) => {
  const takeDocRef = doc(
    db,
    "companies",
    company,
    "deals",
    deal_id,
    "data",
    "take"
  );

  try {
    await updateDoc(takeDocRef, {
      "payment.titulares.personas_fisicas": arrayRemove(data),
      user_update_data: user,
    });
  } catch (error) {
    console.error("Error deleting titular:", error);
  }
};

export const updateTitular = async (deal_id, newArray, user) => {
  const takeDocRef = doc(
    db,
    "companies",
    user?.company,
    "deals",
    deal_id,
    "data",
    "take"
  );

  try {
    await updateDoc(takeDocRef, {
      "payment.titulares.personas_fisicas": newArray,
    });

    await updateDoc(takeDocRef, { user_update_data: user });
  } catch (error) {
    console.error("Error updating titular:", error);
  }
};
