import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import InputComponent from "../../../../../components/InputComponent";

const VehicleManual = ({ register, setValue, dataVehiculo }) => {
  useEffect(() => {
    setValue("year", dataVehiculo?.year === false ? "" : dataVehiculo?.year);
    setValue("brand", dataVehiculo?.brand === false ? "" : dataVehiculo?.brand);
    setValue("model", dataVehiculo?.model === false ? "" : dataVehiculo?.model);
    setValue(
      "version",
      dataVehiculo?.version === false ? "" : dataVehiculo?.version
    );
  }, [dataVehiculo, setValue]);

  return (
    <>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <InputComponent
              label={"Año"}
              placeholder={"Ingresar año"}
              register={register}
              valueRegister={"year"}
              id="año"
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <InputComponent
              label={"Marca"}
              placeholder={"Ingresar marca"}
              register={register}
              valueRegister={"brand"}
              id="Marca"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="select-container">
            <InputComponent
              label={"Modelo"}
              placeholder={"Ingresar modelo"}
              register={register}
              valueRegister={"model"}
              id="modelo"
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="select-container">
            <InputComponent
              label={"Version"}
              placeholder={"Ingresar version"}
              register={register}
              valueRegister={"version"}
              id="versión"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default VehicleManual;
