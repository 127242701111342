import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import axios from "axios";
import SmallSpinnerComponent from "../../../../../components/SmallSpinnerComponent";
import "../../../../../styles/style/dealBasicStyle.css";
import { Row, Col } from "react-bootstrap";
import { useEffect } from "react";

const VehicleFunctional = ({
  register,
  disabled,
  dataVehiculo,
  setdataYear,
  setdataBrand,
  setdataBrandId,
  setdataModel,
  setdataModelId,
  setdataVersion,
  setdataVersionId,
  brands,
  models,
  versions,
  setbrands,
  setmodels,
  setversions,
  setValue,
}) => {
  const years = [
    2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011,
    2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000,
  ];
  const baseURL = "https://infoauto-348420.rj.r.appspot.com/";

  React.useEffect(() => {
    setValue(
      "año",
      dataVehiculo?.year ? dataVehiculo?.year : "Seleccione un año"
    );
    setValue(
      "marca",
      dataVehiculo?.brand ? dataVehiculo?.brand : "Seleccione una marca"
    );
    setValue(
      "modelo",
      dataVehiculo?.model ? dataVehiculo?.model : "Seleccione un modelo"
    );
    setValue(
      "version",
      dataVehiculo?.version ? dataVehiculo?.version : "Seleccione una version"
    );
  }, [dataVehiculo, setValue]);

  const [yearCurrent, setyearCurrent] = useState(null);
  const [brandCurrent, setbrandCurrent] = useState(null);
  const [modelCurrent, setmodelCurrent] = useState(null);
  const [versionCurrent, setversionCurrent] = useState(null);

  const yearSelected = (data) => {
    setyearCurrent(data);
    setdataYear(data);
    setbrands(null);
    setmodels(null);
    setversions(null);
    setdataBrand("");
    setdataModel("");
    setdataVersion("");
    setdataBrandId("");
    setdataModelId("");
    setdataVersionId("");
    axios
      .get(`${baseURL}brands/${data}`)
      .then((resp) => {
        setbrands(resp.data.brands);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const brandSelected = (data) => {
    let dataBrand = JSON.parse(data);
    setbrandCurrent(dataBrand.id);
    setdataBrandId(dataBrand.id);
    setdataBrand(dataBrand.name);
    setdataModel("");
    setdataVersion("");
    setdataModelId("");
    setdataVersionId("");
    setmodels(null);
    setversions(null);
    axios
      .get(`${baseURL}brand/${dataBrand.id}/models/${yearCurrent}`)
      .then((resp) => {
        setmodels(resp.data.models);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const modelSelected = (data) => {
    setmodelCurrent(data);
    let dataModel = JSON.parse(data);
    setdataModel(dataModel.name);
    setdataModelId(dataModel.id);
    setdataVersion("");
    setdataVersionId("");
    setversions(null);
    axios
      .get(
        `${baseURL}brand/${brandCurrent}/model/${dataModel.id}/versions/${yearCurrent}`
      )
      .then((resp) => {
        setversions(resp.data.versions);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const versionSelected = (data) => {
    let dataVersion = JSON.parse(data);
    setversionCurrent(dataVersion.id);
    setdataVersionId(dataVersion.id);
    setdataVersion(dataVersion.name);
  };

  return (
    <>
      {dataVehiculo !== undefined ? (
        <>
          <Row>
            <Col lg={6}>
              <div className="select-container">
                <label className="deal-select-title" htmlFor="año">Año</label>
                <Form.Select
                  id="año"
                  disabled={disabled}
                  {...register("año")}
                  onChange={(e) => yearSelected(e.target.value)}
                  className="deal-select"
                >
                  <option>Seleccione un año</option>
                  {years.map((data) => (
                    <option value={data} key={data}>
                      {data}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-container">
                <label className="deal-select-title" htmlFor="marca">Marca</label>
                <Form.Select
                  id="marca"
                  disabled={disabled}
                  {...register("marca")}
                  onChange={(e) => brandSelected(e.target.value)}
                  className="deal-select"
                >
                  <option>Seleccione una marca</option>
                  {brands?.map((data) => (
                    <option value={JSON.stringify(data)} key={data.id}>
                      {data.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div className="select-container">
                <label className="deal-select-title" htmlFor="modelo">Modelo</label>
                <Form.Select
                  id="modelo"
                  disabled={disabled}
                  {...register("modelo")}
                  onChange={(e) => modelSelected(e.target.value)}
                  className="deal-select"
                >
                  <option>Seleccione un modelo</option>
                  {models?.map((data) => (
                    <option value={JSON.stringify(data)} key={data.id}>
                      {data.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-container">
                <label className="deal-select-title" htmlFor="version">Versión</label>
                <Form.Select
                  id="version"
                  disabled={disabled}
                  {...register("version")}
                  onChange={(e) => versionSelected(e.target.value)}
                  className="deal-select"
                >
                  <option>Seleccione una versión</option>
                  {versions?.map((data) => (
                    <option value={JSON.stringify(data)} key={data.id}>
                      {data.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <SmallSpinnerComponent />
      )}
    </>
  );
};

export default VehicleFunctional;
