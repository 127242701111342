import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import ChangeInspectionTypeButton from "./virtualInspectionTabs/ChangeInspectionTypeButton";
import shortSnapshotFirebase from "../../../../utils/ShortSnapshotFirebase";
import { useParams } from "react-router-dom";
import { useUser } from "../../../../context/UserContext";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { colors } from "../../../../styles/colors";
import { MdEdit } from "react-icons/md";

export default function PhysicalInspectionComponent({ dataAppointment, inspType }) {
  const { deal_id } = useParams();
  const { user } = useUser();
  const [deal, setDeal] = useState(null);
  let isPageWide = useMediaQuery("(min-width: 600px)");

  useEffect(() => {
    const callbackDeals = (data) => {
      setDeal(data);
    };
    const unsubscribeDeals = shortSnapshotFirebase(
      user?.company,
      "deals",
      deal_id,
      callbackDeals
    );
    return () => {
      unsubscribeDeals();
    };

  }, [user?.company, deal_id]);

  const formatSource = (source) => {
    const autaSources = ["auta", "web_auta", "landing_auta"];
    const sanJorgeSources = ["san_jorge", "landing_san_jorge", "", undefined];

    if (autaSources.includes(source)) {
      return "auta";
    } else if (sanJorgeSources.includes(source)) {
      return "san_jorge";
    }
    return source; // Default case, if none of the above matches
  };

  const formattedSource = formatSource(deal?.source);

  const popoverContent = (
    <Popover id="popover-basic">
      <Popover.Body style={{ fontSize: isPageWide ? "14px" : "12px" }}>
        Modificar inspección
      </Popover.Body>
    </Popover>
  );

  const appointmentDetails = (
    <div className="d-flex align-items-center">
      <p className="appointment-text">
        Agendada para el día{" "}
        {new Date(dataAppointment?.date?.seconds * 1000).toLocaleDateString()}{" "}
        a las{" "}
        {new Date(dataAppointment?.date?.seconds * 1000).getHours()}:
        {new Date(dataAppointment?.date?.seconds * 1000).getMinutes() === 0
          ? `00`
          : new Date(dataAppointment?.date?.seconds * 1000).getMinutes()}{" "}
        hs{" "}
        {dataAppointment?.punto_de_inspeccion
          ? "en " + dataAppointment?.punto_de_inspeccion
          : null}
      </p>
      <OverlayTrigger
        placement="top"
        overlay={popoverContent}
      >
        <span>
          <MdEdit
            onClick={() => {
              const url = formattedSource === "san_jorge"
                ? `https://turnos.chevroletsanjorge.com.ar/turnos-san-jorge/${deal_id}`
                : `https://turnos.auta.com.ar/inspeccion/${deal_id}`;
              window.open(url, '_blank', 'noopener,noreferrer');
            }}
            style={{
              fontSize: "22px",
              color: colors.primary.primary,
              cursor: "pointer",
              marginLeft: '6px'
            }}
          />
        </span>
      </OverlayTrigger>
    </div>
  );

  return (
    <div className="containerAll">
      {inspType === 'physical' ? (
        <>
          {dataAppointment?.date !== undefined ? (
            isPageWide ? (
              <div className="d-flex justify-content-between">
                {appointmentDetails}
                <ChangeInspectionTypeButton user={user} inspType={inspType} />
              </div>
            ) : (
              <>
                {appointmentDetails}
                <div className="d-flex justify-content-between">
                  <ChangeInspectionTypeButton user={user} inspType={inspType} />
                </div>
              </>
            )
          ) : (
            <div className="d-flex justify-content-between align-items-center">
              <Button variant="primary">
                <a
                  href={
                    formattedSource === "san_jorge"
                      ? `https://turnos.chevroletsanjorge.com.ar/turnos-san-jorge/${deal_id}`
                      : `https://turnos.auta.com.ar/inspeccion/${deal_id}`
                  }
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontSize: isPageWide ? "" : "14px",
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Agendar turno
                </a>
              </Button>
              <ChangeInspectionTypeButton user={user} inspType={inspType} />
            </div>
          )}
        </>
      ) : (
        <div className={`d-flex ${isPageWide ? 'justify-content-end' : ''}`}>
          <ChangeInspectionTypeButton user={user} inspType={inspType} />
        </div>
      )}
    </div>
  );
}
