import { db } from "../../firebase/config";
import { doc, updateDoc } from "firebase/firestore";

export const sendCloseDeal = async (company, deal_id, data) => {
  const actionsDocRef = doc(
    db,
    "companies",
    company,
    "deals",
    deal_id,
    "data",
    "actions"
  );

  try {
    await updateDoc(actionsDocRef, data);
  } catch (error) {
    console.error("Error updating document: ", error);
    return error;
  }
};
