import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { updateFieldOperation } from "../../../Pricing/services/servicesPricing";

const ModalOperationComponent = ({
  show,
  handleClose,
  deal_id,
  user,
  dataOperation,
}) => {
  const [selectedType, setSelectedType] = useState("ARS");

  useEffect(() => {
    setSelectedType(dataOperation ? dataOperation : "ARS");
  }, [dataOperation]);

  const handleRadioChange = (e) => {
    const value = e.target.value;
    setSelectedType(value);
  };

  const sendOperation = () => {
    updateFieldOperation(selectedType, deal_id, user);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="sm">
      <Modal.Body>
        <p className="title-modal-price" style={{ marginLeft: "0px" }}>
          Operación en:
        </p>
        <div>
          <Form.Check
            type="radio"
            label="Pesos"
            value="ARS"
            checked={selectedType === "ARS"}
            onChange={handleRadioChange}
            inline
          />
          <Form.Check
            type="radio"
            label="Dólares"
            value="USD"
            checked={selectedType === "USD"}
            onChange={handleRadioChange}
            inline
          />
        </div>
        <button
          variant="primary"
          className="btnSendPricing mt-3"
          onClick={sendOperation}
        >
          Guardar cambios
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ModalOperationComponent;
