import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputComponent from "../../../../components/InputComponent";
import Swal from "sweetalert2";
import { sendTitular } from "../../services/TakeServices/HolderServices";

export default function FinalConsumerModalComponent({
  modal,
  setModal,
  setarrayConsumidorFinal,
  totalTitularidad,
  user,
  deal_id,
}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [valuePorcentaje, setvaluePorcentaje] = useState("");

  const addTitular = (data) => {
    const nuevoPorcentaje = parseFloat(data.titularidad || 0);
    const totalPorcentaje = totalTitularidad + nuevoPorcentaje;

    if (totalPorcentaje > 100) {
      Swal.fire({
        title: "Porcentaje excedido",
        text: `La suma de titularidades no puede superar el 100%.`,
        icon: "error",
        confirmButtonColor: "#d33",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    setarrayConsumidorFinal((prevArray) => {
      const updatedArray = [...prevArray, data];
      sendTitular(user, user?.company, deal_id, updatedArray);
      return updatedArray;
    });
    reset();
    setvaluePorcentaje("");
    setModal(false);
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleInputChangePorcentaje = (event) => {
    const inputValueWithoutPercentage = event.target.value;

    if (
      inputValueWithoutPercentage === "" ||
      /^[1-9][0-9]?$|^100$/.test(inputValueWithoutPercentage)
    ) {
      setvaluePorcentaje(inputValueWithoutPercentage);
    }
  };

  return (
    <>
      <Modal size="lg" show={modal} onHide={handleClose}>
        <form>
          <Modal.Header closeButton>
            <Modal.Title className="deal-title">Agregar titular</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
                <InputComponent
                  label={"Nombre completo"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"nombre_completo"}
                />
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"DNI/CUIL"}
                  placeholder=""
                  type="number"
                  register={register}
                  valueRegister={"cuil_dni"}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="select-container">
                  <label className="label-input">Estado civil</label>
                  <Form.Select
                    className="deal-select"
                    {...register("estado_civil")}
                  >
                    <option>Casado</option>
                    <option>Soltero</option>
                    <option>Divorciado</option>
                    <option>Viudo</option>
                  </Form.Select>
                </div>
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"Domicilio fiscal del vehículo (cédula)"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"domicilio_fiscal"}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <InputComponent
                  label={"Domicilio real del titular"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"domicilio_real"}
                />
              </Col>
              <Col lg={6}>
                <label className="label-input">Mail</label>
                <input
                  type="email"
                  className="input-deal"
                  style={{ width: "100%" }}
                  {...register("mail", {
                    required: "El correo electrónico es obligatorio.",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Por favor, ingresa un correo válido.",
                    },
                  })}
                />
                {errors.mail && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.mail.message}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <label className="label-input">Titularidad (%)</label>
                <input
                  type="number"
                  className="input-deal"
                  style={{ width: "100%" }}
                  {...register("titularidad")}
                  value={valuePorcentaje}
                  onChange={handleInputChangePorcentaje}
                  max={100}
                />
              </Col>
              <Col lg={6}>
                <InputComponent
                  label={"Nombre del cónyuge (opcional)"}
                  placeholder=""
                  type="text"
                  register={register}
                  valueRegister={"conyuge"}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={handleSubmit(addTitular)}
            >
              Agregar titular
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
