import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { useParams } from "react-router-dom";
import PreTakeInspTabsComponent from "../components/preTakeInsp/PreTakeinspTabsComponent";
import { useUser } from "../../../context/UserContext";
import {
  ServerErrorComponent,
  SpinnerFullScreenComponent,
} from "../../../components";
import useFirebaseOnSnapshot from "../../../hooks/useFirebaseOnSnapshot";
import shortSnapshotFirebase from "../../../utils/ShortSnapshotFirebase";
import { MdEdit } from "react-icons/md";
import { colors } from "../../../styles/colors";

export default function PreTakeInspPage() {
  const { deal_id } = useParams();
  const [dataPreTake, setdataPreTake] = useState();
  const [dataAppointment, setdataAppointment] = useState();
  const [dataResultFiles, setDataResultFiles] = useState();
  const [deal, setDeal] = useState(null);
  const { user } = useUser();
  const { data, loading, error } = useFirebaseOnSnapshot(
    user?.company,
    deal_id,
    "pre_take"
  );

  useEffect(() => {
    const callbackDeals = (data) => {
      setDeal(data);
    };
    const unsubscribeDeals = shortSnapshotFirebase(
      user?.company,
      "deals",
      deal_id,
      callbackDeals
    );
    return () => {
      unsubscribeDeals();
    };
  }, []);

  useEffect(() => {
    setdataAppointment(data?.appointment);
    setdataPreTake(data);
    setDataResultFiles(data?.results?.files);
  }, [data]);

  const formatSource = (source) => {
    const autaSources = ["auta", "web_auta", "landing_auta"];
    const sanJorgeSources = ["san_jorge", "landing_san_jorge", "", undefined];

    if (autaSources.includes(source)) {
      return "auta";
    } else if (sanJorgeSources.includes(source)) {
      return "san_jorge";
    }
    return source; // Caso por defecto, si no coincide con los anteriores
  };

  if (loading) {
    return <SpinnerFullScreenComponent />;
  }

  if (error) {
    return <ServerErrorComponent error={error} />;
  }

  const appointmentDate = dataAppointment?.date;
  const formattedDate = appointmentDate
    ? new Date(appointmentDate.seconds * 1000).toLocaleDateString()
    : null;
  const formattedTime = appointmentDate
    ? `${new Date(appointmentDate.seconds * 1000).getHours()}:${new Date(
        appointmentDate.seconds * 1000
      )
        .getMinutes()
        .toString()
        .padStart(2, "0")} hs.`
    : null;
  const inspectionLocation = dataAppointment?.punto_de_inspeccion;
  const formattedSource = formatSource(deal?.source);
  const inspectionUrl =
    formattedSource === "san_jorge"
      ? `https://turnos.chevroletsanjorge.com.ar/turnos-san-jorge/take/${deal_id}`
      : `https://turnos.auta.com.ar/toma/${deal_id}`;

  const popoverContent = (
    <Popover id="popover-basic">
      <Popover.Body style={{ fontSize: "14px" }}>
        Modificar inspección Pre Toma
      </Popover.Body>
    </Popover>
  );

  const appointmentDetails = (
    <div className="d-flex align-items-center">
      <p className="appointment-text">
        Agendada para el día {formattedDate} a las {formattedTime}{" "}
        {inspectionLocation ? `en ${inspectionLocation}` : null}
      </p>
      <OverlayTrigger placement="top" overlay={popoverContent}>
        <span>
          <MdEdit
            onClick={() =>
              window.open(inspectionUrl, "_blank", "noopener,noreferrer")
            }
            style={{
              fontSize: "22px",
              color: colors.primary.primary,
              cursor: "pointer",
              marginLeft: "6px",
            }}
          />
        </span>
      </OverlayTrigger>
    </div>
  );

  return (
    <>
      <div className="containerAll">
        {appointmentDate ? (
          <div className="d-flex justify-content-between">
            {appointmentDetails}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <p className="inspection-text mb-4">Inspección Pre Toma</p>
            <Button
              variant="primary"
              style={{ marginRight: "20px", width: "250px" }}
            >
              <a
                href={inspectionUrl}
                style={{ textDecoration: "none", color: "white" }}
                target="_blank"
                rel="noreferrer"
              >
                Agendar Inspección Pre Toma
              </a>
            </Button>
          </div>
        )}
      </div>
      <div className="containerAll">
        <PreTakeInspTabsComponent
          dataPreTake={dataPreTake}
          dataResultFiles={dataResultFiles}
          setDataResultFiles={setDataResultFiles}
        />
      </div>
    </>
  );
}
